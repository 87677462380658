import React from 'react';
import {
    Grid,
    Paper,
    Button,
    Typography,
    Tooltip,
    IconButton,
} from '@material-ui/core';
import ContentCopy from '@material-ui/icons/ContentCopy';
import ShowChart from '@material-ui/icons/ShowChart';
import { Link, RouteComponentProps } from 'react-router-dom';

import { decorate, StyleProps } from './style'
import Breadcrumbs from '../partials/Breadcrumbs';
import { copyToClipboard } from '../../../../utils/clipboard';
import Img from '../partials/Img';


interface Props {
    summary: dashboard.DeliveryTrack | null
    errorMessage: string
    init: (id: string) => void
}

type MergedProps = Props & StyleProps & RouteComponentProps<{ id: string }>;

const LOADING = 'loading...';

class Track extends React.Component<MergedProps> {
    constructor(props: MergedProps) {
        super(props);
        const { id } = props.match.params;
        this.props.init(id);
    }

    render() {
        const { classes: s, summary, errorMessage } = this.props;

        if (errorMessage) {
            return (
                <div className={s.root}>
                    <Grid container spacing={8}>
                        <Breadcrumbs
                            items={[
                                { label: 'Search', link: '/delivery' },
                                { label: 'unknown artist' },
                                { label: 'unknown album' },
                                { label: 'unknown track' },
                            ]}
                        />
                        <p className={s.errorText}>
                            {errorMessage}
                        </p>
                    </Grid>
                </div>
            );
        }

        return (
            <div className={s.root}>
                <Grid container spacing={8}>
                    <Breadcrumbs
                        items={[
                            { label: 'Search', link: '/delivery' },
                            {
                                label: summary?.artist || LOADING,
                                link: summary ? `/delivery/artist/${summary.artistId}` : undefined
                            },
                            {
                                label: summary?.album || LOADING,
                                link: summary ? `/delivery/album/${summary.albumId}` : undefined
                            },
                            {
                                label: summary?.name || LOADING
                            }
                        ]}
                    />
                    {this.buildSummary(summary)}
                </Grid>
            </div>
        );
    }

    private buildSummary(summary:  dashboard.DeliveryTrack | null) {
        const { classes: s } = this.props;
        const { id, imageUrl } = summary || {};

        return (
            <Grid item xs={12}>
                <Paper className={s.summary}>
                    <div className={s.buttons}>
                        {
                            id ? (
                                <Tooltip title="Show Chart">
                                    <Link to={`/track/${id}/summary`} className={s.showChart}>
                                        <Button variant="fab">
                                            <ShowChart />
                                        </Button>
                                    </Link>
                                </Tooltip>
                            ) : null
                        }
                    </div>
                    <div className={s.contentsContainer}>
                        <div className={s.imgContainer}>
                            {
                                imageUrl ? (
                                    <Img src={imageUrl} className={s.img} />
                                ) : null
                            }
                        </div>
                        {this.buildSummaryBody(summary)}
                    </div>
                </Paper>
            </Grid>
        )
    }

    private buildSummaryBody(summary:  dashboard.DeliveryTrack | null) {
        const { classes: s, match } = this.props;
        const { id } = match.params;
        const now = summary ? '' : `${Date.now() / 1000 | 0}`;
        const name = summary ? summary.name : LOADING;
        const published = summary ? (summary.isDeleted ? 'Stopped' : 'Published') : LOADING;
        const shareUrl = summary ? summary.shareUrl : `https://s.awa.fm/track/${id}?t=${now}`;
        const favorited = summary ? `${summary.favorited}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : LOADING;
        const embedCode = summary ? summary.embedCode : `<iframe src="https://embed.awa.fm/track/${id}?t=${now}" width="100%" height="354" frameborder="0" allowtransparency="true"></iframe>`;

        return (
            <div className={s.summaryBody}>
                <Typography variant='subheading' color='primary'>
                    NAME
                </Typography>
                <Typography gutterBottom>
                    {name}
                </Typography>
                <Typography variant='subheading' color='primary'>
                    STATUS
                </Typography>
                <Typography gutterBottom>
                    {published}
                </Typography>
                <Typography variant='subheading' color='primary'>
                    FAVORITED
                </Typography>
                <Typography gutterBottom>
                    {favorited}
                </Typography>
                <Typography variant='subheading' color='primary'>
                    ARTIST
                </Typography>
                <Typography gutterBottom>
                    {
                        summary ? (
                            <Link
                                className={s.link}
                                to={`/delivery/artist/${summary.artistId}`}
                            >
                                {summary.artist}
                            </Link>
                        ) : (
                            <span>
                                {LOADING}
                            </span>
                        )
                    }
                </Typography>
                <Typography variant='subheading' color='primary'>
                    ALBUM
                </Typography>
                <Typography gutterBottom>
                    {
                        summary ? (
                            <Link
                                className={s.link}
                                to={`/delivery/album/${summary.albumId}`}
                            >
                                {summary.album}
                            </Link>
                        ) : (
                            <span>
                                {LOADING}
                            </span>
                        )
                    }
                </Typography>
                <Typography variant='subheading' color='primary'>
                    URL
                </Typography>
                <Typography gutterBottom>
                    <Tooltip title="Copy Share Url">
                        <IconButton
                            aria-label="copy"
                            className={s.urlCopyIconButton}
                            onClick={() => this.copy(shareUrl)}
                        >
                            <ContentCopy className={s.urlCopyIcon} />
                        </IconButton>
                    </Tooltip>
                    <Link
                        className={s.link}
                        to={shareUrl}
                        title={shareUrl}
                        target='_blank'
                    >
                        {shareUrl}
                    </Link>
                </Typography>
                <Typography variant='subheading' color='primary'>
                    EMBED CODE
                </Typography>
                <div className={s.embed}>
                    <div className={s.embedButton}>
                        <Tooltip title="Copy Embed Code">
                            <IconButton
                                aria-label="copy"
                                className={s.urlCopyIconButton}
                                onClick={() => this.copy(embedCode)}
                            >
                                <ContentCopy className={s.urlCopyIcon} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={s.embedCodeWrap}>
                        <code className={s.embedCode}>
                            {embedCode}
                        </code>
                    </div>
                </div>
            </div>
        )
    }

    private async copy(text: string) {
        await copyToClipboard(text);
    }
}

export default decorate<Props>(Track);
