import { Divider, Drawer, List, MenuItem, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import React from 'react';

type ClassNames = 'list'
    | 'textContainer';

const decorate = withStyles<ClassNames>((theme: Theme) => ({
    list: {
        width: 260,
    },
    textContainer: {
        height: 'auto',
        whiteSpace: 'pre-wrap',
    },
}));

interface Props {
    items: dashboard.Notification[];
    drawerOpened: boolean;
    onCloseDrawer: () => void;
}

const Notifications: React.SFC<Props & WithStyles<ClassNames>> = (props) => {
    const { classes, items, drawerOpened, onCloseDrawer } = props;
    return (
        <Drawer anchor="right" open={drawerOpened} onClick={onCloseDrawer}>
            <List disablePadding className={classes.list}>
                {
                    items ? (
                        items.map((item, i) => (
                            <div key={i}>
                                <MenuItem className={classes.textContainer}>
                                    <Typography>{item.text}</Typography>
                                </MenuItem>
                                <Divider />
                            </div>
                        ))
                    ) : (
                            <Typography>No content</Typography>
                        )
                }
            </List>
        </Drawer >
    );
};

export default decorate<Props>(Notifications);