import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MusicNote from '@material-ui/icons/MusicNote';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import MailIcon from '@material-ui/icons/Mail';
import PieChartIcon from '@material-ui/icons/PieChart';
import * as H from 'history';
import React from 'react';
import { Link } from 'react-router-dom';
import { Translator } from '../utils/localization';

const YEAR = new Date().getFullYear();
const CONTACT_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSd5qXyp1BbQ6mtP1C4m0Jfrrbhj-lEYW-zzSHX2551qBZRwxQ/viewform';

type ClassNames = 'nameContainer'
    | 'name'
    | 'list'
    | 'copyright'
    | 'button';

const decorate = withStyles<ClassNames>((theme: Theme) => ({
    nameContainer: {
        backgroundImage: `url('/img/bg_drawer.jpg?v1')`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'flex-end',
        height: 160,
        width: 280,
    },
    name: {
        color: theme.palette.common.white,
        fontSize: '1.2rem',
    },
    list: {
        flex: '1 1 auto',
    },
    copyright: {
        color: theme.palette.grey[500],
        fontWeight: 100,
        marginBottom: 8,
        textAlign: 'center',
    },
    // For overrides
    button: {
        color: 'rgba(0, 0, 0, 0.87)',
    },
}));

interface Props {
    company: string;
    drawerOpened: boolean;
    onCloseDrawer: () => void;
    logout: () => void;
    recoverCancelled: () => void;
    history: H.History;
    translator: Translator;
}

const LeftNav = ((props: Props & WithStyles<ClassNames>) => {
    const { classes, drawerOpened, onCloseDrawer, company, logout, translator } = props;

    const onTitleClicked = () => {
        props.history.push('/');
        props.recoverCancelled();
    };

    return (
        <Drawer open={drawerOpened} onClick={onCloseDrawer}>
            <div className={classes.nameContainer}>
                <Typography variant="title" className={classes.name}>{company}</Typography>
            </div>
            <Divider />
            <List className={classes.list}>
                <ListItem>
                    <ListItemText secondary="STATS" />
                </ListItem>
                <ListItem button onClick={onTitleClicked}>
                    <ListItemIcon>
                        <InsertChartIcon />
                    </ListItemIcon>
                    <ListItemText primary={translator.translate('leftNav__summary')} />
                </ListItem>
                <Link to="/analysis">
                    <ListItem button classes={{ button: classes.button }}>
                        <ListItemIcon>
                            <PieChartIcon />
                        </ListItemIcon>
                        <ListItemText primary={translator.translate('leftNav__analysis')} />
                    </ListItem>
                </Link>
                <Divider />
                <ListItem>
                    <ListItemText secondary="TOOLS" />
                </ListItem>
                <Link to="/delivery">
                    <ListItem button classes={{ button: classes.button }}>
                        <ListItemIcon>
                            <MusicNote />
                        </ListItemIcon>
                        <ListItemText primary={translator.translate('leftNav__deliveries')} />
                    </ListItem>
                </Link>
                <Divider />
                <ListItem>
                    <ListItemText secondary="OTHERS" />
                </ListItem>
                <ListItem button onClick={logout}>
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary={translator.translate('leftNav__logout')} />
                </ListItem>
                <a href={`${CONTACT_URL}?entry.1510356731=${company}`}>
                    <ListItem button classes={{ button: classes.button }}>
                        <ListItemIcon>
                            <MailIcon />
                        </ListItemIcon>
                        <ListItemText primary={translator.translate('leftNav__contact')} />
                    </ListItem>
                </a>
            </List>
            <Typography className={classes.copyright}>Copyright {YEAR} AWA Co. Ltd.</Typography>
        </Drawer>
    );
});

export default decorate<Props>(LeftNav);
