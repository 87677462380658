// tslint:disable:object-literal-key-quotes quotemark trailing-comma max-line-length
export default [
    {
        "id": 1,
        "text": "いつもご利用ありがとうございます。2017/12/21にダッシュボードをリニューアルいたしました。\nご要望、不具合等ございましたらお問い合わせフォーム（メニュー＞お問い合わせ）よりご連絡ください。\n\n変更点は下記の通りです。\n・トップページ以外のURLが変更されたのでブックマーク等している場合は再度登録をお願いします\n・リアルタイムのページが削除されました\n\n今後はダッシュボードの機能をより強化して参りますので些細なことでもご意見頂けますと幸いです。"
    },
    {
        "id": 2,
        "text": "【機能追加のお知らせ】\n再生数ランキングが100位以降も閲覧できるようになりました。ランキング下部の「LOAD MORE」ボタンを押すことで好きなだけ表示することができます。"
    },
    {
        "id": 3,
        "text": "【機能追加のお知らせ】\nプレイリストランキングの行をクリックすると該当プレイリストのWebページへ遷移するようになりました。プレイリストの再生数ランキングはアーティスト毎のページとトラック毎のページに表示されています。どんなプレイリストで聴かれているかチェックしてみましょう。"
    },
    {
        "id": 4,
        "text": "【機能追加のお知らせ】\nアーティスト、アルバム、トラックの各ページから試聴可能なWebページへ遷移するようになりました。タイトル右側のボタンを押すと表示できます。"
    },
    {
        "id": 5,
        "text": "【機能追加のお知らせ】\nアーティスト画像の納品フォームにて画像のドラッグアンドドロップをサポートいたしました。アーティスト画像は「メニュー＞配信アーティスト一覧」より該当アーティストを選択いただくことで納品することができます。"
    },
    {
        "id": 6,
        "text": "【機能改善のお知らせ】\nグラフにマウスオンすると表示される数値詳細の表記を改善しました。該当数値に加え、日時や曜日、複数項目ある場合はTotal数値が表示されます。"
    },
    {
        "id": 7,
        "text": "【仕様変更のお知らせ】\nサイトパフォーマンスの向上を目的として、サマリページのURLを一部変更いたしました。あわせてエクセルのファイル名も変更となります。ブックマーク等されている場合は、お手数ですが再度ブックマークしていただきますようよろしくお願いいたします。"
    },
    {
        "id": 8,
        "text": "【機能改善のお知らせ】\nエクセルとしてダウンロードされるデータの各種ランキングの件数が、従来の50件から最大500件に増えました。またGoogleChrome以外のブラウザでダウンロードできない不具合を修正しました。"
    },
    {
        "id": 9,
        "text": "【機能追加のお知らせ】\nエクセルとしてダウンロードされるランキング項目にUPCを追加しました。"
    },
    {
        "id": 10,
        "text": "【仕様変更のお知らせ】\nアーティスト写真をトリミングする際、余白が入らないよう変更いたしました。スクエアの素材がない場合は、アップロードする画像自体に背景を敷いていただけますようお願いいたします。"
    },
    {
        "id": 11,
        "text": "【機能追加のお知らせ】\n配信管理のアルバム情報にUPCを追加しました。同名のパッケージの区別にご利用ください。"
    },
];
