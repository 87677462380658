import browser from 'detect-browser';
import 'isomorphic-fetch';
import { Dispatch } from 'redux';
import { KEY_TOKEN, load as loadFromLocalStorage } from './../stores/localStorage';
import { SummaryState } from './../stores/summaryState';

export const LOAD_SUCCEEDED = 'LOAD_SUMMARY_SUCCEEDED';
export const LOAD_FAILED = 'LOAD_SUMMARY_FAILED';
export const CLEAR = 'CLEAR_SUMMARY';
export const LOADING = 'LOADING_SUMMARY';
export const CANCEL = 'CANCEL_SUMMARY';
export const RECOVER_CANCELLED = 'RECOVER_CANCELLED_SUMMARY';
export const LOAD_RANKING_SUCCEEDED = 'LOAD_SUMMARY_RANKING_SUCCEEDED';

export interface LoadSucceeded {
    type: typeof LOAD_SUCCEEDED;
    result: dashboard.Summary;
}

export interface LoadFailed {
    type: typeof LOAD_FAILED;
    errorMessage: string;
}

export interface Clear {
    type: typeof CLEAR;
}

export interface Loading {
    type: typeof LOADING;
}

export interface Cancel {
    type: typeof CANCEL;
}

export interface RecoverCancelled {
    type: typeof RECOVER_CANCELLED;
}

export interface LoadRankingSucceeded {
    type: typeof LOAD_RANKING_SUCCEEDED;
    result: dashboard.Summary;
    target: 'artists' | 'albums' | 'tracks' | 'playlists';
}

export type SummaryAction = LoadSucceeded | LoadFailed | Clear | Loading | Cancel | RecoverCancelled | LoadRankingSucceeded;

export const load = (type: string, id: string, start: string, end: string) => {
    return async (dispatch: Dispatch<SummaryState>) => {
        dispatch(clear());
        dispatch(loading());
        const response = await fetch(`/api/summary/${type ? type : 'label'}/${id ? `${id}/` : ''}?start=${start}&end=${end}`, {
            headers: {
                'X-Access-Token': loadFromLocalStorage(KEY_TOKEN) || '',
            },
        }).catch((err) => {
            dispatch(loadFailed(err));
            return;
        });
        if (!response) {
            dispatch(loadFailed('invalid response'));
            return;
        }
        if (response.ok) {
            const json = await response.json();
            dispatch(loadSucceeded(json));
        } else {
            dispatch(loadFailed(`${response.status}:${response.statusText}`));
        }
    };
};

const loadSucceeded = (result: dashboard.Summary) => {
    return {
        type: LOAD_SUCCEEDED,
        result,
    };
};

const loadFailed = (errorMessage: string) => {
    return {
        type: LOAD_FAILED,
        errorMessage,
    };
};

export const clear = () => {
    return {
        type: CLEAR,
    };
};

const loading = () => {
    return {
        type: LOADING,
    };
};

export const cancel = () => {
    return {
        type: CANCEL,
    };
};

export const recoverCancelled = () => {
    return {
        type: RECOVER_CANCELLED,
    };
};

export const download = (type: string, id: string, start: string, end: string) => {
    return () => {
        type = type === 'label' ? 'all' : type;
        id = id || '_';
        window.location.href = `/api/summary/download/${type}/${id}/${start}-${end}?token=${loadFromLocalStorage(KEY_TOKEN)}`;
    };
};

export const loadRanking = (
    start: string,
    end: string,
    offset: number,
    limit: number,
    type: 'artists' | 'albums' | 'tracks' | 'playlists',
    target?: { uriType: string, id: string }) => {
    return async (dispatch: Dispatch<{}>) => {
        const response =
            await fetch(`/api/summary/ranking/${type}/${start}-${end}?offset=${offset}&limit=${limit}&target=${JSON.stringify(target)}`,
                {
                    headers: {
                        'X-Access-Token': loadFromLocalStorage(KEY_TOKEN) || '',
                    },
                }).catch((err) => {
                    dispatch(loadFailed(err));
                    return;
                });
        if (!response) {
            dispatch(loadFailed('invalid response'));
            return;
        }
        if (response.ok) {
            const json: dashboard.Summary = await response.json();
            dispatch(loadRankingSucceeded(json, type));
        } else {
            dispatch(loadFailed(`${response.status}:${response.statusText}`));
        }
    };
};

const loadRankingSucceeded = (result: dashboard.Summary, type: 'artists' | 'albums' | 'tracks' | 'playlists') => {
    return {
        type: LOAD_RANKING_SUCCEEDED,
        result,
        target: type,
    };
};
