import { connect, Dispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../actions/deliveryViewer';
import Artist from '../../../components/delivery/viewer/Artist';
import { RootState } from '../../../stores/rootState';

const mapStateToProps = ({ deliveryViewer }: RootState) => {
  return {
    keyword: deliveryViewer.artist.search.keyword,
    target: deliveryViewer.artist.search.target,
    searchList: deliveryViewer.artist.search.result,
    summary: deliveryViewer.artist.summary,
    list: deliveryViewer.artist.list,
    errorMessage: deliveryViewer.errorMessage,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<actions.DeliveryViewerAction>) => {
  return {
    init: (id: string) => {
      dispatch(actions.loadArtist(id));
    },
    search: (keyword: string, target: 'albums' | 'tracks') => {
      dispatch(actions.searchInArtist(keyword, target));
    },
    loadMore: (target: 'albums' | 'tracks') => {
      dispatch(actions.loadMoreArtistContents(target));
    },
    setTarget: (target: 'albums' | 'tracks') => {
      dispatch(actions.setArtistSearchTarget(target));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Artist));
