import {
    LOAD_LOGIN_STATE,
    LOGIN_FAILED,
    LOGIN_SUCCEEDED,
    LoginAction,
    LOGOUT
} from '../actions/login';
import { LoginState } from '../stores/loginState';

export default function login(state: LoginState, action: LoginAction): LoginState {
    switch (action.type) {
        case LOGIN_SUCCEEDED:
            return { ...state, company: action.company, token: action.token, isLoggedIn: true };
        case LOGIN_FAILED:
            return { ...state, errorMessage: action.errorMessage, isLoggedIn: false };
        case LOGOUT:
            return { ...state, company: null, token: null, errorMessage: null, isLoggedIn: false };
        case LOAD_LOGIN_STATE:
            return {
                ...state,
                company: action.companyFromLocalStorage,
                token: action.tokenFromLocalStorage,
                isLoggedIn: action.isLoggedInFromLocalStorage,
            };
        default:
            return state || {};
    }
}