import { connect, Dispatch } from 'react-redux';
import { withRouter } from 'react-router';
import * as actions from '../../actions/analysis';
import * as searchActions from '../../actions/search';
import Top from '../../components/analysis/Top';
import { RootState } from '../../stores/rootState';

export function mapStateToProps({ analysis, search, window }: RootState) {
    return {
        results: analysis.results,
        searchResult: search.result,
        searchErrorMessage: search.errorMessage,
        translator: window.translator,
    };
}

export function mapDispatchToProps(dispatch: Dispatch<actions.AnalysisAction | searchActions.SearchAction>) {
    return {
        loadTopArtists: () => { dispatch(actions.loadTopArtists()); },
        clearArtists: () => { dispatch(actions.clearArtists()); },
        search: (query: string, targets?: ['artists' | 'albums' | 'tracks'], limit?: number) => {
            dispatch(searchActions.search(query, targets, limit));
        },
        loadHistories: (targets?: ['artists' | 'albums' | 'tracks']) => { dispatch(searchActions.loadHistories(targets)); },
        saveHistory: (history: dashboard.SearchArtist | dashboard.SearchAlbum | dashboard.SearchTrack) => {
            dispatch(searchActions.saveHistory(history));
        },
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Top));
