export default {
    app__errorMessage1: 'エラーが発生しました。',
    app__errorMessage2: 'メニュー内のお問い合わせよりご報告ください。',
    app__done__button: '完了',
    app__cancel__button: 'キャンセル',

    toolbar__date__show: '表示する',
    toolbar__showSharePage: '試聴可能なWebページを表示します',
    toolbar__period__default: '期間を選択する',
    toolbar__period__yesterday: '昨日',
    toolbar__period__day: '${s}日',
    toolbar__period__week: '${s}週間',
    toolbar__period__month: '${s}ヶ月',
    toolbar__period__year: '${s}年',
    toolbar__download__tooltip: '対象のデータをダウンロードします',
    toolbar__downloading__tooltip: 'ダウンロードしています',

    leftNav__summary: 'サマリ',
    leftNav__analysis: 'アーティスト分析(β)',
    leftNav__deliveries: '配信管理',
    leftNav__logout: 'ログアウト',
    leftNav__contact: 'お問い合わせ',

    login__note: '推奨ブラウザ',
    login__errorMessage1: 'ログインの有効期限が切れました（${s}）',
    login__errorMessage2: 'User NameもしくはPasswordが正しくありません',

    summary__loading__message1: 'データを集計しています…',
    summary__loading__message2: 'しばらくお待ち下さい',
    summary__loading__cancel: '読み込みをキャンセル',
    summary__loading__cancelled: '読み込みがキャンセルされました',

    summary__figure__plays: '再生数(30秒以上 / Total)',
    summary__figure__users: 'ユーザー数',
    summary__figure__playsShare: '再生数シェア',
    summary__figure__usersShare: 'ユーザー数シェア',

    summary__graph__plays: '再生数',
    summary__graph__users: '再生ユーザー数',
    summary__graph__favCount: 'お気に入り追加数',

    summary__graph__legend__playsOver30s: '30秒以上',
    summary__graph__legend__playsUnder30s: '30秒未満',
    summary__graph__legend__users: 'ユーザー数',
    summary__graph__legend__artists: 'アーティスト',
    summary__graph__legend__albums: 'アルバム',
    summary__graph__legend__tracks: 'トラック',
    summary__graph__legend__playlists: 'プレイリスト',

    summary__rank__unpublishedPlaylist: '非公開プレイリスト',

    deliveries__noImage: 'アー写未設定',
    deliveries__noBio: 'Bio未設定',

    delivery__adopted__tooltip: '現所属アーティストです。設定したデータはアプリに反映されます。',
    delivery__deleteImage__button: '画像を削除',
    delivery__done__button: '更新',
    delivery__deleteImage__message: 'アーティスト画像を削除しますか？',
    delivery__bio__label: 'あと${s}文字入力できます',
    delivery__bio__placeholder: 'Bio.を入力',
    delivery__updateMessage1: 'プロフィールを更新しました',
    delivery__updateMessage2: '画像を削除しました',
    delivery__deleteImageDialog__button: '削除する',

    delivery__viewer__error__not__allowed: 'このコンテンツの閲覧は許可されていません\n別アカウントから配信されているか、アカウントが紐づいていない可能性があります',
    delivery__viewer__error__not__found: 'コンテンツが見つかりませんでした\nURLをご確認ください',

    selectImageDialog__message1: 'ここに画像をドロップ',
    selectImageDialog__message2: 'または...',
    selectImageDialog__message3: 'アップロードする画像を選択',
    selectImageDialog__note__size: '推奨サイズ',
    selectImageDialog__note__format: '対応フォーマット',
    selectImageDialog__errorMessage1: 'jpgもしくはpng形式でアップロードしてください',
    selectImageDialog__errorMessage2: '128MB以内でアップロードしてください',
    selectImageDialog__errorMessage3: '縦横${s}px以上でアップロードしてください',

    analysis__topArtists__title: 'アーティストランキング TOP 10',
    analysis__relatedArtists__viaPlaylist__title: '同じプレイリストに入っていることが多いアーティスト',
    analysis__relatedArtists__viaPlayback__title: '前後に再生されていることが多いアーティスト',

};
