import {
    CANCEL,
    CLEAR,
    LOAD_FAILED,
    LOAD_RANKING_SUCCEEDED,
    LOAD_SUCCEEDED,
    LOADING,
    RECOVER_CANCELLED,
    SummaryAction
} from './../actions/summary';
import { SummaryState } from './../stores/summaryState';

export enum DataSetProps {
    artists = 'artists',
    albums = 'albums',
    tracks = 'tracks',
    playlists = 'playlists',
    users = 'users',
}

export default (state: SummaryState, action: SummaryAction): SummaryState => {
    switch (action.type) {
        case LOAD_SUCCEEDED:
            return { ...state, result: action.result, loading: false };
        case LOAD_FAILED:
            return { ...state, errorMessage: action.errorMessage, loading: false };
        case CLEAR:
            return { ...state, result: null, errorMessage: null };
        case LOADING:
            return { ...state, loading: true };
        case CANCEL:
            return { ...state, loading: false, cancelled: true };
        case RECOVER_CANCELLED:
            return { ...state, cancelled: false };
        case LOAD_RANKING_SUCCEEDED:
            const { result: prevResult } = state;
            const { result: nextResult, target: nextTarget } = action;
            if (!prevResult || !prevResult.ranking || !prevResult.dataSet || !nextResult.ranking) { return { ...state }; }

            const prevRanking = prevResult.ranking[nextTarget];
            const nextRanking = nextResult.ranking[nextTarget];
            if (!prevRanking || !nextRanking) { return { ...state }; }

            Object.keys(DataSetProps).forEach((dataSetProp: 'artists' | 'albums' | 'tracks' | 'playlists' | 'users') => {
                const prevDataSet = prevResult.dataSet && prevResult.dataSet[dataSetProp];
                const nextDataSet = nextResult.dataSet && nextResult.dataSet[dataSetProp];
                if (!prevDataSet || !nextDataSet) { return; }
                Object.keys(nextDataSet).forEach(((id) => {
                    prevDataSet[id] = nextDataSet[id];
                }));
            });

            return {
                ...state,
                result: {
                    ...prevResult,
                    ranking: {
                        ...prevResult.ranking,
                        [nextTarget]: prevRanking.concat(nextRanking),
                    },
                },
                hasNextRankings: {
                    [nextTarget]: nextRanking.length > 0 ? true : false,
                },
            };
        default:
            return state || {};
    }
};