import 'isomorphic-fetch';
import * as queryString from 'query-string';
import { Dispatch } from 'redux';
import { DeliveryState } from './../stores/deliveryState';
import { KEY_TOKEN, load as loadFromLocalStorage } from './../stores/localStorage';

export const LOAD_LIST_SUCCEEDED = 'DELIVERY_LOAD_LIST_SUCCEEDED';
export const LOAD_ONE_SUCCEEDED = 'DELIVERY_LOAD_ONE_SUCCEEDED';
export const UPDATE_SUCCEEDED = 'DELIVERY_UPDATE_SUCCEEDED';
export const FAILED = 'DELIVERY_FAILED';
export const CLEAR = 'DELIVERY_CLEAR';
export const CLEAR_IMAGE = 'DELIVERY_CLEAR_IMAGE';
export const LOADING = 'DELIVERY_LOADING';

export enum UpdateType {
    update,
    delete,
}

interface LoadListSucceeded {
    type: typeof LOAD_LIST_SUCCEEDED;
    result: dashboard.Delivery[];
}

interface LoadOneSucceeded {
    type: typeof LOAD_ONE_SUCCEEDED;
    result: dashboard.Delivery;
}

interface UpdateSucceeded {
    type: typeof UPDATE_SUCCEEDED;
    updateType: UpdateType;
}

interface LoadFailed {
    type: typeof FAILED;
    errorMessage: string;
}

interface Clear {
    type: typeof CLEAR;
}

interface ClearImage {
    type: typeof CLEAR_IMAGE;
}

interface Loading {
    type: typeof LOADING;
    linerNotes?: string;
}

export type DeliveryAction =
    LoadListSucceeded |
    LoadOneSucceeded |
    UpdateSucceeded |
    LoadFailed |
    Clear |
    ClearImage |
    Loading;

export const loadList = (offset: number, limit: number, noLiner: boolean, noImage: boolean) => {
    return async (dispatch: Dispatch<DeliveryState>) => {
        dispatch(loading());
        const queries = queryString.stringify(
            { offset: offset, limit: limit, noLiner: noLiner, noImage: noImage }
        );
        const response = await fetch(`/api/delivery?${queries}`, {
            headers: {
                'X-Access-Token': loadFromLocalStorage(KEY_TOKEN) || '',
            },
        }).catch((err) => {
            dispatch(failed(err));
            return;
        });
        if (response) {
            if (!response.ok) {
                dispatch(failed(`${response.status}:${response.statusText}`));
                return;
            }
            const json: dashboard.Delivery[] = await response.json();
            dispatch(loadListSucceeded(json));
        }
    };
};

export const loadOne = (id: string, from?: 'admintool', distributorId?: string) => {
    return async (dispatch: Dispatch<DeliveryState>) => {
        const response = await fetch(`/api/delivery/${id}?${queryString.stringify({ from, distributorId })}`, {
            headers: {
                'X-Access-Token': loadFromLocalStorage(KEY_TOKEN) || '',
            },
        }).catch((err) => {
            dispatch(failed(err));
            return;
        });
        if (response) {
            const json: dashboard.Delivery = await response.json();
            if (!response.ok) {
                dispatch(failed(`${response.status}:${json.errorMessage}`));
                return;
            }
            dispatch(loadOneSucceeded(json));
        }
    };
};

export const update = (id: string, imageBase64: string | null, linerNotes: string, distributorId?: string) => {
    return async (dispatch: Dispatch<DeliveryState>) => {
        dispatch(loading(linerNotes));
        const response = await fetch(`/api/delivery/${id}`, {
            method: 'post',
            headers: {
                'X-Access-Token': loadFromLocalStorage(KEY_TOKEN) || '',
                'accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ imageBase64, linerNotes, distributorId }),
        }).catch((err) => {
            dispatch(failed(err.message));
            return;
        });
        if (response) {
            if (!response.ok) {
                const json = await response.json();
                dispatch(failed(`${response.status}: ${response.statusText} (${json.params && json.params[0] && json.params[0].value})`));
                return;
            }
            dispatch(updateSucceeded(UpdateType.update));
        }
    };
};

export const deleteImage = (id: string, distributorId?: string) => {
    return async (dispatch: Dispatch<DeliveryState>) => {
        const response = await fetch(`/api/delivery/${id}/images?${queryString.stringify({ distributorId })}`, {
            method: 'delete',
            headers: {
                'X-Access-Token': loadFromLocalStorage(KEY_TOKEN) || '',
            },
        }).catch((err) => {
            dispatch(failed(err.message));
            return;
        });
        if (response) {
            if (!response.ok) {
                dispatch(failed(`${response.status}:${response.statusText}`));
                return;
            }
            dispatch(updateSucceeded(UpdateType.delete));
        }
    };
};

const loadListSucceeded = (result: dashboard.Delivery[]) => {
    return {
        type: LOAD_LIST_SUCCEEDED,
        result,
    };
};

const loadOneSucceeded = (result: dashboard.Delivery) => {
    return {
        type: LOAD_ONE_SUCCEEDED,
        result,
    };
};

const updateSucceeded = (updateType: UpdateType) => {
    return {
        type: UPDATE_SUCCEEDED,
        updateType,
    };
};

const failed = (errorMessage: string) => {
    return {
        type: FAILED,
        errorMessage,
    };
};

export const clear = () => {
    return {
        type: CLEAR,
    };
};

export const clearImage = () => {
    return {
        type: CLEAR_IMAGE,
    };
};

const loading = (linerNotes?: string) => {
    return {
        type: LOADING,
        linerNotes,
    };
};
