import { connect, Dispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../actions/deliveryViewer';
import Track from '../../../components/delivery/viewer/Track';
import { RootState } from '../../../stores/rootState';

const mapStateToProps = ({ deliveryViewer }: RootState) => {
  return {
    summary: deliveryViewer.track.summary,
    errorMessage: deliveryViewer.errorMessage,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<actions.DeliveryViewerAction>) => {
  return {
    init: (id: string) => {
      dispatch(actions.loadTrack(id))
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Track));
