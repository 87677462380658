import { AppBar, Theme, WithStyles, withStyles } from '@material-ui/core';
import MuiToolbar from '@material-ui/core/Toolbar';
import React from 'react';

type ClassNames = 'appBar';

const decorate = withStyles<ClassNames>((theme: Theme) => ({
    appBar: {
        backgroundColor: theme.palette.grey[100],
        [theme.breakpoints.down('sm')]: {
            marginTop: '54px',
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: '64px',
        },
    },
}));

const Toolbar: React.SFC<WithStyles<ClassNames>> = (props) => {

    const { classes } = props;
    return (
        <AppBar className={classes.appBar}>
            <MuiToolbar>
                {props.children}
            </MuiToolbar>
        </AppBar>
    );
};

export default decorate<{}>(Toolbar);