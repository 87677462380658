import {
    Theme,
    WithStyles,
    withStyles,
} from '@material-ui/core';

export type StyleProps = WithStyles<keyof ReturnType<typeof styles>>

export const decorate = withStyles(styles);

function styles(theme: Theme) {
    return {
        placeholder: {
            backgroundColor: theme.palette.grey[100]
        },
    } as const
}
