import React from 'react';
import { decorate, StyleProps } from './style'

type Props = {
    className?: string
    src: string
}

type State = {
    isError: boolean
}

type MergedProps = Props & StyleProps;

class Img extends React.Component<MergedProps, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            isError: false,
        };

        this.handleImgError = this.handleImgError.bind(this);
    }

    shouldComponentUpdate(nextProps: MergedProps, nextState: State) {
        return (
            nextState.isError !== this.state.isError ||
            nextProps.className !== this.props.className ||
            nextProps.src !== this.props.src
        );
    }

    componentDidUpdate(prevProps: MergedProps) {
        if (prevProps.src !== this.props.src) {
            this.setState({ isError: false });
        }
    }

    render() {
        return this.state.isError ? (
            <div
                className={[
                    this.props.classes.placeholder,
                    this.props.className,
                ].join(' ')}
            />
        ) : (
            <img
                src={this.props.src}
                className={this.props.className}
                onError={this.handleImgError}
            />
        )
    }

    private handleImgError() {
        this.setState({ isError: true })
    }
}

export default decorate(Img)
