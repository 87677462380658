import { AppBar, Badge, IconButton, Theme, Toolbar, Tooltip, Typography, WithStyles, withStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import * as H from 'history';
import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Translator } from '../utils/localization';
import LeftNav from './LeftNav';
import Notifications from './Notifications';

type ClassNames = 'container'
    | 'appBar'
    | 'toolBar'
    | 'titleContainer'
    | 'iconButton'
    | 'title'
    | 'subtitle'
    | 'iconButtonOpenInNew'
    | 'children';

const decorate = withStyles<ClassNames>((theme: Theme) => {
    const subtitleColor = theme.palette.grey[400];
    return {
        container: {
            height: '100%',
        },
        appBar: {
            backgroundColor: theme.palette.primary.dark,
        },
        toolBar: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        iconButton: {
            color: theme.palette.common.white,
        },
        title: {
            color: theme.palette.common.white,
            marginRight: 8,
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.98em',
            },
            cursor: 'pointer',
        },
        subtitle: {
            color: subtitleColor,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.9em',
            },
        },
        iconButtonOpenInNew: {
            color: subtitleColor,
        },
        children: {
            height: '100%',
            marginTop: 64,
        },
    };
});

interface Props {
    company: string;
    loading: boolean;
    name: string | undefined;
    encryptedId: string | undefined;
    updatedAt: number | undefined;
    isLoggedIn: boolean;
    notifications: dashboard.Notification[];
    notificationUnread: number;
    translator: Translator;
    location: H.Location;
    logout: () => void;
    recoverCancelled: () => void;
    readNotification: () => void;
}

interface State {
    leftDrawerOpened: boolean;
    rightDrawerOpened: boolean;
    error?: string;
    uriType?: 'artist' | 'album' | 'track';
}

type MergedProps = Props & WithStyles<ClassNames> & RouteComponentProps<{}>;

class App extends React.Component<MergedProps, State> {

    public static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const uriTypes = nextProps.location.pathname.match(/(artist|album|track)/);
        if (uriTypes) {
            const uriType = uriTypes[0] as 'artist' | 'album' | 'track';
            return { uriType: uriType };
        } else {
            return { uriType: 'artist' };
        }
    }

    constructor(props: MergedProps) {
        super(props);
        this.state = { leftDrawerOpened: false, rightDrawerOpened: false };
        this.toggleLeftDrawer = this.toggleLeftDrawer.bind(this);
        this.closeLeftDrawer = this.closeLeftDrawer.bind(this);
        this.toggleRightDrawer = this.toggleRightDrawer.bind(this);
        this.closeRightDrawer = this.closeRightDrawer.bind(this);
        this.onTitleClicked = this.onTitleClicked.bind(this);
    }

    public componentDidCatch(error: any, info: any) {
        console.error(error.message, info.componentStack);
        this.setState({ error: error.message });
    }

    public render() {
        const {
            classes,
            ...other
        } = this.props;

        return (
            <div className={classes.container}>
                <AppBar className={classes.appBar} style={{ zIndex: this.props.loading ? -1 : 100 }}>
                    <Toolbar className={classes.toolBar}>
                        <div className={classes.titleContainer}>
                            {
                                this.props.isLoggedIn ? (
                                    <IconButton onClick={this.toggleLeftDrawer} className={classes.iconButton}>
                                        <MenuIcon />
                                    </IconButton>
                                ) : null
                            }
                            <Typography variant="headline" className={classes.title} onClick={this.onTitleClicked}>
                                AWA Dashboard
                            </Typography>

                            {this.props.name ? (
                                <>
                                    <Typography variant="title" className={classes.subtitle}>- {this.props.name}</Typography>
                                    <a href={`https://s.awa.fm/${this.state.uriType}/${this.props.encryptedId}/?t=${this.props.updatedAt}`}
                                        target="_blank">
                                        <Tooltip placement="bottom" title={this.props.translator.translate('toolbar__showSharePage')}>
                                            <IconButton className={classes.iconButtonOpenInNew}>
                                                <OpenInNewIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </a>
                                </>
                            ) : null}
                        </div>
                        {
                            this.props.isLoggedIn ? (
                                <IconButton onClick={this.toggleRightDrawer} className={classes.iconButton}>
                                    {
                                        this.props.notificationUnread > 0 ? (
                                            <Badge badgeContent={this.props.notificationUnread} color="secondary">
                                                <NotificationsIcon />
                                            </Badge>
                                        ) : <NotificationsIcon />
                                    }
                                </IconButton>) : null
                        }
                    </Toolbar>
                </AppBar>
                <LeftNav
                    drawerOpened={this.state.leftDrawerOpened}
                    onCloseDrawer={this.closeLeftDrawer}
                    {...other} />
                <Notifications
                    items={this.props.notifications}
                    drawerOpened={this.state.rightDrawerOpened}
                    onCloseDrawer={this.closeRightDrawer} />
                {
                    this.state.error ? (
                        <div style={{ padding: `${64 + 16}px 16px` }}>
                            <Typography variant="title">
                                {`${this.props.translator.translate('app__errorMessage1')}(${this.state.error})`}
                            </Typography>
                            <Typography variant="title">
                                {this.props.translator.translate('app__errorMessage2')}
                            </Typography>
                        </div>
                    ) :
                        (
                            <div className={classes.children}>
                                <Route children={this.props.children} />
                            </div>
                        )
                }
            </div>
        );
    }

    private toggleLeftDrawer() {
        this.setState({
            leftDrawerOpened: !this.state.leftDrawerOpened,
        });
    }

    private closeLeftDrawer() {
        this.setState({
            leftDrawerOpened: false,
        });
    }

    private toggleRightDrawer() {
        this.setState({
            rightDrawerOpened: !this.state.rightDrawerOpened,
        });
        this.props.readNotification();
    }

    private closeRightDrawer() {
        this.setState({
            rightDrawerOpened: false,
        });
    }

    private onTitleClicked() {
        this.props.history.push('/');
        this.props.recoverCancelled();
    }
}

export default decorate<Props>(App);
