// tslint:disable-next-line:max-line-length
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, Input, MenuItem, Paper, Select, Snackbar, TextField, Theme, Tooltip, Typography, withStyles, WithStyles } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import _ from 'lodash';
import * as queryString from 'query-string';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { UpdateType } from '../../../actions/delivery';
import { EMPTY_IMG, MAX_LENGTH_LINER_NOTES } from '../../../consts';
import { Translator } from '../../../utils/localization';
import SelectImageDialog from './SelectImageDialog';
import TrimImageDialog from './TrimImageDialog';

const IMG_SIZE = 258;
const MARGIN = 16;

type ClassNames = 'root'
    | 'buttons'
    | 'button'
    | 'contentsContainer'
    | 'imgContainer'
    | 'img'
    | 'linerNotes'
    | 'linerNotesText'
    | 'errorTextContainer'
    | 'errorText'
    | 'imgDialog';

const decorate = withStyles<ClassNames>((theme: Theme) => ({
    root: {
        margin: MARGIN,
        position: 'absolute',
        width: `calc(100% - ${MARGIN * 2}px)`,
        boxSizing: 'border-box',
        padding: 16,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    button: {
        marginLeft: 8, // FIXME: will be 16
    },
    contentsContainer: {
        display: 'flex',
        marginTop: 8,
    },
    imgContainer: {
        // tslint:disable:object-literal-key-quotes
        backgroundImage: `url(${EMPTY_IMG})`,
        height: IMG_SIZE,
        width: IMG_SIZE,
        opacity: 1,
        // tslint:enable:object-literal-key-quotes
        '&:hover': {
            opacity: 0.5,
            cursor: 'pointer',
        },
    },
    img: {
        height: IMG_SIZE,
        width: IMG_SIZE,
    },
    linerNotes: {
        width: `calc(100% - ${IMG_SIZE}px - ${MARGIN}px)`,
        marginLeft: MARGIN,
    },
    linerNotesText: {
        width: '100%',
    },
    errorTextContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    errorText: {
        marginTop: 32,
    },
    // For overrides
    imgDialog: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: 600,
    },
}));

interface Props {
    data: dashboard.Delivery;
    loading: boolean;
    updateType: UpdateType;
    updateMessage: string;
    errorMessage: string;
    translator: Translator;
    loadOne: (id: string, from?: 'admintool', distributorId?: string) => void;
    clear: () => void;
    clearImage: () => void;
    update: (id: string, imagBase64: string | null, linerNotes?: string, distributorId?: string | null) => void;
    delete: (id: string, distributorId?: string | null) => void;
    getArtist: (id: string) => void;
    clearArtist: () => void;
}

interface State {
    id: string;
    imageDialogOpened: boolean;
    deleteDialogOpened: boolean;
    blobUrl: string | null;
    base64Url: string | null;
    linerNotes?: string;
    message: string;
    distributors?: Array<{ id: string, name: string }>;
    selectedDistributor?: { id: string, name: string };
}

type MergedProps = Props & WithStyles<ClassNames> & RouteComponentProps<{ id: string }>;

class Artist extends React.Component<MergedProps, State> {

    public static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const { data, updateMessage } = nextProps;
        if (data) {
            let state = {};
            if (data.distributors && data.selectedDistributorId) {
                const distributors = Object.keys(data.distributors).map((d) => {
                    return data.distributors && data.distributors[d];
                });
                state = {
                    distributors: distributors,
                    selectedDistributor: data.distributors[data.selectedDistributorId],
                };
            }
            return _.merge(state, {
                linerNotes: data.linerNotes,
                message: updateMessage,
            });
        } else {
            return null;
        }
    }

    constructor(props: MergedProps) {
        super(props);
        this.onClickImg = this.onClickImg.bind(this);
        this.onCloseImageDialog = this.onCloseImageDialog.bind(this);
        this.onRequestCloseDeleteDialog = this.onRequestCloseDeleteDialog.bind(this);
        this.onTextChanged = this.onTextChanged.bind(this);
        this.onSelectImage = this.onSelectImage.bind(this);
        this.onTrimImageCompleted = this.onTrimImageCompleted.bind(this);
        this.onShowMessage = this.onShowMessage.bind(this);
        this.onEditComplete = this.onEditComplete.bind(this);
        this.onDeleteImageClicked = this.onDeleteImageClicked.bind(this);
        this.onCloseSnackbar = this.onCloseSnackbar.bind(this);
        this.onSelectDistributor = this.onSelectDistributor.bind(this);
        const id = this.props.match.params.id;
        this.state = {
            id: id,
            imageDialogOpened: false,
            deleteDialogOpened: false,
            blobUrl: null,
            base64Url: null,
            linerNotes: '',
            message: '',
        };
    }

    public componentDidMount() {
        const queries = queryString.parse(location.search);
        this.props.loadOne(this.state.id, queries.from);
        this.props.getArtist(this.state.id);
    }

    public componentWillUnmount() {
        this.props.clear();
        this.props.clearArtist();
    }

    public render() {
        const { data, loading, errorMessage, classes, translator } = this.props;
        const {
            imageDialogOpened,
            linerNotes,
            blobUrl,
            base64Url,
            distributors,
            selectedDistributor,
            message,
        } = this.state;

        let src: string;
        if (base64Url) {
            src = base64Url;
        } else if (data && data.imageUrl) {
            src = data.imageUrl;
        } else {
            src = EMPTY_IMG;
        }

        let showHexArea = false;
        let selectBox;
        if (distributors && selectedDistributor) {
            showHexArea = true;
            selectBox = (
                <FormControl>
                    <Select
                        onChange={this.onSelectDistributor}
                        value={selectedDistributor.id}
                        input={<Input value={selectedDistributor.name} />}>
                        {
                            distributors.map((d) => (
                                <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            );
        }

        return (
            errorMessage ? (
                <div className={classes.errorTextContainer}>
                    <Typography variant="title" color="error" className={classes.errorText}>{errorMessage}</Typography>
                </div>
            ) : (
                    <Paper className={classes.root}>
                        <div className={classes.buttons}>
                            {selectBox}
                            <Button
                                variant="raised"
                                onClick={this.onDeleteImageClicked}
                                className={classes.button}>
                                {translator.translate('delivery__deleteImage__button')}
                            </Button>
                            <Button
                                variant="raised"
                                color="secondary"
                                className={classes.button}
                                onClick={this.onEditComplete}>
                                {translator.translate('delivery__done__button')}
                            </Button>
                            {
                                loading ? <CircularProgress /> : null
                            }
                        </div>
                        <div className={classes.contentsContainer}>
                            <div className={classes.imgContainer}>
                                <img
                                    src={src}
                                    className={classes.img}
                                    onClick={this.onClickImg}
                                    onLoad={this.onLoadImg}
                                    onError={this.onErrorImg} />
                            </div>
                            <div className={classes.linerNotes}>
                                <TextField
                                    multiline
                                    label={translator.translate('delivery__bio__label',
                                        linerNotes ? MAX_LENGTH_LINER_NOTES - linerNotes.length : MAX_LENGTH_LINER_NOTES)}
                                    placeholder={translator.translate('delivery__bio__placeholder')}
                                    value={linerNotes ? linerNotes : ''}
                                    onChange={this.onTextChanged}
                                    className={classes.linerNotesText} />
                            </div>
                        </div>
                        <Dialog open={this.state.deleteDialogOpened} onClose={this.onRequestCloseDeleteDialog.bind(this, false)}>
                            <DialogTitle>{translator.translate('delivery__deleteImage__message')}</DialogTitle>
                            <DialogActions>
                                <Button onClick={this.onRequestCloseDeleteDialog.bind(this, false)} color="primary">
                                    {translator.translate('app__cancel__button')}
                                </Button>
                                <Button onClick={this.onRequestCloseDeleteDialog.bind(this, true)} color="secondary" autoFocus>
                                    {translator.translate('delivery__deleteImageDialog__button')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={imageDialogOpened}
                            onClose={this.onCloseImageDialog}
                            maxWidth={false}>
                            <DialogContent classes={{ root: classes.imgDialog }}>
                                {blobUrl ? (
                                    <TrimImageDialog
                                        blobUrl={blobUrl}
                                        onClose={this.onCloseImageDialog}
                                        onComplete={this.onTrimImageCompleted}
                                        translator={translator}
                                        showHexArea={showHexArea} />
                                ) : (
                                        <SelectImageDialog
                                            onSelect={this.onSelectImage}
                                            onClose={this.onCloseImageDialog}
                                            onShowMessage={this.onShowMessage}
                                            translator={translator} />
                                    )
                                }
                            </DialogContent>
                        </Dialog>
                        <Snackbar
                            open={!!message}
                            message={<span>{message}</span>}
                            autoHideDuration={5000}
                            onClose={this.onCloseSnackbar} />
                    </Paper>
                )
        );
    }

    private onClickImg() {
        this.setState({ imageDialogOpened: true });
    }

    private onLoadImg(e: React.SyntheticEvent<HTMLImageElement>) {
        const { currentTarget: img } = e;
        const { style } = img;
        style.width = `${IMG_SIZE} px`;
        style.height = `${IMG_SIZE}px`;
    }

    private onErrorImg(e: React.SyntheticEvent<HTMLImageElement>) {
        const { currentTarget: img } = e;
        img.removeAttribute('crossorigin');
        img.src = EMPTY_IMG;
    }

    private onCloseImageDialog() {
        this.setState({ imageDialogOpened: false, blobUrl: null });
    }

    private onRequestCloseDeleteDialog(execute: boolean) {
        this.setState({ deleteDialogOpened: false });
        if (execute) {
            this.props.delete(this.state.id, this.state.selectedDistributor ? this.state.selectedDistributor.id : null);
            this.setState({ base64Url: null });
            this.props.clearImage();
        }
    }

    private onTextChanged(e: React.ChangeEvent<HTMLInputElement>) {
        const text = e.target.value;
        const { linerNotes = '' } = this.state;
        if (MAX_LENGTH_LINER_NOTES - linerNotes.length > 0) {
            this.setState({
                linerNotes: text,
            });
        }
    }

    private onSelectImage(blobUrl: string) {
        this.setState({ blobUrl: blobUrl });
    }

    private onTrimImageCompleted(base64Url: string) {
        this.setState({ base64Url: base64Url });
    }

    private onShowMessage(message: string) {
        this.setState({ message: message });
    }

    private onEditComplete() {
        const { id, linerNotes, selectedDistributor } = this.state;
        let { base64Url } = this.state;
        if (base64Url) {
            base64Url = base64Url.split(',')[1];
        }
        this.props.update(
            id,
            base64Url,
            linerNotes,
            selectedDistributor ? selectedDistributor.id : null
        );
    }

    private onDeleteImageClicked() {
        this.setState({ deleteDialogOpened: true });
    }

    private onCloseSnackbar() {
        this.setState({ message: '' });
    }

    private onSelectDistributor(e: React.ChangeEvent<HTMLSelectElement>) {
        const selectedDistributor = _.find(this.state.distributors, { id: e.target.value });
        if (selectedDistributor) {
            this.props.loadOne(this.state.id, 'admintool', selectedDistributor.id);
        }
    }

}

export default decorate<Props>(Artist);
