import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

interface Props {
    isLoggedIn: boolean;
    refreshToken: () => void;
    loadNotifications: () => dashboard.Notification[];
}

type MergedProps = Props & RouteComponentProps<{}>;

class AuthenticatedRoute extends React.Component<MergedProps, {}> {

    public componentDidMount() {
        if (this.props.isLoggedIn) {
            this.props.refreshToken();
            this.props.loadNotifications();
        }
    }

    public render() {
        const { children, isLoggedIn, location } = this.props;
        return isLoggedIn ? (
            <Route children={children} />
        ) : (
                <Redirect to={`/login?redirect=${location.pathname}${location.search}`} />
            );
    }
}

export default AuthenticatedRoute;