type MaybeFalsy<T> = T | false | 0 | '' | null | undefined;
function notNull<T>(value: MaybeFalsy<T>): value is T {
  return Boolean(value);
}

export function compactMap<T, U>(array: T[], iterator: (item: T, index?: number) => MaybeFalsy<U>): U[] {
  return array
    .map((item, index) => iterator(item, index))
    .filter(notNull);
}
