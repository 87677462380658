import { Button, Theme, withStyles, WithStyles } from '@material-ui/core';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React from 'react';
import { Translator } from '../../../utils/localization';

const IMG_CONTAINER_SIZE = 400;
let primaryColor: string;
let accentColor: string;

type ClassNames = 'imgContainer'
    | 'img'
    | 'hexArea'
    | 'crop'
    | 'slider'
    | 'button';

const decorate = withStyles<ClassNames>((theme: Theme) => {
    primaryColor = theme.palette.primary.main;
    accentColor = theme.palette.secondary.main;
    return {
        imgContainer: {
            width: IMG_CONTAINER_SIZE,
            height: IMG_CONTAINER_SIZE,
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: theme.palette.grey[400],
        },
        img: {
            position: 'absolute',
        },
        hexArea: {
            position: 'absolute',
            width: '100%',
            height: '100%',
        },
        crop: {
            /* tslint:disable:object-literal-key-quotes */
            width: IMG_CONTAINER_SIZE,
            height: IMG_CONTAINER_SIZE,
            border: 'solid 1px #ccc',
            position: 'absolute',
            boxSizing: 'border-box',
            /* tslint:enable-:object-literal-key-quotes */
            '&:hover': {
                cursor: 'move',
            },
        },
        slider: {
            margin: '16px 0 ',
        },
        button: {
            margin: '4px 4px 0 4px',
        },
    };
});

interface Props {
    blobUrl: string;
    translator: Translator;
    showHexArea: boolean;
    onClose: () => void;
    onComplete: (base64Url: string) => void;
}

interface State {
    sliderValue: number;
    dragging: boolean;
    prevX: number;
    prevY: number;
    translateX: number;
    translateY: number;
    naturalWidth: number;
    naturalHeight: number;
    ratio: number;
}

class TrimImageDialog extends React.Component<Props & WithStyles<ClassNames>, State> {

    private imgContainerDom: HTMLDivElement | null = null;
    private imgDom: HTMLImageElement | null = null;
    private hexArea: HTMLCanvasElement | null = null;

    constructor(props: Props & WithStyles<ClassNames>) {
        super(props);
        this.onLoad = this.onLoad.bind(this);
        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDragMove = this.handleDragMove.bind(this);
        this.handleDragEnd = this.handleDragEnd.bind(this);
        this.onSliderChanged = this.onSliderChanged.bind(this);
        this.onComplete = this.onComplete.bind(this);
        this.state = {
            sliderValue: 1,
            dragging: false,
            prevX: 0,
            prevY: 0,
            translateX: 0,
            translateY: 0,
            naturalWidth: 0,
            naturalHeight: 0,
            ratio: 1,
        };
    }

    public componentDidMount() {
        if (!this.hexArea) { return; }
        this.renderHexArea(this.hexArea);
    }

    public componentDidUpdate(_: any, prevState: State) {

        if (!this.imgDom || !this.imgContainerDom) { return; }

        const parentDom = this.imgContainerDom.getClientRects()[0] as DOMRect;
        const childDom = this.imgDom.getClientRects()[0] as DOMRect;

        const isHorizontalNG = (
            parentDom.x - childDom.x < 0 ||
            (parentDom.x + IMG_CONTAINER_SIZE) - (childDom.x + childDom.width) > 0
        );
        const isVerticalNG = (
            parentDom.y - childDom.y < 0 ||
            (parentDom.y + IMG_CONTAINER_SIZE) - (childDom.y + childDom.height) > 0
        );

        if (isHorizontalNG || isVerticalNG) {
            this.setState({
                prevX: isHorizontalNG ? prevState.prevX : this.state.prevX,
                prevY: isVerticalNG ? prevState.prevY : this.state.prevY,
                translateX: isHorizontalNG ? prevState.translateX : this.state.translateX,
                translateY: isVerticalNG ? prevState.translateY : this.state.translateY,
                sliderValue: prevState.sliderValue,
            });
            return;
        }
    }

    public render() {
        if (!this.state) { return null; }
        const { blobUrl, translator, onClose, classes, showHexArea } = this.props;
        const { sliderValue, translateX, translateY } = this.state;

        return (
            <>
                <div className={classes.imgContainer}
                    ref={(e) => { this.imgContainerDom = e; }}>
                    <img
                        src={blobUrl}
                        onLoad={this.onLoad}
                        className={classes.img}
                        ref={(e) => { this.imgDom = e; }}
                        style={{ transform: `scale(${sliderValue}) translate(${translateX}px,${translateY}px)` }} />
                    {
                        showHexArea ? (
                            <canvas
                                className={classes.hexArea}
                                ref={(e) => { this.hexArea = e; }}
                                width={IMG_CONTAINER_SIZE * 2}
                                height={IMG_CONTAINER_SIZE * 2}
                            />
                        ) : null
                    }
                    <div className={classes.crop}
                        onMouseDown={this.handleDragStart}
                        onMouseMove={this.handleDragMove}
                        onMouseUp={this.handleDragEnd}
                        onMouseLeave={this.handleDragEnd} />
                </div>
                <Slider
                    className={classes.slider}
                    value={sliderValue}
                    min={0.5}
                    max={1.5}
                    step={0.01}
                    railStyle={{ backgroundColor: primaryColor }}
                    trackStyle={{ backgroundColor: accentColor }}
                    handleStyle={{ border: `solid 2px ${accentColor}` }}
                    onChange={this.onSliderChanged} />
                <div>
                    <Button
                        variant="raised"
                        className={classes.button}
                        onClick={onClose}>
                        {translator.translate('app__cancel__button')}
                    </Button>
                    <Button
                        variant="raised"
                        color="secondary"
                        className={classes.button}
                        onClick={this.onComplete}>
                        {translator.translate('app__done__button')}
                    </Button>
                </div>
            </>
        );
    }

    private onLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (!this.imgDom) { return; }
        const { naturalWidth: iw, naturalHeight: ih } = this.imgDom;

        let w: number;
        let h: number;
        let margin: number;
        let ratio: number;
        if (iw > ih) {
            h = IMG_CONTAINER_SIZE;
            ratio = ih / IMG_CONTAINER_SIZE;
            w = Math.round(iw / ratio);
            margin = (w - IMG_CONTAINER_SIZE) / 2;
            this.imgDom.style.marginLeft = `-${margin}px`;
            this.imgDom.style.marginTop = `0px`;
        } else {
            w = IMG_CONTAINER_SIZE;
            ratio = iw / IMG_CONTAINER_SIZE;
            h = Math.round(ih / ratio);
            margin = (h - IMG_CONTAINER_SIZE) / 2;
            this.imgDom.style.marginTop = `-${margin}px`;
            this.imgDom.style.marginLeft = `0px`;
        }
        this.imgDom.style.width = `${w}px`;
        this.imgDom.style.height = `${h}px`;

        const canvas = document.createElement('canvas');
        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
        if (!ctx) { return false; }
        ctx.drawImage(this.imgDom, 0, 0, iw, ih);

        this.setState({
            naturalWidth: iw,
            naturalHeight: ih,
            ratio: ratio,
        });
    }

    private handleDragStart(e: React.DragEvent<HTMLDivElement>) {
        const { clientX, clientY } = e;
        this.setState({ dragging: true, prevX: clientX, prevY: clientY });
    }

    private handleDragMove(e: React.DragEvent<HTMLDivElement>) {
        if (this.state.dragging) {
            const { clientX: currX, clientY: currY } = e;
            const { prevX, prevY, translateX, translateY, ratio, naturalWidth } = this.state;

            const distanceX = currX - prevX;
            const distanceY = currY - prevY;

            if (distanceY === 0 && distanceX === 0) {
                return;
            }

            this.setState({
                prevX: currX,
                prevY: currY,
                translateX: distanceX + translateX,
                translateY: distanceY + translateY,
            });
        }
    }

    private handleDragEnd() {
        this.setState({ dragging: false });
    }

    private onSliderChanged(value: number) {
        this.setState({ sliderValue: value });
    }

    private onComplete() {
        if (!this.imgDom) { return; }
        const canvas = document.createElement('canvas');
        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
        if (!ctx) { return false; }

        const {
            naturalWidth,
            naturalHeight,
            translateX,
            translateY,
            sliderValue,
            ratio = 1,
        } = this.state;

        const naturalCroppedSize = IMG_CONTAINER_SIZE * ratio / sliderValue;

        const sx = (naturalWidth - naturalCroppedSize) / 2 - translateX * ratio;
        const sy = (naturalHeight - naturalCroppedSize) / 2 - translateY * ratio;

        canvas.width = naturalCroppedSize;
        canvas.height = naturalCroppedSize;

        ctx.drawImage(this.imgDom, sx, sy, naturalCroppedSize, naturalCroppedSize, 0, 0, naturalCroppedSize, naturalCroppedSize);

        const base64Url = canvas.toDataURL();
        this.props.onComplete(base64Url);
        this.props.onClose();
    }

    private renderHexArea(canvas: HTMLCanvasElement) {
        const context = canvas && canvas.getContext('2d');
        if (!context) {
            return;
        }

        const fullSize = IMG_CONTAINER_SIZE * 2;
        const halfSize = fullSize / 2;
        const sin60 = Math.sin(Math.PI / 3);

        const padding = 0;
        const imageSize = fullSize - 2 * padding;
        const borderWidth = 2 * 2;

        const leftEnd = borderWidth + padding;
        const rightEnd = fullSize - (borderWidth + padding);
        const topEnd = Math.round(halfSize * (1 - sin60)) + padding;
        const bottomEnd = Math.round(halfSize * (1 + sin60)) - padding;

        const leftOneQuarter = imageSize * (1 / 4) + padding;
        const leftThreeQuarter = imageSize * (3 / 4) + padding;

        context.save();

        context.lineWidth = borderWidth;
        context.strokeStyle = 'rgba(204, 204, 204, 0.4)';

        context.beginPath();
        context.moveTo(leftEnd, halfSize);
        context.lineTo(leftOneQuarter, topEnd);
        context.lineTo(leftThreeQuarter, topEnd);
        context.lineTo(rightEnd, halfSize);
        context.lineTo(leftThreeQuarter, bottomEnd);
        context.lineTo(leftOneQuarter, bottomEnd);
        context.closePath();
        context.save();

        context.clip();
        context.restore();
        context.stroke();
        context.restore();
    }

}

export default decorate<Props>(TrimImageDialog);
