import _ from 'lodash';
import { en, ja, notificationsEn, notificationsJa } from './i18n';

export interface Dictionary {
    app__errorMessage1: string;
    app__errorMessage2: string;
    app__done__button: string;
    app__cancel__button: string;

    toolbar__date__show: string;
    toolbar__showSharePage: string;
    toolbar__period__default: string;
    toolbar__period__yesterday: string;
    toolbar__period__day: string;
    toolbar__period__week: string;
    toolbar__period__month: string;
    toolbar__period__year: string;
    toolbar__download__tooltip: string;
    toolbar__downloading__tooltip: string;

    leftNav__summary: string;
    leftNav__analysis: string;
    leftNav__deliveries: string;
    leftNav__logout: string;
    leftNav__contact: string;

    login__note: string;
    login__errorMessage1: string;
    login__errorMessage2: string;

    summary__loading__message1: string;
    summary__loading__message2: string;
    summary__loading__cancel: string;
    summary__loading__cancelled: string;

    summary__figure__plays: string;
    summary__figure__users: string;
    summary__figure__playsShare: string;
    summary__figure__usersShare: string;

    summary__graph__plays: string;
    summary__graph__users: string;
    summary__graph__favCount: string;
    summary__graph__legend__playsOver30s: string;
    summary__graph__legend__playsUnder30s: string;
    summary__graph__legend__users: string;
    summary__graph__legend__artists: string;
    summary__graph__legend__albums: string;
    summary__graph__legend__tracks: string;
    summary__graph__legend__playlists: string;

    summary__rank__unpublishedPlaylist: string;

    deliveries__noImage: string;
    deliveries__noBio: string;

    delivery__adopted__tooltip: string;
    delivery__deleteImage__button: string;
    delivery__done__button: string;
    delivery__deleteImage__message: string;
    delivery__bio__label: string;
    delivery__bio__placeholder: string;
    delivery__updateMessage1: string;
    delivery__updateMessage2: string;
    delivery__deleteImageDialog__button: string;

    delivery__viewer__error__not__allowed: string;
    delivery__viewer__error__not__found: string;

    selectImageDialog__message1: string;
    selectImageDialog__message2: string;
    selectImageDialog__message3: string;
    selectImageDialog__note__size: string;
    selectImageDialog__note__format: string;
    selectImageDialog__errorMessage1: string;
    selectImageDialog__errorMessage2: string;
    selectImageDialog__errorMessage3: string;

    analysis__topArtists__title: string;
    analysis__relatedArtists__viaPlaylist__title: string;
    analysis__relatedArtists__viaPlayback__title: string;
}

type DictionaryKeys = keyof Dictionary;

/**
 * Translator class
 */
export class Translator {

    private language: string;
    private dictionary: Dictionary;
    private notifications: Array<{ id: number, text: string }>;

    constructor(lang: string) {
        if (lang.match(/(ja|ja-JP)/i)) {
            this.language = 'ja';
            this.dictionary = ja;
            this.notifications = notificationsJa;
        } else {
            this.language = 'en';
            this.dictionary = en;
            this.notifications = notificationsEn;
        }
    }

    /**
     * Translate
     * @param key - key from DictionaryKeys
     * @param value - value to be formatted
     */
    public translate(key: DictionaryKeys, value?: string | number) {
        let translatedString = this.dictionary[key];
        if (!value) { return translatedString; }

        if (this.language === 'en' && typeof value === 'number') {
            if (value !== 1) {
                translatedString = `${translatedString}s`;
            }
        }

        const compiled = _.template(translatedString);
        return compiled({ s: value });
    }

    /**
     * Get language
     */
    public getLanguage() {
        return this.language;
    }

    /**
     * Load notification list
     */
    public loadNotifications() {
        return this.notifications;
    }

}
