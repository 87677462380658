export const KEY_COMPANY = 'company';
export const KEY_TOKEN = 'token';
export const KEY_NOTIFICATIONS = 'notifications';
export const KEY_NOTIFICATION_READ_ID = 'notification_read_id';
export const KEY_SEARCH_HISTORIES = 'search_histories';

export const load = (key: string): string | null => {
    try {
        return localStorage.getItem(key);
    } catch (err) {
        console.error('failed to load from local storage.', err);
        return null;
    }
};

export const save = (
    company?: string | null,
    token?: string | null,
    notifications?: dashboard.Notification[] | null,
    notificationReadId?: number,
    searchHistories?: dashboard.SearchSet
) => {
    try {
        if (company) {
            localStorage.setItem(KEY_COMPANY, company);
        }
        if (token) {
            localStorage.setItem(KEY_TOKEN, token);
        }
        if (notifications) {
            localStorage.setItem(KEY_NOTIFICATIONS, JSON.stringify(notifications));
        }
        if (notificationReadId) {
            localStorage.setItem(KEY_NOTIFICATION_READ_ID, Number(notificationReadId).toString());
        }
        if (searchHistories) {
            localStorage.setItem(KEY_SEARCH_HISTORIES, JSON.stringify(searchHistories));
        }
    } catch (err) {
        console.error('failed to save to local storage.', err);
    }
};

export const remove = () => {
    try {
        const keys = [KEY_COMPANY, KEY_TOKEN, KEY_SEARCH_HISTORIES];
        keys.forEach((key) => {
            localStorage.removeItem(key);
        });
    } catch (err) {
        console.error('failed to remove from local storage.', err);
    }
};

export const isLoggedIn = (): boolean => {
    const token = load(KEY_TOKEN);
    return !!token;
};