import { Button, Paper, TextField, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import * as queryString from 'query-string';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Translator } from '../utils/localization';

type ClassNames = 'root'
    | 'formContainer'
    | 'textField'
    | 'button'
    | 'descriptionContainer';

const decorate = withStyles<ClassNames>((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: 400,
        position: 'absolute',
        left: 0,
        right: 0,
        margin: 'auto',
        padding: 30,
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 30,
        alignItems: 'center',
        padding: 20,
    },
    textField: {
        width: '100%',
        marginTop: 8,
    },
    button: {
        color: theme.palette.common.white,
        width: '100%',
        marginTop: 24,
        marginBottom: 8,
    },
    descriptionContainer: {
        padding: 20,
    },
}));

interface Props {
    isLoggedIn: boolean;
    errorMessage: string | null;
    translator: Translator;
    login: (username: string, password: string) => void;
}

interface State {
    inputedUsername: string;
    inputedPassword: string;
}

type MergedProps = Props & WithStyles<ClassNames>;

class Login extends React.Component<MergedProps, State> {

    constructor(props: MergedProps) {
        super(props);
        this.state = { inputedUsername: '', inputedPassword: '' };
        this.onLogin = this.onLogin.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
    }

    public render() {
        const { classes, isLoggedIn, errorMessage, translator } = this.props;
        const { inputedUsername, inputedPassword } = this.state;
        const queries = queryString.parse(location.search);

        return (
            isLoggedIn ? (
                <Redirect to={`${queries.redirect}`} />
            ) : (
                    <div className={classes.root}>
                        <Paper className={classes.formContainer}>
                            <Typography color="error">
                                {errorMessage}
                            </Typography>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                value={inputedUsername}
                                label={'User Name'}
                                onChange={this.onChangeUsername} />
                            <TextField
                                className={classes.textField}
                                value={inputedPassword}
                                label={'Password'}
                                type="password"
                                onChange={this.onChangePassword} />
                            <Button
                                variant="raised"
                                color="secondary"
                                onClick={this.onLogin}
                                className={classes.button}>
                                log in
                            </Button>
                        </Paper>
                        <Paper className={classes.descriptionContainer}>
                            <Typography variant="subheading">{translator.translate('login__note')}</Typography>
                            <Typography color="primary">Google Chrome, Safari, Mozilla Firefox, Microsoft Edge</Typography>
                        </Paper>
                    </div>
                )
        );
    }

    private onLogin() {
        const { login } = this.props;
        const { inputedUsername, inputedPassword } = this.state;
        login(inputedUsername, inputedPassword);
    }

    private onChangeUsername(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            inputedUsername: e.target.value,
        });
    }

    private onChangePassword(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            inputedPassword: e.target.value,
        });
    }
}

export default decorate<Props>(Login);
