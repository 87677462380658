import {
    Theme,
    WithStyles,
    withStyles,
} from '@material-ui/core';

export type StyleProps = WithStyles<keyof ReturnType<typeof styles>>

export const decorate = withStyles(styles);

function styles(theme: Theme) {
    return {
        root: {
            display: 'block',
            padding: theme.spacing.unit / 2,
            width: '100%',
            boxSizing: 'border-box',
        },
        ol: {
            margin: 0,
            display: 'flex',
            padding: 0,
            listStyle: 'none',
            alignItems: 'center',
            height: 24,
            fontSize: '0.875rem',
            overflow: 'hidden',
        },
        li: {
            color: theme.palette.grey[700],
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '20%',
            '&:last-of-type': {
                color: theme.palette.grey[900],
            },
        },
        link: {
            color: 'inherit',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        separator: {
            display: 'flex',
            userSelect: 'none',
            margin: `0 ${theme.spacing.unit}px`,
            overflow: 'visible',
        }
    } as const
}
