import { Button, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import React from 'react';
import { Translator } from '../../../utils/localization';

const FILE_CAPACITY_MAX = 128000000;
const FILE_SIZE_MIN = 600;

type ClassNames = 'container'
    | 'textMainArea'
    | 'textSubArea'
    | 'textTitle'
    | 'input'
    | 'textNote'
    | 'dragging'
    | 'buttons';

const decorate = withStyles<ClassNames>((theme: Theme) => ({
    container: {
        height: 480,
        width: 720,
        border: `4px dashed ${theme.palette.grey[400]}`,
        position: 'relative',
    },
    textMainArea: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    textSubArea: {
        width: '100%',
        textAlign: 'center',
        position: 'absolute',
        bottom: 0,
        marginBottom: '12px',
    },
    textTitle: {
        marginBottom: '16px',
    },
    input: {
        display: 'none',
    },
    textNote: {
        color: theme.palette.grey[500],
    },
    dragging: {
        border: `4px dashed ${theme.palette.secondary.main}`,
    },
    buttons: {
        marginTop: '16px',
    },
}));

interface Props {
    onSelect: (blobUrl: string) => void;
    onClose: () => void;
    onShowMessage: (message: string) => void;
    translator: Translator;
}

interface State {
    dragging: boolean;
}

class SelectImageDialog extends React.Component<Props & WithStyles<ClassNames>, State> {

    constructor(props: Props & WithStyles<ClassNames>) {
        super(props);
        this.state = { dragging: false };
        this.onSelect = this.onSelect.bind(this);
        this.handleDragEnter = this.handleDragEnter.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }

    public render() {
        const { onClose, classes, translator } = this.props;
        const { dragging } = this.state;

        return (
            <>
                <div className={`${classes.container} ${dragging ? `${classes.dragging}` : ''}`}>
                    <div
                        className={classes.textMainArea}
                        onDragEnter={this.handleDragEnter}
                        onDragOver={this.handleDragOver}
                        onDragLeave={this.handleDragLeave}
                        onDrop={this.handleDrop}>
                        <Typography variant="title" className={classes.textTitle}>
                            {translator.translate('selectImageDialog__message1')}
                        </Typography>
                        <Typography>
                            {translator.translate('selectImageDialog__message2')}
                        </Typography>
                        <input
                            accept="image/jpg,image/png"
                            className={classes.input}
                            id="file"
                            type="file"
                            onChange={this.onSelect} />
                        <label htmlFor="file">
                            <Button
                                variant="raised"
                                component="span"
                                size="small">
                                {translator.translate('selectImageDialog__message3')}
                            </Button>
                        </label>
                    </div>
                    <div className={classes.textSubArea}>
                        <Typography className={classes.textNote}>
                            {translator.translate('selectImageDialog__note__size')}: 1920px x 1920px
                        </Typography>
                        <Typography className={classes.textNote}>
                            {translator.translate('selectImageDialog__note__format')}: png / jpg
                        </Typography>
                    </div>
                </div>
                <Button
                    variant="raised"
                    onClick={onClose}
                    className={classes.buttons}>
                    {translator.translate('app__cancel__button')}
                </Button>
            </>
        );
    }

    private prepare(file: File) {
        const { onShowMessage, translator } = this.props;
        if (!file.type.match(/^image\/(jpg|jpeg|png)/i)) {
            onShowMessage(translator.translate('selectImageDialog__errorMessage1'));
        } else if (file.size > FILE_CAPACITY_MAX) {
            onShowMessage(translator.translate('selectImageDialog__errorMessage2'));
        } else {
            const blobUrl: string = URL.createObjectURL(file);
            const img = new Image();
            img.src = blobUrl;
            let w = 0;
            let h = 0;
            img.onload = () => {
                w = img.naturalWidth;
                h = img.naturalHeight;
                if (w < FILE_SIZE_MIN || h < FILE_SIZE_MIN) {
                    onShowMessage(translator.translate('selectImageDialog__errorMessage3', FILE_SIZE_MIN));
                } else {
                    this.props.onSelect(blobUrl);
                }
            };
        }
    }

    private onSelect(e: React.SyntheticEvent<HTMLInputElement>) {
        if (!e.currentTarget.files) { return; }
        this.prepare(e.currentTarget.files[0]);
    }

    private handleDragEnter() {
        this.setState({ dragging: true });
    }

    private handleDragOver(e: React.SyntheticEvent<HTMLDivElement>) {
        e.preventDefault();
    }

    private handleDragLeave() {
        this.setState({ dragging: false });
    }

    private handleDrop(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        this.prepare(e.dataTransfer.files[0]);
        this.setState({ dragging: false });
    }

}

export default decorate<Props>(SelectImageDialog);
