import { Translator } from '../utils/localization';

export const RESIZE = 'WINDOW_RESIZE';
export const LOCALIZE = 'WINDOW_LOCALIZE';

export interface Resize {
    type: typeof RESIZE;
    width: number;
    height: number;
}

export interface Localize {
    type: typeof LOCALIZE;
    translator: Translator;
}

export type WindowAction = Resize | Localize;

export const resize = (width: number, height: number): Resize => {
    return {
        type: RESIZE,
        width,
        height,
    };
};

export const localize = (): Localize => {
    return {
        type: LOCALIZE,
        translator: new Translator(navigator.language),
    };
};