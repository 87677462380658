export default {
    app__errorMessage1: 'An error occurred.',
    app__errorMessage2: 'Please report us via CONTACT in the menu.',
    app__done__button: 'Done',
    app__cancel__button: 'Cancel',

    toolbar__date__show: 'Show',
    toolbar__showSharePage: 'Show a page you can try to listen',
    toolbar__period__default: 'Select a period',
    toolbar__period__yesterday: 'Yesterday',
    toolbar__period__day: '${s} day',
    toolbar__period__week: '${s} week',
    toolbar__period__month: '${s} month',
    toolbar__period__year: '${s} year',
    toolbar__download__tooltip: 'Download this data',
    toolbar__downloading__tooltip: 'Downloading...',

    leftNav__summary: 'SUMMARY',
    leftNav__analysis: 'ARTIST ANALYSIS(beta)',
    leftNav__deliveries: 'DELIVERIES',
    leftNav__logout: 'LOG OUT',
    leftNav__contact: 'CONTACT US',

    login__note: 'Recommended browsers',
    login__errorMessage1: 'A session is expired. (${s})',
    login__errorMessage2: 'Wrong User Name or Password',

    summary__loading__message1: 'Aggregating data...',
    summary__loading__message2: 'Just a minute',
    summary__loading__cancel: 'Cancel loading',
    summary__loading__cancelled: 'Loading has been cancelled',

    summary__figure__plays: 'Play count(30 sec. or more / Total)',
    summary__figure__users: 'Listeners',
    summary__figure__playsShare: 'Percentage of play count',
    summary__figure__usersShare: 'Percentage of listeners',

    summary__graph__plays: 'Number of play count',
    summary__graph__users: 'Number of listeners',
    summary__graph__favCount: 'Number of favorites added',

    summary__graph__legend__playsOver30s: '30 seconds or more',
    summary__graph__legend__playsUnder30s: 'Less than 30 seconds',
    summary__graph__legend__users: 'Number of listeners',
    summary__graph__legend__artists: 'Artists',
    summary__graph__legend__albums: 'Albums',
    summary__graph__legend__tracks: 'Tracks',
    summary__graph__legend__playlists: 'Playlists',

    summary__rank__unpublishedPlaylist: 'Unpublished playlist',

    deliveries__noImage: 'No image',
    deliveries__noBio: 'No bio.',

    delivery__adopted__tooltip: 'This artist is belong to your label currently. The set data is reflected in the application.',
    delivery__deleteImage__button: 'Delete image',
    delivery__done__button: 'Done',
    delivery__deleteImage__message: 'Are you sure to delete this image?',
    delivery__bio__label: 'You can enter ${s} more character',
    delivery__bio__placeholder: 'Enter bio. here...',
    delivery__updateMessage1: 'Profile updated',
    delivery__updateMessage2: 'Image deleted',
    delivery__deleteImageDialog__button: 'Delete',

    delivery__viewer__error__not__allowed: 'You are not allowed to view this content.\nIt may be delivered from a different account or may not be linked to any accounts.',
    delivery__viewer__error__not__found: 'The content is not found, please check the URL.',

    selectImageDialog__message1: 'Drop image here',
    selectImageDialog__message2: 'Or...',
    selectImageDialog__message3: 'Select the image to upload',
    selectImageDialog__note__size: 'Recommended size',
    selectImageDialog__note__format: 'Supported format',
    selectImageDialog__errorMessage1: 'Please upload in jpg or png format',
    selectImageDialog__errorMessage2: 'Please upload within 128 MB',
    selectImageDialog__errorMessage3: 'Please upload at least ${s} px in height and width',

    analysis__topArtists__title: 'Most popular artists TOP 10',
    analysis__relatedArtists__viaPlaylist__title: 'Artists often in the same playlist',
    analysis__relatedArtists__viaPlayback__title: 'Artists that are often played back and forth',
};
