import React from 'react';
// import {
// } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { decorate, StyleProps } from './style'

type Props = {
    items: {
        link?: string
        label: string
    }[]
}

type MergedProps = Props & StyleProps;

class Breadcrumbs extends React.Component<MergedProps> {
    render() {
        const { classes: s, items } = this.props

        return (
            <nav className={s.root}>
                <ol className={s.ol}>
                    {
                        items.map((item, idx) => {
                            const isLast = idx === items.length - 1;

                            if (isLast) {
                                return this.buildItem(`${idx}`, item);
                            } else {
                                return [
                                    this.buildItem(`${idx}`, item),
                                    this.buildSeparator(`s${idx}`),
                                ];
                            }
                        })
                    }
                </ol>
            </nav>
        )
    }

    buildItem(key: string, item: Props['items'][0]) {
        const { classes: s } = this.props

        return (
            <li key={key} className={s.li}>
                {
                    item.link ? (
                        <Link to={item.link} className={s.link}>{item.label}</Link>
                    ) : (
                        <span>{item.label}</span>
                    )
                }
            </li>
        );
    }

    buildSeparator(key: string) {
        const { classes: s } = this.props

        return (
            <li key={key} aria-hidden='true' className={[ s.li, s.separator ].join(' ')}>
                <span>›</span>
            </li>
        );
    }
}

export default decorate<Props>(Breadcrumbs)
