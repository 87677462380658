import {
    ANALYSIS_CLEAR,
    ANALYSIS_FAILED,
    ANALYSIS_LOAD_RELATED_VIA_PLAYBACK_SUCCEEDED,
    ANALYSIS_LOAD_RELATED_VIA_PLAYLIST_SUCCEEDED,
    ANALYSIS_LOAD_TOP_SUCCEEDED,
    AnalysisAction
} from './../actions/analysis';
import { AnalysisState } from './../stores/analysisState';

export default (state: AnalysisState, action: AnalysisAction): AnalysisState => {
    switch (action.type) {
        case ANALYSIS_LOAD_TOP_SUCCEEDED:
            return { ...state, results: action.results };
        case ANALYSIS_LOAD_RELATED_VIA_PLAYLIST_SUCCEEDED:
            return { ...state, relatedResultsViaPlaylist: action.results };
        case ANALYSIS_LOAD_RELATED_VIA_PLAYBACK_SUCCEEDED:
            return { ...state, relatedResultsViaPlayback: action.results };
        case ANALYSIS_CLEAR:
            return { ...state, results: [], relatedResultsViaPlaylist: [], relatedResultsViaPlayback: [], errorMessage: undefined };
        case ANALYSIS_FAILED:
            return { ...state, errorMessage: action.errorMessage };
        default:
            const _never: never = action;
            return state || {};
    }
};
