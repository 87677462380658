import {
    Button, CircularProgress, Grid, Hidden, IconButton, TextField, Theme, Tooltip, Typography, withStyles, WithStyles
} from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FileDownloadIcon from '@material-ui/icons/FileDownload';
import ForwardIcon from '@material-ui/icons/Forward';
import * as H from 'history';
import moment from 'moment';
import * as queryString from 'query-string';
import React from 'react';
import { match as MatchType, RouteComponentProps } from 'react-router-dom';
import { DATE_FORMAT } from '../../consts';
import { Translator } from '../../utils/localization';
import { Type as SearchType } from '../SearchBox';
import SearchBox from '../SearchBox';
import Toolbar from '../Toolbar';
import { Figures } from './Figures';
import { Graphs } from './Graphs';
import { Ranks } from './Ranks';
import Selects from './Selects';

const DATE_FORMAT_FOR_PICKER = 'YYYY-MM-DD';
const minDate = moment().subtract(1, 'year').startOf('day').format(DATE_FORMAT_FOR_PICKER);
const maxDate = moment().subtract(1, 'day').startOf('day').format(DATE_FORMAT_FOR_PICKER);

type ClassNames = 'children'
    | 'root'
    | 'toolbarDateContainer'
    | 'toolbarSearchContainer'
    | 'toolbarForwardIcon'
    | 'toolbarShowButton'
    | 'progressContainer'
    | 'progress'
    | 'circularProgress'
    | 'progressText'
    | 'progressCancelTextContainer'
    | 'progressCancelText'
    | 'errorTextContainer'
    | 'errorText';

const decorate = withStyles<ClassNames>((theme: Theme) => ({
    children: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '118px',
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: '64px',
        },
    },
    root: {
        padding: 16,
        backgroundColor: theme.palette.grey[100],
    },
    toolbarDateContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    toolbarSearchContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    toolbarForwardIcon: {
        marginRight: 8,
    },
    toolbarShowButton: {
        marginLeft: 8,
    },
    progressContainer: {
        position: 'relative',
        height: 'calc(100% + 64px)',
        marginTop: -64,
        backgroundColor: 'rgba(0,0,0,0.4)',
    },
    progress: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        margin: 'auto',
        zIndex: 1000,
        textAlign: 'center',
    },
    circularProgress: {
        color: theme.palette.common.white,
    },
    progressText: {
        color: theme.palette.common.white,
    },
    progressCancelTextContainer: {
        marginTop: 16,
    },
    progressCancelText: {
        color: theme.palette.common.white,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    errorTextContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    errorText: {
        marginTop: 32,
    },
}));

interface Props {
    summaryResult: dashboard.Summary;
    errorMessage: string;
    cancelled: boolean;
    width: number;
    height: number;
    translator: Translator;
    searchResult: dashboard.SearchSet;
    searchErrorMessage: string | undefined;
    location: H.Location;
    match: MatchType<{ id: string }>;
    hasNextRankings: { [key: string]: boolean };
    load: (type: string, id: string, start: string, end: string) => void;
    clear: () => void;
    download: (type: string, id: string, start: string, end: string) => void;
    cancel: () => void;
    loadMoreRanking:
    (start: string, end: string, offset: number, limit: number, type: string, target?: { uriType: string, id: string } | null) => void;
    search: (query: string) => void;
    loadHistories: () => void;
    saveHistory: (history: dashboard.SearchArtist | dashboard.SearchAlbum | dashboard.SearchTrack) => void;
    getCommonInfo: (type: dashboard.CommonInfoType, id: string) => void;
    clearCommonInfo: () => void;
}

interface State {
    id: string;
    uriType: dashboard.summary.UriType;
    start: moment.Moment;
    end: moment.Moment;
    inputedStart: string;
    inputedEnd: string;
    fileDownloading: boolean;
}

type MergedProps = Props & WithStyles<ClassNames> & RouteComponentProps<{ id: string }>;

class Summary extends React.Component<MergedProps, State> {

    public static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        return { fileDownloading: false };
    }

    constructor(props: MergedProps) {
        super(props);
        this.onSelectPeriod = this.onSelectPeriod.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.onCancelClicked = this.onCancelClicked.bind(this);
        this.state = { id: '', uriType: 'label', start: moment(), end: moment(), inputedStart: '', inputedEnd: '', fileDownloading: false };
    }

    public componentDidMount() {
        this.load(this.props.match, this.props.location);
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        const {
            location: prevLocation,
            match: prevMatch,
        } = prevProps;
        const {
            location, match,
        } = this.props;
        if (location.search !== prevLocation.search || match.params.id !== prevMatch.params.id) {
            this.load(match, location);
        }
    }

    public componentWillUnmount() {
        this.props.clear();
        this.props.clearCommonInfo();
    }

    public render() {
        const { summaryResult, classes, cancelled, errorMessage, ...other } = this.props;
        let errorText;
        if (cancelled) {
            errorText = this.props.translator.translate('summary__loading__cancelled');
        } else if (errorMessage) {
            errorText = errorMessage;
        }
        if (cancelled || errorMessage) {
            return (
                <div className={classes.errorTextContainer}>
                    <Typography variant="title" color="error" className={classes.errorText}>{errorText}</Typography>
                </div>
            );
        }

        return summaryResult ? (
            <div className={classes.root}>
                <Toolbar>
                    <Grid container>
                        <Grid item xs={12} sm={6} className={classes.toolbarDateContainer}>
                            <TextField
                                type="date"
                                value={this.state.inputedStart}
                                InputProps={{
                                    inputProps: {
                                        max: maxDate,
                                        min: minDate,
                                    },
                                }}
                                onChange={(e) => this.setState({ inputedStart: e.target.value })} />
                            <ForwardIcon color="primary" className={classes.toolbarForwardIcon} />
                            <TextField
                                type="date"
                                value={this.state.inputedEnd}
                                InputProps={{
                                    inputProps: {
                                        max: maxDate,
                                        min: minDate,
                                    },
                                }}
                                onChange={(e) => this.setState({ inputedEnd: e.target.value })} />
                            <Button variant="outlined" onClick={this.onSelectPeriod} className={classes.toolbarShowButton}>
                                {this.props.translator.translate('toolbar__date__show')}
                            </Button>
                        </Grid>
                        <Grid item sm={3}>
                            <Hidden smDown>
                                <>
                                    <Selects
                                        history={this.props.history}
                                        location={this.props.location}
                                        translator={this.props.translator} />
                                    <Tooltip title={
                                        this.state.fileDownloading ?
                                            this.props.translator.translate('toolbar__downloading__tooltip') :
                                            this.props.translator.translate('toolbar__download__tooltip')
                                    }>
                                        <IconButton onClick={this.onDownload}>
                                            {this.state.fileDownloading ? <AutorenewIcon /> : <FileDownloadIcon />}
                                        </IconButton>
                                    </Tooltip>
                                </>
                            </Hidden>
                        </Grid>
                        <Grid item xs={12} sm={3} className={classes.toolbarSearchContainer}>
                            <SearchBox
                                type={SearchType.summary}
                                result={this.props.searchResult}
                                errorMessage={this.props.searchErrorMessage}
                                history={this.props.history}
                                location={this.props.location}
                                search={this.props.search}
                                loadHistories={this.props.loadHistories}
                                saveHistory={this.props.saveHistory} />
                        </Grid>
                    </Grid>
                </Toolbar>
                <div className={classes.children}>
                    <Figures
                        playsSum={summaryResult.playsSum}
                        usersSum={summaryResult.usersSum}
                        {...other} />
                    <Graphs
                        plays={summaryResult.plays}
                        users={summaryResult.users}
                        favCount={summaryResult.favCount}
                        unit={summaryResult.unit}
                        start={this.state.start}
                        end={this.state.end}
                        {...other} />
                    <Ranks
                        uriType={this.state.uriType}
                        data={summaryResult.ranking}
                        dataSet={summaryResult.dataSet}
                        start={this.state.start}
                        end={this.state.end}
                        {...other} />
                </div>
            </div >
        ) : (
                <div className={classes.progressContainer}>
                    <div className={classes.progress} style={{ paddingTop: `${this.props.height / 2 - 100}px` }}>
                        <CircularProgress className={classes.circularProgress} size={100} />
                        <Typography className={classes.progressText}>
                            {this.props.translator.translate('summary__loading__message1')}
                        </Typography>
                        <Typography className={classes.progressText}>
                            {this.props.translator.translate('summary__loading__message2')}
                        </Typography>
                        <div className={classes.progressCancelTextContainer}>
                            <Typography className={classes.progressCancelText} onClick={this.onCancelClicked}>
                                {this.props.translator.translate('summary__loading__cancel')}
                            </Typography>
                        </div>
                    </div>
                </div>
            );
    }

    private load(match: MatchType<{ id: string }>, location: H.Location) {
        this.props.clearCommonInfo();
        const queries = queryString.parse(location.search);
        let start: moment.Moment;
        let end: moment.Moment;
        if (queries.days) {
            start = moment().subtract(Number(queries.days), 'day').startOf('day');
        } else if (queries.month) {
            start = moment().subtract(Number(queries.month), 'month').startOf('day');
        } else if (queries.start) {
            start = moment(queries.start);
        } else {
            start = moment().subtract(7, 'days').startOf('day');
        }
        if (queries.end) {
            end = moment(queries.end);
        } else {
            end = moment().subtract(1, 'days').startOf('day');
        }
        const id = match.params.id;
        const commonInfoType = match.path.split('/')[1] as dashboard.CommonInfoType;
        let uriType: dashboard.summary.UriType;
        if (!commonInfoType) {
            uriType = 'label';
        } else {
            uriType = commonInfoType;
            this.props.getCommonInfo(commonInfoType, id);
        }

        this.setState({
            id,
            uriType,
            start,
            end,
            inputedStart: start.format(DATE_FORMAT_FOR_PICKER),
            inputedEnd: end.format(DATE_FORMAT_FOR_PICKER),
        });
        this.props.load(commonInfoType, id, start.format(DATE_FORMAT), end.format(DATE_FORMAT));
    }

    private onSelectPeriod() {
        this.props.history.push(`${this.props.location.pathname}?start=${this.state.inputedStart}&end=${this.state.inputedEnd}`);
    }

    private onDownload() {
        const { uriType, start, end } = this.state;
        this.props.download(uriType, this.props.match.params.id, start.format(DATE_FORMAT), end.format(DATE_FORMAT));
        this.setState({ fileDownloading: true });
    }

    private onCancelClicked() {
        this.props.cancel();
    }
}

export default decorate<Props>(Summary);