import { WindowState } from '../stores/windowState';
import { LOCALIZE, RESIZE, WindowAction } from './../actions/window';

export default (state: WindowState, action: WindowAction): WindowState => {
    switch (action.type) {
        case RESIZE:
            return { ...state, width: action.width, height: action.height };
        case LOCALIZE:
            return { ...state, translator: action.translator };
        default:
            return state || { width: window.innerWidth, height: window.innerHeight };
    }
};