import {
    CLEAR,
    CLEAR_IMAGE,
    DeliveryAction,
    FAILED,
    LOAD_LIST_SUCCEEDED,
    LOAD_ONE_SUCCEEDED,
    LOADING,
    UPDATE_SUCCEEDED,
} from './../actions/delivery';
import { DeliveryState } from './../stores/deliveryState';

export default (state: DeliveryState, action: DeliveryAction): DeliveryState => {
    switch (action.type) {
        case LOAD_LIST_SUCCEEDED:
            return { ...state, results: action.result, loading: false };
        case LOAD_ONE_SUCCEEDED:
            return { ...state, result: action.result, updateType: null, loading: false };
        case UPDATE_SUCCEEDED:
            return { ...state, updateType: action.updateType, loading: false };
        case FAILED:
            return { ...state, errorMessage: action.errorMessage, loading: false };
        case CLEAR:
            return { ...state, results: null, result: null, errorMessage: null };
        case CLEAR_IMAGE:
            if (state.result) {
                delete state.result.imageUrl;
            }
            return { ...state, result: state.result };
        case LOADING:
            const delivery = state.result;
            if (delivery && action.linerNotes) {
                delivery.linerNotes = action.linerNotes;
            }
            return {
                ...state,
                updateType: null,
                loading: true,
                result: delivery,
            };
        default:
            return state || {};
    }
};
