import { Typography } from '@material-ui/core';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Analysis from '../containers/analysis/Artist';
import AnalysisTop from '../containers/analysis/Top';
import App from '../containers/App';
import AuthenticatedRoute from '../containers/AuthenticatedRoute';
import DeliveryEditArtist from '../containers/delivery/editor/Artist';
import DeliveryArtists from '../containers/delivery/editor/Artists';
import DeliveryAlbum from '../containers/delivery/viewer/Album';
import DeliveryArtist from '../containers/delivery/viewer/Artist';
import DeliveryTrack from '../containers/delivery/viewer/Track';
import DeliverySearch from '../containers/delivery/viewer/Search';
import Login from '../containers/Login';
import Summary from '../containers/summary/Summary';

const Routes = () => (
    <BrowserRouter>
        <App>
            <Switch>
                <Route path="/login" component={Login} />
                <AuthenticatedRoute>
                    <Switch>
                        <Route exact path="/" component={Summary} />
                        <Route path="/artist/:id/summary" component={Summary} />
                        <Route path="/album/:id/summary" component={Summary} />
                        <Route path="/track/:id/summary" component={Summary} />
                        <Route path="/delivery/artist/:id/edit" component={DeliveryEditArtist} />
                        <Route path="/delivery/artist/:id" component={DeliveryArtist} />
                        <Route path="/delivery/album/:id" component={DeliveryAlbum} />
                        <Route path="/delivery/track/:id" component={DeliveryTrack} />
                        <Route path="/delivery/artists" component={DeliveryArtists} />
                        <Route path="/delivery" component={DeliverySearch} />
                        <Route path="/artist/:id/delivery" component={DeliveryEditArtist} />
                        <Route path="/analysis" component={AnalysisTop} />
                        <Route path="/artist/:id/analysis" component={Analysis} />
                        <Route component={NoMatch} />
                    </Switch>
                </AuthenticatedRoute>
            </Switch>
        </App>
    </BrowserRouter>
);

const NoMatch = () => (
    <Typography variant="display1" style={{
        position: 'absolute',
        margin: 'auto',
        left: 0,
        right: 0,
        width: 'fit-content',
        paddingTop: 20,
    }}>NOT FOUND</Typography>
);

export default Routes;
