import { connect, Dispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../actions/delivery';
import * as searchActions from '../../../actions/search';
import Artists from '../../../components/delivery/editor/Artists';
import { RootState } from '../../../stores/rootState';

const mapStateToProps = ({ delivery, window, search }: RootState) => {
    return {
        results: delivery.results,
        errorMessage: delivery.errorMessage,
        translator: window.translator,
        searchResult: search.result,
        searchErrorMessage: search.errorMessage,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<actions.DeliveryAction | searchActions.SearchAction>) => {
    return {
        load: (offset: number, limit: number, noLiner: boolean, noImage: boolean) => {
            dispatch(actions.loadList(offset, limit, noLiner, noImage));
        },
        search: (query: string, targets?: ['artists' | 'albums' | 'tracks'], limit?: number) => {
            dispatch(searchActions.search(query, targets, limit));
        },
        loadHistories: (targets?: ['artists' | 'albums' | 'tracks']) => { dispatch(searchActions.loadHistories(targets)); },
        saveHistory: (history: dashboard.SearchArtist | dashboard.SearchAlbum | dashboard.SearchTrack) => {
            dispatch(searchActions.saveHistory(history));
        },
        clear: () => { dispatch(actions.clear()); },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Artists));
