import React from 'react';
import {
  TableRow,
  TableCell,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import ContentCopy from '@material-ui/icons/ContentCopy';
import { Link } from 'react-router-dom';
import { copyToClipboard } from '../../../../../utils/clipboard';
import { decorate, StyleProps } from './style'
import Img from '../Img';

type ArtistProps = {
    type: 'searchArtist'
    item: dashboard.SearchArtist
}

type AlbumProps = {
    type: 'searchAlbum' | 'artistAlbum'
    item: dashboard.SearchAlbum
}

type TrackProps = {
    type: 'searchTrack' | 'artistTrack' | 'albumTrack'
    item: dashboard.SearchTrack
}

type DataTypeProps = ArtistProps | AlbumProps | TrackProps

type Props = DataTypeProps & {
    index: number
    selected: boolean
    onRowClick: (index: number) => void
}

type MergedProps = Props & StyleProps;

class BodyRow extends React.Component<MergedProps> {
    shouldComponentUpdate(nextProps: Props) {
        const {
            index: nextIndex,
            selected: nextSelected,
            item: nextItem,
        } = nextProps

        const {
            index,
            selected,
            item,
        } = this.props

        return (
            nextSelected !== selected
            || nextItem.encryptedId !== item.encryptedId
            || nextIndex !== index
        )
    }

    render() {
        const { index } = this.props

        switch(this.props.type) {
            case 'searchArtist':
                {
                    const type = this.props.type;
                    const item = this.props.item;
                    return this.buildArtistRow(index, type, item);
                }
            case 'searchAlbum':
            case 'artistAlbum':
                {
                    const type = this.props.type;
                    const item = this.props.item;
                    return this.buildAlbumRow(index, type, item);
                }
            case 'searchTrack':
            case 'artistTrack':
            case 'albumTrack':
                {
                    const type = this.props.type;
                    const item = this.props.item;
                    return this.buildTrackRow(index, type, item);
                }
        }
    }


    private buildArtistRow(index: number, type: ArtistProps['type'], item: ArtistProps['item']) {
        return (
            <TableRow
                onClick={() => this.handleRowClick(index)}
            >
                {this.buildCheckCell()}
                {this.buildImgCell(item.imageUrl)}
                {this.buildLinkCell('artist', item.encryptedId, item.name, true)}
                {this.buildStatus(item.isDeleted)}
                {this.buildShareUrlCell(item.shareUrl)}
            </TableRow>
        );
    }

    private buildAlbumRow(index: number, type: AlbumProps['type'], item: AlbumProps['item']) {
        return (
            <TableRow
                onClick={() => this.handleRowClick(index)}
            >
                {this.buildCheckCell()}
                {this.buildImgCell(item.imageUrl)}
                {this.buildLinkCell('album', item.encryptedId, item.name, true)}
                {this.buildStatus(item.isDeleted)}
                {
                    type === 'searchAlbum'
                        ? this.buildLinkCell('artist', item.artistId, item.artist)
                        : null
                }
                {this.buildUpc(item.upc)}
                {this.buildShareUrlCell(item.shareUrl)}
            </TableRow>
        );
    }

    private buildTrackRow(index: number, type: TrackProps['type'], item: TrackProps['item']) {
        return (
            <TableRow
                onClick={() => this.handleRowClick(index)}
            >
                {this.buildCheckCell()}
                {
                    type === 'albumTrack'
                        ? this.buildNumberCell(index)
                        : this.buildImgCell(item.imageUrl)
                }
                {this.buildLinkCell('track', item.encryptedId, item.name, true)}
                {this.buildStatus(item.isDeleted)}
                {
                    type === 'searchTrack'
                        ? this.buildLinkCell('artist', item.artistId, item.artist)
                        : null
                }
                {
                    type === 'albumTrack'
                        ? null
                        : this.buildLinkCell('album', item.albumId, item.album)
                }
                {this.buildShareUrlCell(item.shareUrl)}
            </TableRow>
        );
    }

    private buildCheckCell() {
        const { classes: s, selected } = this.props;

        return (
            <TableCell className={s.checkColumn} padding='none'>
                <Checkbox checked={selected} />
            </TableCell>
        );
    }

    private buildImgCell(src: string) {
        const { classes: s } = this.props;
        return (
            <TableCell className={s.imgColumn} padding='none'>
                <Img src={src} className={s.img} />
            </TableCell>
        )
    }

    private buildNumberCell(index: number) {
        const { classes: s } = this.props;
        return (
            <TableCell className={s.imgColumn} padding='none'>
                {index + 1}
            </TableCell>
        );
    }

    private buildLinkCell(type: 'artist' | 'album' | 'track', id: string, name: string, main?: boolean) {
        const { classes: s } = this.props;
        const linkClassNames = [ s.link ];

        if (main) {
            linkClassNames.push(s.main);
        }

        return (
            <TableCell padding='dense' className={main ? undefined : s.subColumn}>
                <div className={s.linkWrap}>
                    <Link
                        to={`/delivery/${type}/${id}`}
                        className={linkClassNames.join(' ')}
                        title={name}
                        onClick={this.handleToStopPropagation}
                    >
                        {name}
                    </Link>
                </div>
            </TableCell>
        )
    }

    private buildStatus(isDeleted: boolean) {
        return (
            <TableCell padding='dense' className={this.props.classes.subColumn}>
                { isDeleted ? 'Stopped' : 'Published' }
            </TableCell>
        )
    }

    private buildUpc(upc: string) {
        return (
            <TableCell padding='dense' className={this.props.classes.subColumn}>
                {upc}
            </TableCell>
        )
    }

    private buildShareUrlCell(url: string) {
        const { classes: s } = this.props;
        return (
            <TableCell padding='dense'>
                <div className={s.shareUrl} onClick={this.handleToStopPropagation}>
                    <IconButton
                        aria-label="copy"
                        className={s.urlCopyIconButton}
                        onClick={() => this.copyUrl(url)}
                        title="Copy Share Url"
                    >
                        <ContentCopy className={s.urlCopyIcon} />
                    </IconButton>
                    <Link
                        className={s.shareUrlLink}
                        to={url}
                        title={url}
                        target='_blank'
                    >
                        {url.replace('https://s.awa.fm', '')}
                    </Link>
                </div>
            </TableCell>
        )
    }

    private handleToStopPropagation = (e: React.MouseEvent<any>) => {
        e.stopPropagation()
    }

    private handleRowClick(index: number) {
        this.props.onRowClick(index)
    }

    private async copyUrl(url: string) {
        if (url) {
            await copyToClipboard(url)
        }
    }
}

export default decorate<Props>(BodyRow)
