import { connect, Dispatch } from 'react-redux';
import * as actions from '../actions/login';
import Login from '../components/Login';
import { RootState } from '../stores/rootState';

const mapStateToProps = ({ login, window }: RootState) => {
    return {
        isLoggedIn: login.isLoggedIn,
        errorMessage: login.errorMessage,
        translator: window.translator,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<actions.LoginAction>) => {
    return {
        login: (username: string, password: string) => { dispatch(actions.login(username, password)); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);