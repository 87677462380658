import { Grid, Paper, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import PeopleIcon from '@material-ui/icons/People';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import React from 'react';
import { Translator } from '../../utils/localization';

type ClassNames = 'container'
    | 'value'
    | 'icon'
    | 'itemContainer'
    | 'playsContainer';

const decorate = withStyles<ClassNames>((theme: Theme) => ({
    container: {
        height: 132,
        display: 'flex',
        paddingRight: 16,
        alignItems: 'center',
    },
    value: {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        fontSize: '2rem',
        marginBottom: 8,
    },
    icon: {
        color: theme.palette.grey['600'],
        fontSize: 60,
        marginLeft: 16,
    },
    itemContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    playsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
}));

export enum Type {
    plays = 'summary__figure__plays',
    users = 'summary__figure__users',
    playsShare = 'summary__figure__playsShare',
    usersShare = 'summary__figure__usersShare',
}

interface Props {
    type: Type;
    sum?: number;
    sumOver30s?: number;
    ratio?: number;
    translator: Translator;
}

const Figure: React.SFC<Props & WithStyles<ClassNames>> = (props) => {
    let figure: JSX.Element;
    let icon;
    let value: string = '';
    const {
        sum = 0,
        sumOver30s = 0,
        ratio = 0,
        type,
        translator,
        classes,
    } = props;

    switch (props.type) {
        case Type.plays:
            value = optimizeSum(sum);
            const valueOver30s = optimizeSum(sumOver30s);
            icon = <PlaylistPlayIcon className={classes.icon} />;
            figure = buildItem(classes, `${valueOver30s} / ${value}`, type, translator);
            break;
        case Type.users:
            value = optimizeSum(sum);
            icon = <PeopleIcon className={classes.icon} />;
            figure = buildItem(classes, value, type, translator);
            break;
        case Type.playsShare:
            value = convertRatio(ratio);
            icon = <LibraryMusicIcon className={classes.icon} />;
            figure = buildItem(classes, value, type, translator);
            break;
        case Type.usersShare:
            value = convertRatio(ratio);
            icon = <PeopleOutlineIcon className={classes.icon} />;
            figure = buildItem(classes, value, type, translator);
            break;
        default:
            figure = <div />;
            break;
    }

    return (
        <Grid item xs={12} sm={6} md={3}>
            <Paper className={classes.container}>
                {icon}
                {figure}
            </Paper>
        </Grid>
    );
};

const buildItem = (classes: any, value: string, type: Type, translator: Translator): JSX.Element => {
    return (
        <div className={classes.itemContainer}>
            <Typography variant="display1" className={classes.value}>
                {value}
            </Typography>
            <Typography color="primary">
                {translator.translate(type)}
            </Typography>
        </div>
    );
};

const optimizeSum = (sum: number): string => {
    let unit = '';
    if (!sum) {
        sum = 0;
    }
    if (sum > 1000000) {
        sum /= 1000000;
        unit = 'M';
    }
    if (unit !== '') {
        if (sum < 100) {
            sum = Math.round(sum * 100) / 100;
        } else if (sum < 1000) {
            sum = Math.round(sum * 10) / 10;
        } else {
            sum = Math.round(sum);
        }
    }
    return sum.toLocaleString() + unit;
};

const convertRatio = (ratio: number): string => {
    let value = '';
    if (ratio > 0) {
        ratio *= 100;
        if (ratio < 0.1) {
            value = ratio.toFixed(3);
        } else if (ratio < 10) {
            value = ratio.toFixed(2);
        } else if (ratio < 100) {
            value = ratio.toFixed(1);
        } else {
            value = ratio.toFixed(0);
        }
        return `${value}%`;
    }
    return value;
};

export default decorate<Props>(Figure);