import {
    Theme,
    WithStyles,
    withStyles,
} from '@material-ui/core';

export type StyleProps = WithStyles<keyof ReturnType<typeof styles>>

export const decorate = withStyles(styles);

function styles(theme: Theme) {
    return {
        hidden: {
            display: 'none',
        },
        toolBar: {
            background: theme.palette.background.paper,
            position: 'sticky',
            top: 64,
            zIndex: 1,
        },
        button: {
            backgroundColor: theme.palette.grey[300],
            marginRight: theme.spacing.unit,
        },
        table: {
            tableLayout: 'fixed',
        },
        th: {
            background: theme.palette.background.paper,
            fontWeight: 'bold',
            position: 'sticky',
            top: 64 * 2,
            zIndex: 1,
            '&:after': {
                content: '""',
                display: 'block',
                width: '100%',
                height: 1,
                position: 'absolute',
                bottom: -1,
                left: 0,
                background: '#e0e0e0'
            }
        },
        subColumn: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            }
        },
        checkColumn: {
            width: 48,
        },
        imgColumn: {
            width: 48,
        },
        img: {
            display: 'block',
            width: 48,
            height: 48,
        },
        linkWrap: {
            color: theme.palette.text.secondary,
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            'word-break': 'break-all',
        },
        link: {
            color: 'inherit',

            '&:hover': {
                textDecoration: 'underline'
            },
        },
        main: {
            color: theme.palette.text.primary,
        },
        shareUrl: {
            color: theme.palette.text.secondary,
            display: 'block',
            overflow: 'hidden',
            'text-overflow': 'ellipsis',
            whiteSpace: 'nowrap',
        },
        shareUrlLink: {
            color: 'inherit',
            '&:hover': {
                textDecoration: 'underline'
            },
        },
        urlCopyIconButton: {
            width: 32,
            height: 32,
            marginRight: theme.spacing.unit
        },
        urlCopyIcon: {
            width: 16,
            height: 16
        }
    } as const
}
