import { Paper, Theme, Typography, withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles';
import d3 from 'd3';
import moment from 'moment';
import React from 'react';
import { AreaChart } from 'react-d3-components';
import { Translator } from '../../utils/localization';

let language: string;

type ClassNames = 'container'
    | 'legends'
    | 'legendsContainer'
    | 'legendColor'
    | 'legendText'
    | 'tooltip'
    | 'tooltipDatetime'
    | 'tooltipValue';

const decorate = withStyles<ClassNames>((theme: Theme) => ({
    container: {
        padding: '16px',
        margin: '16px 0',
    },
    legends: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    legendsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    legendColor: {
        width: 20,
        height: 20,
        display: 'inline-block',
    },
    legendText: {
        fontSize: '0.9em',
        padding: '0 8px 0 4px',
    },
    tooltip: {
        opacity: 0.8,
        padding: 12,
        width: 136,
    },
    tooltipDatetime: {
        color: theme.palette.grey['600'],
        marginBottom: 16,
    },
    tooltipValue: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

export enum Type {
    plays,
    users,
    favCount,
}

export enum Labels {
    PlaysOver30s = 'PlaysOver30s',
    PlaysUnder30s = 'PlaysUnder30s',
    Users = 'Users',
    Artists = 'Artists',
    Albums = 'Albums',
    Tracks = 'Tracks',
    Playlists = 'Playlists',
}

const titles: { [type: string]: 'summary__graph__plays' | 'summary__graph__users' | 'summary__graph__favCount' } = {
    [Type.plays]: 'summary__graph__plays',
    [Type.users]: 'summary__graph__users',
    [Type.favCount]: 'summary__graph__favCount',
};

const colorLegendMap: {
    [label: string]: {
        color: string,
        legend: 'summary__graph__legend__playsOver30s'
        | 'summary__graph__legend__playsUnder30s'
        | 'summary__graph__legend__users'
        | 'summary__graph__legend__artists'
        | 'summary__graph__legend__albums'
        | 'summary__graph__legend__tracks'
        | 'summary__graph__legend__playlists'
    }
} = {
        [Labels.PlaysOver30s]: { color: '#2a8d30', legend: 'summary__graph__legend__playsOver30s' },
        [Labels.PlaysUnder30s]: { color: '#4caf50', legend: 'summary__graph__legend__playsUnder30s' },
        [Labels.Users]: { color: '#2196F3', legend: 'summary__graph__legend__users' },
        [Labels.Artists]: { color: '#F23558', legend: 'summary__graph__legend__artists' },
        [Labels.Albums]: { color: '#04BD69', legend: 'summary__graph__legend__albums' },
        [Labels.Tracks]: { color: '#049DD9', legend: 'summary__graph__legend__tracks' },
        [Labels.Playlists]: { color: '#F28907', legend: 'summary__graph__legend__playlists' },
    };

interface Props {
    type: Type;
    data: Array<{
        label: string,
        values: [{
            x: string, y: number
        }]
    }>;
    unit: string;
    start: moment.Moment;
    end: moment.Moment;
    width: number;
    translator: Translator;
}

const Graph: React.SFC<Props & WithStyles<ClassNames>> = (props) => {
    const { start, end, unit, type, translator, classes } = props;
    language = translator.getLanguage();

    const width = props.width - 32;
    const range = {
        start: start.toDate(),
        end: unit === 'day' ? end.toDate() : moment(end).add(1, 'days').subtract(1, 'hours').toDate(),
    };
    const scale = d3.time.scale();
    const xScale = scale.domain([range.start, range.end]).range([0, width - 100]);
    const xAxis = {
        scale: xScale,
        tickFormat: (date: Date) => {
            const fill = (n: number) => (n < 10 ? `0${n}` : `${n}`);
            if (date.getHours() === 0) {
                const m = date.getMonth() + 1;
                const d = date.getDate();
                return `${m}/${d}`;
            }
            const h = date.getHours();
            return `${fill(h)}:00`;
        },
    };

    const margin = { top: 20, bottom: 40, left: 60, right: 0 };

    const data = props.data.map((d) => {
        const values = d.values.map((e) => {
            return ({ x: new Date(e.x), y: + e.y || 0 });
        });
        return { label: d.label, values: values };
    });

    const rangeColors: string[] = [];
    const legends = props.data.map((d, i) => {
        const cl = colorLegendMap[d.label];
        if (cl) {
            rangeColors.push(cl.color);
        }
        return cl ? (
            <div key={i} className={classes.legendsContainer}>
                <div className={classes.legendColor} style={{ backgroundColor: cl.color }} />
                <span className={classes.legendText}>{translator.translate(cl.legend)}</span>
            </div>
        ) : null;
    });

    const tooltip = (yValue: number, yValueCumulative: number, xValue: Date, label: string) => {
        const datetime = moment(xValue).locale(language).format(unit === 'hour' ? 'M/D dddd HH:mm' : 'M/D dddd');
        const cl = colorLegendMap[label];
        return (
            <Paper className={classes.tooltip}>
                <>
                    <Typography className={classes.tooltipDatetime}>{datetime}</Typography>
                    <div className={classes.tooltipValue}>
                        <Typography>{cl && translator.translate(cl.legend)}</Typography>
                        <Typography>{str(yValue)}</Typography>
                    </div>
                    {
                        yValue !== yValueCumulative ? (
                            <div className={classes.tooltipValue}>
                                <Typography>Total</Typography>
                                <Typography>{str(yValueCumulative)}</Typography>
                            </div>
                        ) : null
                    }
                </>
            </Paper>
        );
    };
    return (
        <Paper className={classes.container}>
            <Typography variant="title" color="primary">
                {translator.translate(titles[type])}
            </Typography>
            <AreaChart
                data={data}
                width={width}
                height={320}
                xScale={xScale}
                xAxis={xAxis}
                colorScale={d3.scale.ordinal().range(rangeColors) as any}
                margin={margin}
                tooltipHtml={tooltip}
                tooltipContained={true} />
            <div className={classes.legends}>
                {legends}
            </div>
        </Paper>
    );
};

const str = (v: number) => {
    if (v > 1000000) {
        return `${(v / 1000000).toFixed(2).toLocaleString()}M`;
    } else {
        return v.toLocaleString();
    }
};

export default decorate<Props>(Graph);
