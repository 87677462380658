import { FAILED, SEARCH_SUCCEEDED, SearchAction } from '../actions/search';
import { SearchState } from '../stores/searchState';

export default (state: SearchState, action: SearchAction): SearchState => {
    switch (action.type) {
        case SEARCH_SUCCEEDED:
            return { ...state, result: action.result };
        case FAILED:
            return { ...state, errorMessage: action.errorMessage };
        default:
            const _: never = action;
            return state || {};
    }
};