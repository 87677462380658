import { Grid, Paper } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { Translator } from '../../utils/localization';
import Rank, { Type } from './Rank';

enum uriType {
    label = 'label',
    artist = 'artist',
    album = 'album',
    track = 'track',
}

const uriTypeMap = {
    [uriType.label]: [Type.artists, Type.albums, Type.tracks],
    [uriType.artist]: [Type.albums, Type.tracks, Type.playlists],
    [uriType.album]: [Type.tracks],
    [uriType.track]: [Type.playlists],
};

interface Props {
    uriType: 'label' | 'artist' | 'album' | 'track';
    data: dashboard.Ranking;
    dataSet: dashboard.DataSet;
    start: moment.Moment;
    end: moment.Moment;
    hasNextRankings: { [key: string]: boolean };
    translator: Translator;
    loadMoreRanking: (
        start: string,
        end: string,
        offset: number,
        limit: number,
        type: 'artists' | 'albums' | 'tracks' | 'playlists',
        target?: { uriType: string, id: string } | null) => void;
}

export const Ranks: React.SFC<Props> = (props) => {
    const items = uriTypeMap[props.uriType];
    const gridSizeInSm: 1 | 3 = 12 / items.length as 1 | 3;
    return (
        <Grid container spacing={16}>
            {
                items.map((type, i) => (
                    <Grid item xs={12} sm={gridSizeInSm} key={i}>
                        <Paper>
                            <Rank key={i} type={type} {...props} />
                        </Paper>
                    </Grid>
                ))
            }
        </Grid>
    );
};