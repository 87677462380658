import { connect, Dispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as authActions from '../actions/auth';
import * as notificationsAction from '../actions/notifications';
import AuthenticatedRoute from '../components/AuthenticatedRoute';
import { RootState } from '../stores/RootState';

const mapStateToProps = ({ login }: RootState) => {
    return {
        isLoggedIn: login.isLoggedIn,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<authActions.AuthAction>) => {
    return {
        refreshToken: () => { dispatch(authActions.refreshToken()); },
        loadNotifications: () => { dispatch(notificationsAction.loadList()); },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoute));