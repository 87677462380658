import { connect, Dispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as loginActions from '../actions/login';
import * as notificationsAction from '../actions/notifications';
import * as summaryActions from '../actions/summary';
import App from '../components/App';
import { RootState } from '../stores/rootState';

const mapStateToProps = ({
    common,
    login,
    summary,
    delivery,
    notifications,
    window,
}: RootState) => {
    let name;
    let encryptedId;
    let updatedAt;

    const { artist, album, track } = common;
    if (artist) {
        name = artist.name;
        encryptedId = artist.encryptedId;
        updatedAt = artist.updatedAt;
    } else if (album) {
        name = album.name;
        encryptedId = album.encryptedId;
        updatedAt = album.updatedAt;
    } else if (track) {
        name = track.name;
        encryptedId = track.encryptedId;
        updatedAt = track.updatedAt;
    }

    return {
        company: login.company,
        isLoggedIn: login.isLoggedIn,
        loading: summary.loading,
        name,
        encryptedId,
        updatedAt,
        notifications: notifications.result,
        notificationUnread: notifications.unread,
        translator: window.translator,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<RootState>) => {
    return {
        logout: () => { dispatch(loginActions.logout()); },
        recoverCancelled: () => { dispatch(summaryActions.recoverCancelled()); },
        readNotification: () => { dispatch(notificationsAction.read()); },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
