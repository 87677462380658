import { connect, Dispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as commonActions from '../../../actions/common';
import * as actions from '../../../actions/delivery';
import Artist from '../../../components/delivery/editor/Artist';
import { RootState } from '../../../stores/rootState';

const mapStateToProps = ({ delivery, window }: RootState) => {
    let updateMessage;
    const translator = window.translator;
    if (delivery.updateType === actions.UpdateType.update) {
        updateMessage = translator.translate('delivery__updateMessage1');
    } else if (delivery.updateType === actions.UpdateType.delete) {
        updateMessage = translator.translate('delivery__updateMessage2');
    }
    return {
        data: delivery.result,
        loading: delivery.loading,
        updateType: delivery.updateType,
        updateMessage: updateMessage,
        errorMessage: delivery.errorMessage,
        translator: translator,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<actions.DeliveryAction | commonActions.CommonAction>) => {
    return {
        loadOne: (id: string, from?: 'admintool', distributorId?: string) => { dispatch(actions.loadOne(id, from, distributorId)); },
        clear: () => { dispatch(actions.clear()); },
        clearImage: () => { dispatch(actions.clearImage()); },
        update: (id: string, imageBase64: string | null, linerNotes: string, distributorId: string) => {
            dispatch(actions.update(id, imageBase64, linerNotes, distributorId));
        },
        delete: (id: string, distributorId: string) => { dispatch(actions.deleteImage(id, distributorId)); },
        getArtist: (id: string) => { dispatch(commonActions.getCommonInfo('artist', id)); },
        clearArtist: () => { dispatch(commonActions.clearCommonInfo()); },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Artist));
