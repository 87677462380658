import { GridList, GridListTile, GridListTileBar, Grow, IconButton, LinearProgress, Theme, WithStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { EMPTY_IMG } from '../../consts';
import { Translator } from '../../utils/localization';
import SearchBox, { Type as SearchType } from '../SearchBox';

type ClassNames = 'root'
    | 'topArtistsContainer'
    | 'searchContainer'
    | 'title'
    | 'gridList'
    | 'gridListTile'
    | 'img'
    | 'iconButton'
    | 'searchBoxContainer';

const decorate = withStyles<ClassNames>((theme: Theme) => ({
    root: {
        padding: 16,
        backgroundColor: theme.palette.grey[100],
        height: '100%',
        boxSizing: 'border-box',
        overflow: 'hidden',
    },
    topArtistsContainer: {
        padding: 16,
    },
    searchContainer: {
        marginTop: 16,
        paddingLeft: 12,
    },
    title: {
        marginBottom: 8,
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },
    gridListTile: {
        backgroundImage: `url(${EMPTY_IMG})`,
        backgroundSize: 'contain',
        margin: '0 2px',
    },
    img: {
        width: 200,
    },
    iconButton: {
        color: theme.palette.common.white,
    },
    searchBoxContainer: {
        flexGrow: 1,
        paddingRight: 16,
    },
}));

interface Props {
    results: dashboard.RelatedArtist[];
    searchResult: dashboard.SearchSet;
    searchErrorMessage: string | undefined;
    translator: Translator;
    loadTopArtists: () => void;
    clearArtists: () => void;
    search: (query: string, targets?: ['artists' | 'albums' | 'tracks'], limit?: number) => void;
    loadHistories: (targets?: ['artists' | 'albums' | 'tracks']) => void;
    saveHistory: (history: dashboard.SearchArtist | dashboard.SearchAlbum | dashboard.SearchTrack) => void;
}

type MergedProps = Props & WithStyles<ClassNames> & RouteComponentProps<{}>;

class Top extends React.Component<MergedProps, {}> {

    constructor(props: MergedProps) {
        super(props);
    }

    public componentDidMount() {
        this.props.loadTopArtists();
    }

    public componentWillUnmount() {
        this.props.clearArtists();
    }

    public render() {
        const { results, translator, classes } = this.props;

        return (
            <div className={classes.root}>
                <Paper className={classes.topArtistsContainer}>
                    <Typography variant="title" color="primary" className={classes.title}>
                        {translator.translate('analysis__topArtists__title')}
                    </Typography>
                    {results ?
                        <GridList className={classes.gridList} cellHeight={200}>
                            {
                                results.map((result) => (
                                    <GridListTile
                                        key={result.id}
                                        className={classes.gridListTile}
                                        style={{ width: 200, height: 200, padding: 0 }}>
                                        <Grow in={true} timeout={500}>
                                            <img src={result.imgUrl} className={classes.img} />
                                        </Grow>
                                        <GridListTileBar
                                            title={result.name}
                                            actionIcon={
                                                <Link to={`/artist/${result.encryptedId}/analysis`}>
                                                    <IconButton className={classes.iconButton}>
                                                        <InfoIcon />
                                                    </IconButton>
                                                </Link>} />
                                    </GridListTile>
                                ))
                            }
                        </GridList> : <LinearProgress />
                    }
                </Paper>
                <Paper className={classes.searchContainer}>
                    <SearchBox
                        search={this.props.search}
                        loadHistories={this.props.loadHistories}
                        saveHistory={this.props.saveHistory}
                        type={SearchType.analysis}
                        result={this.props.searchResult}
                        errorMessage={this.props.searchErrorMessage}
                        history={this.props.history}
                        location={this.props.location}
                        classNamesContainer={classes.searchBoxContainer} />
                </Paper>
            </div>
        );
    }

}

export default decorate<Props>(Top);