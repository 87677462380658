import { combineReducers } from 'redux';
import analysis from './analysis';
import common from './common';
import delivery from './delivery';
import deliveryViewer from './deliveryViewer';
import login from './login';
import notifications from './notifications';
import search from './search';
import summary from './summary';
import window from './window';

const reducers = combineReducers({
    delivery,
    deliveryViewer,
    login,
    search,
    summary,
    window,
    notifications,
    analysis,
    common,
});

export default reducers;
