import 'isomorphic-fetch';
import _ from 'lodash';
import * as queryString from 'query-string';
import { Dispatch } from 'redux';
import { KEY_SEARCH_HISTORIES, KEY_TOKEN, load as loadFromLocalStorage, save as saveToLocalStorage } from './../stores/localStorage';
import { SearchState } from './../stores/searchState';

export const SEARCH_SUCCEEDED = 'SEARCH_SUCCEEDED';
const SAVE_HISTORY_SUCCEEDED = 'SAVE_HISTORY_SUCCEEDED';
export const FAILED = 'SEARCH_FAILED';
const SAVED_HISTORIES_COUNT = 3;

export interface SearchSucceeded {
    type: typeof SEARCH_SUCCEEDED;
    result: dashboard.SearchSet;
}

export interface Failed {
    type: typeof FAILED;
    errorMessage: string;
}

export type SearchAction = SearchSucceeded | Failed;

export const search = (query: string, targets?: ['artists' | 'albums' | 'tracks'], limit?: number) => {
    return async (dispatch: Dispatch<SearchState>) => {
        const queries = queryString.stringify(
            { limit: limit ? limit : 10, targets: targets }
        );
        const response = await fetch(`/api/search/${query}?${queries}`, {
            headers: {
                'X-Access-Token': loadFromLocalStorage(KEY_TOKEN) || '',
            },
        });
        if (response) {
            try {
                const json = await response.json();
                if (response.ok) {
                    dispatch(succeeded(json));
                } else {
                    dispatch(failed(`${response.status}:${json.msg}`));
                }
            } catch (e) {
                dispatch(failed(`${response.status}:${e}`));
            }
        }
    };
};

export const loadHistories = (targets?: ['artists' | 'albums' | 'tracks']) => {
    const savedItemString = loadFromLocalStorage(KEY_SEARCH_HISTORIES);
    let savedSearchSet: dashboard.SearchSet = { artists: [], albums: [], tracks: [] };
    if (savedItemString) {
        savedSearchSet = JSON.parse(savedItemString);
    }

    return targets ? succeeded(_.pick(savedSearchSet, targets)) : succeeded(savedSearchSet);
};

export const saveHistory = (willSaveItem: dashboard.SearchArtist | dashboard.SearchAlbum | dashboard.SearchTrack) => {
    willSaveItem.history = true;
    const savedItemString = loadFromLocalStorage(KEY_SEARCH_HISTORIES);
    const savedSearchSet: dashboard.SearchSet = savedItemString ? JSON.parse(savedItemString) : { artists: [], albums: [], tracks: [] };

    const savedSearchItems: dashboard.SuperSearch[] = savedSearchSet[willSaveItem.type] || [];
    savedSearchItems.unshift(willSaveItem);

    const willSaveItems = _.uniqWith(savedSearchItems, _.isEqual);
    if (willSaveItems.length > SAVED_HISTORIES_COUNT) {
        willSaveItems.pop();
    }
    savedSearchSet[willSaveItem.type] = willSaveItems;

    saveToLocalStorage(null, null, null, undefined, savedSearchSet);

    return {
        type: SAVE_HISTORY_SUCCEEDED,
    };
};

const succeeded = (result: dashboard.SearchSet) => {
    return {
        type: SEARCH_SUCCEEDED,
        result,
    };
};

const failed = (errorMessage: string) => {
    return {
        type: FAILED,
        errorMessage,
    };
};
