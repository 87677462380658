// tslint:disable:object-literal-key-quotes quotemark trailing-comma max-line-length
export default [
    {
        "id": 1,
        "text": "Thank you for using AWA Dashboard. This was renewed on December 21, 2017. Please contact us from the inquiry form (MENU > CONTACT US) if there is a requirement, trouble etc. \n\nThe changes are as follows. \n - Since URLs other than the top page have been changed, please register again if you are bookmarked \n - Realtime page has been deleted \n\n This will further enhance the feature of the dashboard I will be pleased if you can give us your opinion even if it is trivial."
    },
    {
        "id": 2,
        "text": "[New Feature]\nPlayback ranking can now be viewed after 100th place. By pressing the LOAD MORE button at the bottom of the ranking you can display as much as you want."
    },
    {
        "id": 3,
        "text": "[New Feature]\nClicking the playlist ranking line will now transition to the web page of the corresponding playlist. Playlist playback ranking is displayed on the page for each artist and on the page for each track. Let's check what kind of playlists are listening."
    },
    {
        "id": 4,
        "text": "[New Feature]\nNow we are transitioning from the artist, album, track page to a trial listening web page. You can display it by pressing the button on the right side of the title."
    },
    {
        "id": 5,
        "text": "[New Feature]\nWe supported the drag and drop of images on the delivery form of artist image. The artist image can be delivered by selecting the corresponding artist from Menu > Artist List"
    },
    {
        "id": 6,
        "text": "[Improvement]\nImproved notation of numerical detail displayed when mouse is turned on graph. In addition to the corresponding numerical values, the date and time, the day of the week, and Total numerical values are displayed if there are multiple items."
    },
    {
        "id": 7,
        "text": "[Specification Change]\nIn order to improve the site performance, we changed part of the summary page URL. In addition, the file name of Excel will be changed. If you are bookmarked etc, sorry to trouble you but please bookmark again so thank you."
    },
    {
        "id": 8,
        "text": "[Improvement]\nThe number of various rankings of data downloaded as Excel has increased from the conventional 50 to a maximum of 500. Also fixed a bug that can not be downloaded with browsers other than Google Chrome."
    },
    {
        "id": 9,
        "text": "[Improvement]\nAdded UPC to the ranking items downloaded as Excel."
    },
    {
        "id": 10,
        "text": "[Specification Change]\nChanged trimming of artist photos so that there is no blank space. If there is no square material, please provide a background on the uploaded image itself."
    },
    {
        "id": 11,
        "text": "[Improvement]\nAdded UPC to album information in DELIVERIES. Please use it to distinguish same name packages."
    }
];
