import 'isomorphic-fetch';
import { Dispatch } from 'redux';
import { store } from '../stores';
import { NotificationsState } from '../stores/notificationsState';
import { RootState } from '../stores/rootState';
import {
    KEY_NOTIFICATION_READ_ID,
    KEY_NOTIFICATIONS,
    load as loadFromLocalStorage,
    save as saveToLocalStorage
} from './../stores/localStorage';
export const SUCCEEDED = 'NOTIFICATIONS_SUCCEEDED';
export const FAILED = 'NOTIFICATIONS_FAILED';

export interface Succeeded {
    type: typeof SUCCEEDED;
    result: dashboard.Notification[];
    unread: number;
}

export interface Failed {
    type: typeof FAILED;
    errorMessage: string;
}

export type NotificationsAction = Succeeded | Failed;

export const loadList = () => {
    return async (dispatch: Dispatch<NotificationsState>) => {
        const states = store.getState() as RootState;
        const notifications: dashboard.Notification[] = states.window.translator.loadNotifications();
        const resultFromApi = notifications.reverse() || [];
        const readId = Number(loadFromLocalStorage(KEY_NOTIFICATION_READ_ID) || 0);
        const unread = resultFromApi[0].id - readId;
        saveToLocalStorage(null, null, resultFromApi);
        dispatch(succeeded(resultFromApi, unread));
    };
};

export const read = () => {
    return async (dispatch: Dispatch<NotificationsState>) => {
        const notificationsFromLocalStorage = JSON.parse(loadFromLocalStorage(KEY_NOTIFICATIONS) || '[]');
        const readId = notificationsFromLocalStorage[0] && notificationsFromLocalStorage[0].id;
        saveToLocalStorage(null, null, null, readId);
        dispatch(succeeded(notificationsFromLocalStorage, 0));
    };
};

const succeeded = (result: dashboard.Notification[], unread: number) => {
    return {
        type: SUCCEEDED,
        result,
        unread,
    };
};

const failed = (errorMessage: string) => {
    return {
        type: FAILED,
        errorMessage,
    };
};
