import { connect, Dispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../actions/deliveryViewer';
import * as commonActions from '../../../actions/common';
import Search from '../../../components/delivery/viewer/Search';
import { RootState } from '../../../stores/rootState';

const mapStateToProps = ({ deliveryViewer }: RootState) => {
  return {
    keyword: deliveryViewer.search.keyword,
    target: deliveryViewer.search.target,
    result: deliveryViewer.search.result,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<actions.DeliveryViewerAction>) => {
  return {
    init: () => {
      dispatch(commonActions.clearCommonInfo());
    },
    search: (keyword: string, target: 'artists' | 'albums' | 'tracks') => {
      dispatch(actions.search(keyword, target))
    },
    searchMore: (target: 'artists' | 'albums' | 'tracks') => {
      dispatch(actions.searchMore(target))
    },
    setTarget: (target: 'artists' | 'albums' | 'tracks') => {
      dispatch(actions.setTarget(target))
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
