import 'isomorphic-fetch';
import { Dispatch } from 'react-redux';
import { AnalysisState } from '../stores/analysisState';
import {
    KEY_TOKEN,
    load as loadFromLocalStorage,
} from './../stores/localStorage';
export const ANALYSIS_LOAD_TOP_SUCCEEDED = 'ANALYSIS_LOAD_TOP_SUCCEEDED';
export const ANALYSIS_LOAD_RELATED_VIA_PLAYLIST_SUCCEEDED = 'ANALYSIS_LOAD_RELATED_VIA_PLAYLIST_SUCCEEDED';
export const ANALYSIS_LOAD_RELATED_VIA_PLAYBACK_SUCCEEDED = 'ANALYSIS_LOAD_RELATED_VIA_PLAYBACK_SUCCEEDED';
export const ANALYSIS_FAILED = 'ANALYSIS_FAILED';
export const ANALYSIS_CLEAR = 'ANALYSIS_CLEAR';

export enum Type {
    playlist = 'playlist',
    playback = 'playback',
}

interface LoadTopArtistsSucceeded {
    type: typeof ANALYSIS_LOAD_TOP_SUCCEEDED;
    results: dashboard.RelatedArtist[];
}

interface LoadRelatedViaPlaylistSucceeded {
    type: typeof ANALYSIS_LOAD_RELATED_VIA_PLAYLIST_SUCCEEDED;
    results: dashboard.RelatedArtist[];
}

interface LoadRelatedViaPlaybackSucceeded {
    type: typeof ANALYSIS_LOAD_RELATED_VIA_PLAYBACK_SUCCEEDED;
    results: dashboard.RelatedArtist[];
}

interface Clear {
    type: typeof ANALYSIS_CLEAR;
}

interface Failed {
    type: typeof ANALYSIS_FAILED;
    errorMessage: string;
}

export type AnalysisAction = LoadTopArtistsSucceeded
    | LoadRelatedViaPlaylistSucceeded
    | LoadRelatedViaPlaybackSucceeded
    | Clear
    | Failed;

export const loadTopArtists = () => {
    return async (dispatch: Dispatch<AnalysisState>) => {
        const response = await fetch(`/api/analysis`, {
            headers: {
                'X-Access-Token': loadFromLocalStorage(KEY_TOKEN) || '',
            },
        }).catch((err) => {
            dispatch(failed(err));
            return;
        });
        if (response) {
            if (response.ok) {
                const json: dashboard.RelatedArtist[] = await response.json();
                dispatch(loadTopArtistsSucceeded(json));
            } else {
                dispatch(failed(`${response.status}:${response.statusText}`));
            }
        }
    };
};

export const loadRelatedArtists = (id: string, type: Type) => {
    return async (dispatch: Dispatch<AnalysisState>) => {
        const response = await fetch(`/api/analysis/${id}/related/${type}`, {
            headers: {
                'X-Access-Token': loadFromLocalStorage(KEY_TOKEN) || '',
            },
        }).catch((err) => {
            dispatch(failed(err));
            return;
        });
        if (response) {
            if (response.ok) {
                const json: dashboard.RelatedArtist[] = await response.json();
                dispatch(type === Type.playlist ? loadRelatedViaPlaylistSucceeded(json) : loadRelatedViaPlaybackSucceeded(json));
            } else {
                switch (response.status) {
                    case 404:
                        dispatch(failed('No Results Found.'));
                        break;
                    default:
                        dispatch(failed(`${response.status}:${response.statusText}`));
                }
            }
        }
    };
};

const loadTopArtistsSucceeded = (results: dashboard.RelatedArtist[]): LoadTopArtistsSucceeded => {
    return {
        type: ANALYSIS_LOAD_TOP_SUCCEEDED,
        results,
    };
};

const loadRelatedViaPlaylistSucceeded = (results: dashboard.RelatedArtist[]): LoadRelatedViaPlaylistSucceeded => {
    return {
        type: ANALYSIS_LOAD_RELATED_VIA_PLAYLIST_SUCCEEDED,
        results,
    };
};

const loadRelatedViaPlaybackSucceeded = (results: dashboard.RelatedArtist[]): LoadRelatedViaPlaybackSucceeded => {
    return {
        type: ANALYSIS_LOAD_RELATED_VIA_PLAYBACK_SUCCEEDED,
        results,
    };
};

const failed = (errorMessage: string): Failed => {
    return {
        type: ANALYSIS_FAILED,
        errorMessage,
    };
};

export const clearArtists = (): Clear => {
    return {
        type: ANALYSIS_CLEAR,
    };
};
