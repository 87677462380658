import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Toolbar,
  Button,
} from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';

import { decorate, StyleProps } from './style'
import { copyToClipboard } from '../../../../../utils/clipboard';
import { compactMap } from '../../../../../utils/conpactMap';
import _ from 'lodash';
import BodyRow from '../BodyRow';

type ArtistProps = {
    type: 'searchArtist'
    data: dashboard.SearchArtist[]
}

type AlbumProps = {
    type: 'searchAlbum' | 'artistAlbum'
    data: dashboard.SearchAlbum[]
}

type TrackProps = {
    type: 'searchTrack' | 'artistTrack' | 'albumTrack'
    data: dashboard.SearchTrack[]
}

type DataTypeProps = ArtistProps | AlbumProps | TrackProps

type Props = DataTypeProps & {
    hidden?: boolean
    onNext?: (
        type: DataTypeProps['type'],
        length: number,
        lastId: string
    ) => void
}

type MergedProps = Props & StyleProps;

class ListWrap extends React.Component<MergedProps> {
    shouldComponentUpdate(nextProps: Props) {
        if (this.props.hidden !== nextProps.hidden) {
            return true;
        }

        return this.props.data !== nextProps.data;
    }

    render() {
        const { classes: s, hidden } = this.props;
        const classNames = [];

        if (hidden) {
            classNames.push(s.hidden);
        }

        return (
            <div className={classNames.join(' ')}>
                <List {...this.props} />
            </div>
        )
    }
}

interface State {
    selected: number[]
}

class List extends React.Component<MergedProps, State> {
    state: State = {
        selected: [],
    }

    componentDidUpdate(prevProps: Props) {
        const { data: prevData } = prevProps
        const { data } = this.props

        const isBrandNewData = (prevData as dashboard.SuperSearch[]).some((item, i) => {
            return !data[i] || item.encryptedId !== data[i].encryptedId
        })

        if (isBrandNewData) {
            this.uncheckAll()
        }
    }

    render() {
        const { classes: s, hidden } = this.props;
        const { selected } = this.state;
        const selectedLength = selected.length
        return (
            <>
                <Toolbar className={s.toolBar}>
                    <Button
                        variant="flat"
                        size="small"
                        disabled={selectedLength === 0}
                        className={s.button}
                        onClick={() => this.copyUrl()}
                    >
                        {
                            selectedLength ? `COPY URL (${selectedLength})` : 'COPY URL'
                        }
                    </Button>
                    <Button
                        variant="flat"
                        size="small"
                        disabled={selectedLength === 0}
                        className={s.button}
                        onClick={() => this.copyNameAndUrl()}
                    >
                        {
                            selectedLength ? `COPY NAME & URL (${selectedLength})` : 'COPY NAME & URL'
                        }
                    </Button>
                </Toolbar>
                <InfiniteScroll
                    dataLength={this.props.data.length}
                    next={this.handleNext}
                    hasMore={!hidden}
                    loader={null}
                    style={{ overflow: 'visible' }}
                >
                    <Table className={s.table}>
                        <TableHead>
                            { this.buildHeadRow() }
                        </TableHead>
                        <TableBody>
                            { this.buildBodyRows() }
                        </TableBody>
                    </Table>
                </InfiniteScroll>
            </>
        )
    }

    private buildHeadRow() {
        const { type, classes: s, data } = this.props;
        const { selected } = this.state;
        const selectedLength = selected.length;
        const dataLength = data.length;
        let titles: string[] = []

        switch(type) {
            case 'searchArtist':
                titles = [ 'IMAGE', 'ARTIST', 'STATUS', 'URL' ];
                break
            case 'searchAlbum':
                titles = [ 'IMAGE', 'ALBUM', 'STATUS', 'ARTIST', 'UPC', 'URL' ];
                break
            case 'artistAlbum':
                titles = [ 'IMAGE', 'ALBUM', 'STATUS', 'UPC', 'URL' ];
                break
            case 'searchTrack':
                titles = [ 'IMAGE', 'TRACK', 'STATUS', 'ARTIST', 'ALBUM', 'URL' ];
                break
            case 'artistTrack':
                titles = [ 'IMAGE', 'TRACK', 'STATUS', 'ALBUM', 'URL' ];
                break
            case 'albumTrack':
                titles = [ 'NO.', 'TRACK', 'STATUS', 'URL' ];
                break
            default:
                const t: never = type;
        }

        return (
            <TableRow>
                <TableCell className={[s.checkColumn, s.th].join(' ')} padding='none'>
                    <Checkbox
                        indeterminate={selectedLength > 0 && selectedLength < dataLength}
                        checked={selectedLength > 0  && selectedLength === dataLength}
                        onClick={this.handleSelectAll}
                    />
                </TableCell>
                {
                    titles.map((title, i) => {
                        const classNames = [s.th];
                        if (i === 0) {
                            classNames.push(s.imgColumn)
                        }

                        if (i >= 2 && i !== titles.length - 1) {
                            classNames.push(s.subColumn)
                        }

                        return (
                            <TableCell
                                key={i}
                                className={classNames.join(' ')}
                                padding={i === 0 ? 'none' : 'dense'}
                            >
                                {title}
                            </TableCell>
                        )
                    })
                }
            </TableRow>
        )
    }

    private buildBodyRows() {
        switch(this.props.type) {
            case 'searchArtist':
                {
                    const type = this.props.type;
                    return this.props.data.map((item, i) => this.buildArtistRow(i, type, item));
                }
            case 'searchAlbum':
            case 'artistAlbum':
                {
                    const type = this.props.type;
                    return this.props.data.map((item, i) => this.buildAlbumRow(i, type, item));
                }
            case 'searchTrack':
            case 'artistTrack':
            case 'albumTrack':
                {
                    const type = this.props.type;
                    return this.props.data.map((item, i) => this.buildTrackRow(i, type, item));
                }
        }
    }

    private buildArtistRow(index: number, type: ArtistProps['type'], item: ArtistProps['data'][0]) {
        const selected = this.state.selected.indexOf(index) > -1;

        return (
            <BodyRow
                key={index}
                index={index}
                type={type}
                onRowClick={(index: number) => this.handleRowClick(index)}
                item={item}
                selected={selected}
            />
        );
    }

    private buildAlbumRow(index: number, type: AlbumProps['type'], item: AlbumProps['data'][0]) {
        const selected = this.state.selected.indexOf(index) > -1;

        return (
            <BodyRow
                key={index}
                index={index}
                type={type}
                onRowClick={(index: number) => this.handleRowClick(index)}
                item={item}
                selected={selected}
            />
        );
    }

    private buildTrackRow(index: number, type: TrackProps['type'], item: TrackProps['data'][0]) {
        const selected = this.state.selected.indexOf(index) > -1;

        return (
            <BodyRow
                key={index}
                index={index}
                type={type}
                onRowClick={(index: number) => this.handleRowClick(index)}
                item={item}
                selected={selected}
            />
        );
    }

    private uncheckAll() {
        this.setState({
            selected: []
        })
    }

    private handleSelectAll = () => {
        const selectedLength = this.state.selected.length
        const dataLength = this.props.data.length

        if (selectedLength === dataLength) {
            this.setState({
                selected: []
            })
        } else {
            this.setState({
                selected: new Array(dataLength).fill('').map((s, i) => i)
            })
        }
    }

    private handleRowClick(index: number) {
        const { selected } = this.state;
        const itemIndex = selected.indexOf(index);

        if (itemIndex > -1) {
            const newSelected = selected.slice();
            newSelected.splice(itemIndex, 1);
            this.setState({ selected: newSelected });
        } else {
            this.setState({
                selected: [
                    ...selected,
                    index,
                ]
            })
        }
    }

    private handleNext = () => {
        this.emitNext()
    }

    private emitNext = () => {
        const { type, data, hidden, onNext } = this.props;
        const { encryptedId } = data[data.length - 1] || {};

        if (onNext) {
            onNext(type, data.length, encryptedId)
        }
    }

    private getSortedSelections() {
        const { selected } = this.state
        return selected.slice().sort((a, b) => a - b)
    }

    private async copyUrl() {
        const { data } = this.props
        const urls = compactMap(this.getSortedSelections(), index => {
            const { shareUrl } = data[index] || {}
            if (shareUrl) {
                return shareUrl
            }
        })

        if (urls.length) {
            await copyToClipboard(urls.join('\n'))
        }
    }

    private async copyNameAndUrl() {
        const { data } = this.props
        const lines = compactMap(this.getSortedSelections(), index => {
            const item = data[index];
            if (!item) {
                return;
            }

            const { name, shareUrl } = item;

            if (name && shareUrl) {
                return [ name, shareUrl ].join('\t');
            }
        })

        if (lines.length) {
            await copyToClipboard(lines.join('\n'))
        }
    }
}

export default decorate<Props>(ListWrap)
