import {
    ALBUM_CLEAR,
    ALBUM_LOADED,
    ARTIST_ALBUMS_LOADED,
    ARTIST_CLEAR,
    ARTIST_LOADED,
    ARTIST_SEARCH_SUCCEEDED,
    ARTIST_SEARCH_TARGET_CHANGED,
    ARTIST_TRACKS_LOADED,
    DeliveryViewerAction,
    ERROR,
    SEARCH_CLEAR,
    SEARCH_SUCCEEDED,
    SEARCH_TARGET_CHANGED,
    TRACK_CLEAR,
    TRACK_LOADED,
} from './../actions/deliveryViewer';
import { DeliveryViewerState } from './../stores/deliveryViewerState';

export default (state: DeliveryViewerState, action: DeliveryViewerAction): DeliveryViewerState => {
    switch (action.type) {
        case SEARCH_SUCCEEDED: {
            const { keyword: prevKeyword, result: prevResult } = state.search;
            // tslint:disable-next-line: no-shadowed-variable
            const { keyword, target, result, paging } = action.result;
            if (prevKeyword === keyword) {
                return {
                    ...state,
                    search: {
                        keyword,
                        target,
                        result: {
                            artists:
                                target !== 'artists'
                                    ? prevResult.artists
                                    : paging
                                        ? [...prevResult.artists, ...result.artists]
                                        : result.artists,
                            albums:
                                target !== 'albums'
                                    ? prevResult.albums
                                    : paging
                                        ? [...prevResult.albums, ...result.albums]
                                        :  result.albums,
                            tracks:
                                target !== 'tracks'
                                    ? prevResult.tracks
                                    : paging
                                        ? [...prevResult.tracks, ...result.tracks]
                                        :  result.tracks,
                        },
                    },
                };
            } else {
                return {
                    ...state,
                    search: {
                        ...action.result,
                    },
                };
            }
        }
        case SEARCH_TARGET_CHANGED:
            return {
                ...state,
                search: {
                    ...state.search,
                    target: action.result.target,
                },
            };
        case SEARCH_CLEAR:
            return {
                ...state,
                search: defaultState().search,
            };
        case ARTIST_LOADED:
            return {
                ...state,
                artist: {
                    ...state.artist,
                    summary: action.result,
                },
            };
        case ARTIST_SEARCH_SUCCEEDED: {
            const { keyword: prevKeyword, result: prevResult } = state.artist.search;
            // tslint:disable-next-line
            const { keyword, target, result, paging } = action.result;
            if (prevKeyword === keyword) {
                return {
                    ...state,
                    artist: {
                        ...state.artist,
                        search: {
                            keyword,
                            target,
                            result: {
                                albums:
                                    target !== 'albums'
                                        ? prevResult.albums
                                        : paging
                                            ? [...prevResult.albums, ...result.albums]
                                            :  result.albums,
                                tracks:
                                    target !== 'tracks'
                                        ? prevResult.tracks
                                        : paging
                                            ? [...prevResult.tracks, ...result.tracks]
                                            :  result.tracks,
                            },
                        },
                    },
                };
            } else {
                return {
                    ...state,
                    artist: {
                        ...state.artist,
                        search: {
                            ...action.result,
                        },
                    },
                };
            }
        }
        case ARTIST_SEARCH_TARGET_CHANGED:
            return {
                ...state,
                artist: {
                    ...state.artist,
                    search: {
                        ...state.artist.search,
                        target: action.result.target,
                    },
                },
            };
        case ARTIST_ALBUMS_LOADED:
            return {
                ...state,
                artist: {
                    ...state.artist,
                    list: {
                        albums: [
                            ...state.artist.list.albums,
                            ...action.result,
                        ],
                        tracks: state.artist.list.tracks,
                    },
                },
            };
        case ARTIST_TRACKS_LOADED:
            return {
                ...state,
                artist: {
                    ...state.artist,
                    list: {
                        albums: state.artist.list.albums,
                        tracks: [
                            ...state.artist.list.tracks,
                            ...action.result,
                        ],
                    },
                },
            };
        case ARTIST_CLEAR:
            return {
                ...state,
                artist: defaultState().artist,
            };
        case ALBUM_LOADED:
            return {
                ...state,
                album: action.result,
            };
        case ALBUM_CLEAR:
            return {
                ...state,
                album: defaultState().album,
            };
        case TRACK_LOADED:
            return {
                ...state,
                track: action.result,
            };
        case TRACK_CLEAR:
            return {
                ...state,
                track: defaultState().track,
            };
        case ERROR:
            return {
                ...state,
                errorMessage: action.result,
            };
        default:
            return state || defaultState();
    }
};

function defaultState(): DeliveryViewerState {
    return {
        errorMessage: '',
        search: {
            keyword: '',
            target: 'artists',
            result: {
                artists: [],
                albums: [],
                tracks: [],
            },
        },
        artist: {
            summary: null,
            search: {
                keyword: '',
                target: 'albums',
                result: {
                    albums: [],
                    tracks: [],
                },
            },
            list: {
                albums: [],
                tracks: [],
            },
        },
        album: {
            summary: null,
            list: [],
        },
        track: {
            summary: null,
        },
    };
}
