import { FormControl, Input, MenuItem, Select, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as H from 'history';
import _ from 'lodash';
import React from 'react';
import { Translator } from '../../utils/localization';

type ClassNames = 'formControl';

const decorate = withStyles<ClassNames>((theme: Theme) => ({
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
}));

interface Period {
    value: number;
    text: string;
    query: { [key: string]: string; };
}

interface Props {
    history: H.History;
    location: H.Location;
    translator: Translator;
}

interface State {
    period: Period;
}

type MergedProps = Props & WithStyles<ClassNames>;

class Selects extends React.Component<MergedProps, State> {

    private periods: Period[];

    constructor(props: MergedProps) {
        super(props);
        const { translator } = props;
        this.periods = [
            { value: 99, text: translator.translate('toolbar__period__default'), query: {} },
            { value: 0, text: translator.translate('toolbar__period__yesterday'), query: { days: '1' } },
            { value: 1, text: translator.translate('toolbar__period__day', 3), query: { days: '3' } },
            { value: 2, text: translator.translate('toolbar__period__week', 1), query: { days: '7' } },
            { value: 3, text: translator.translate('toolbar__period__week', 2), query: { days: '14' } },
            { value: 4, text: translator.translate('toolbar__period__month', 1), query: { month: '1' } },
            { value: 5, text: translator.translate('toolbar__period__month', 3), query: { month: '3' } },
            { value: 6, text: translator.translate('toolbar__period__month', 6), query: { month: '6' } },
            { value: 7, text: translator.translate('toolbar__period__year', 1), query: { month: '12' } },
        ];
        this.state = {
            period: this.periods[0],
        };
        this.onChange = this.onChange.bind(this);
    }

    public render() {
        const { classes } = this.props;
        const { period } = this.state;
        return (
            <FormControl className={classes.formControl}>
                <Select
                    value={period.value}
                    onChange={this.onChange}
                    input={<Input value={period.text} />}>
                    {
                        this.periods.map((p) => (
                            <MenuItem key={p.value} value={p.value}>{p.text}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        );
    }

    private onChange(e: React.ChangeEvent<HTMLSelectElement>) {
        const period = _.find(this.periods, { value: e.target.value });
        if (period) {
            this.setState({ period: period });
            const q = Object.keys(period.query).map((key) => {
                return `${key}=${period.query[key]}`;
            });
            this.props.history.push(`${this.props.location.pathname}?${q}`);
        }
    }
}

export default decorate<Props>(Selects);