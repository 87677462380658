import 'isomorphic-fetch';
import { Dispatch } from 'react-redux';
import { CommonState } from '../stores/commonState';
import {
    KEY_TOKEN,
    load as loadFromLocalStorage,
} from './../stores/localStorage';

export const COMMON_GET_ARTIST_SUCCEEDED = 'COMMON_GET_ARTIST_SUCCEEDED';
export const COMMON_GET_ALBUM_SUCCEEDED = 'COMMON_GET_ALBUM_SUCCEEDED';
export const COMMON_GET_TRACK_SUCCEEDED = 'COMMON_GET_TRACK_SUCCEEDED';
export const COMMON_CLEAR = 'COMMON_CLEAR';
export const COMMON_FAILED = 'COMMON_FAILED';

interface GetArtistSucceeded {
    type: typeof COMMON_GET_ARTIST_SUCCEEDED;
    payload: dashboard.Artist;
}

interface GetAlbumSucceeded {
    type: typeof COMMON_GET_ALBUM_SUCCEEDED;
    payload: dashboard.Album;
}

interface GetTrackSucceeded {
    type: typeof COMMON_GET_TRACK_SUCCEEDED;
    payload: dashboard.Track;
}

interface Clear {
    type: typeof COMMON_CLEAR;
}

interface Failed {
    type: typeof COMMON_FAILED;
    errorMessage: string;
}

export type CommonAction =
    | GetArtistSucceeded
    | GetAlbumSucceeded
    | GetTrackSucceeded
    | Clear
    | Failed;

export const getCommonInfo = (type: dashboard.CommonInfoType, id: string) => {
    return async (dispatch: Dispatch<CommonState>) => {
        const response = await fetch(`/api/${type}/${id}`, {
            headers: {
                'X-Access-Token': loadFromLocalStorage(KEY_TOKEN) || '',
            },
        });
        if (response.ok) {
            const json = await response.json();
            switch (type) {
                case 'artist':
                    dispatch(succeeded<dashboard.Artist>(COMMON_GET_ARTIST_SUCCEEDED, json));
                    break;
                case 'album':
                    dispatch(succeeded<dashboard.Album>(COMMON_GET_ALBUM_SUCCEEDED, json));
                    break;
                case 'track':
                    dispatch(succeeded<dashboard.Track>(COMMON_GET_TRACK_SUCCEEDED, json));
                    break;
                default:
                    break;
            }
        } else {
            dispatch(failed(`${response.status}:${response.statusText}`));
        }
    };
};

const succeeded = <T>(type: string, payload: T) => {
    return {
        type,
        payload,
    };
};

const failed = (errorMessage: string) => {
    return {
        type: COMMON_FAILED,
        errorMessage,
    };
};

export const clearCommonInfo = () => {
    return {
        type: COMMON_CLEAR,
    };
};

export const artistCommonInfo = (artist: dashboard.Artist) => {
    return succeeded(COMMON_GET_ARTIST_SUCCEEDED, artist);
};

export const albumCommonInfo = (artist: dashboard.Album) => {
    return succeeded(COMMON_GET_ALBUM_SUCCEEDED, artist);
};

export const trackCommonInfo = (artist: dashboard.Artist) => {
    return succeeded(COMMON_GET_TRACK_SUCCEEDED, artist);
};
