import { CircularProgress, IconButton, ListItemText, MenuItem, Paper, TextField, Theme, withStyles, WithStyles } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';
import * as H from 'history';
import _ from 'lodash';
import React from 'react';
import Autosuggest from 'react-autosuggest';
const IMG_WIDTH = 80;

type ClassNames = 'root'
    | 'icon'
    | 'container'
    | 'suggestionsContainerOpen'
    | 'suggestion'
    | 'suggestionsList'
    | 'sectionContainer'
    | 'sectionTitle'
    | 'textField'
    | 'listItemTextRoot'
    | 'listItemTextText';

const decorate = withStyles<ClassNames>((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        height: 48,
        position: 'relative',
    },
    icon: {
        marginRight: 8,
    },
    container: {
        flexGrow: 1,
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        left: 0,
        right: 0,
        maxHeight: '400px', // TODO: innerHeightにあわせる
        overflowY: 'auto',
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    sectionContainer: {
        borderTop: '1px dashed #ccc',
    },
    sectionTitle: {
        padding: '10px 0 0 10px',
        fontSize: '0.8em',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    textField: {
        width: '100%',
    },
    // For overrides
    listItemTextRoot: {
        width: `calc(100% - ${IMG_WIDTH / 2}px)`,
    },
    listItemTextText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));

export enum Type {
    summary,
    delivery,
    analysis,
}

interface Props {
    type: Type;
    result: dashboard.SearchSet;
    errorMessage?: string;
    history: H.History;
    location: H.Location;
    classNamesContainer?: string;
    search: (query: string, targets?: ['artists' | 'albums' | 'tracks'], limit?: number) => void;
    loadHistories: (targets?: ['artists' | 'albums' | 'tracks']) => void;
    saveHistory: (history: dashboard.SearchArtist | dashboard.SearchAlbum | dashboard.SearchTrack) => void;
}

interface State {
    inputedValue: string;
    loading: boolean;
}

type MergedProps = Props & WithStyles<ClassNames>;
type Suggestion = dashboard.SearchArtist | dashboard.SearchAlbum | dashboard.SearchTrack;
interface Section {
    label: string;
    items: dashboard.SearchArtist[] | dashboard.SearchAlbum[] | dashboard.SearchTrack[];
}

class SearchBox extends React.Component<MergedProps, State> {

    public static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        return { loading: false };
    }

    private mounted: boolean = false;

    constructor(props: MergedProps) {
        super(props);
        this.state = { inputedValue: '', loading: false };
        this.onChangeValue = this.onChangeValue.bind(this);
        this.debouncedOnChange = _.debounce(this.debouncedOnChange, 300);
        this.renderSuggestion = this.renderSuggestion.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onClickHistory = this.onClickHistory.bind(this);
    }

    public componentDidMount() {
        this.mounted = true;
    }

    public componentWillUnmount() {
        this.mounted = false;
    }

    public render() {
        const sections: Section[] = [];
        const { result, classes } = this.props;
        const { inputedValue = '' } = this.state;
        if (result) {
            Object.keys(result).forEach((key: 'artists' | 'albums' | 'tracks') => {
                const items = result[key];
                if (items && items.length > 0) {
                    sections.push({ label: key, items: items });
                }
            });
        }

        return (
            <div className={classes.root}>
                {!!this.state.loading ?
                    <CircularProgress size={20} className={classes.icon} />
                    : <SearchIcon color="primary" className={classes.icon} />
                }
                <Autosuggest
                    renderInputComponent={renderInputComponent}
                    inputProps={{
                        classes,
                        value: inputedValue,
                        onChange: this.onChangeValue,
                        placeholder: 'Search',
                        autoFocus: false,
                    }}
                    suggestions={sections}
                    getSuggestionValue={(suggestion: Suggestion) => (suggestion && suggestion.name)}
                    renderSuggestion={this.renderSuggestion}
                    onSuggestionSelected={this.onSuggestionSelected}
                    multiSection={true}
                    getSectionSuggestions={(section) => (section && section.items)}
                    renderSectionTitle={(section: Section) => (section.label)}
                    renderSuggestionsContainer={renderSuggestionsContainer}
                    theme={{
                        container: classNames(classes.container, this.props.classNamesContainer),
                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                        sectionContainer: classes.sectionContainer,
                        sectionTitle: classes.sectionTitle,
                    }}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={() => null}
                    shouldRenderSuggestions={() => true}
                    alwaysRenderSuggestions={false}
                />
            </div>
        );
    }

    private onChangeValue(e: React.FormEvent<HTMLInputElement>, { newValue: query }: Autosuggest.ChangeEvent) {
        e.persist();
        this.setState({
            inputedValue: query,
        });
        if (query && query.length === 0) {
            this.setState({
                loading: false,
            });
        } else {
            this.debouncedOnChange(query);
        }
    }

    private debouncedOnChange(query: string) {
        if (this.mounted) {
            if (query) {
                const { type, search } = this.props;
                if (type === Type.delivery || type === Type.analysis) {
                    search(query, ['artists'], 50);
                } else {
                    search(query);
                }
                this.setState({
                    loading: true,
                });
            }
        }
    }

    private renderSuggestion(
        suggestion: dashboard.SearchArtist | dashboard.SearchAlbum | dashboard.SearchTrack,
        { query, isHighlighted }: Autosuggest.RenderSuggestionParams) {

        let secondaryText;
        switch (suggestion.type) {
            case 'albums':
                secondaryText = suggestion.artist;
                break;
            case 'tracks':
                secondaryText = `${suggestion.artist} / ${suggestion.album}`;
                break;
            default:
                break;
        }
        return (
            <ul style={{ padding: 0 }}>
                <MenuItem key={suggestion.encryptedId} selected={isHighlighted}>
                    {
                        suggestion.history ? (
                            <IconButton>
                                <HistoryIcon />
                            </IconButton>
                        ) : (
                                <img
                                    src={suggestion.imageUrl}
                                    style={{ minWidth: `${IMG_WIDTH / 2}px`, height: `${IMG_WIDTH / 2}px` }} />

                            )
                    }
                    <ListItemText
                        primary={suggestion.name}
                        secondary={secondaryText}
                        classes={{
                            root: this.props.classes.listItemTextRoot,
                            primary: this.props.classes.listItemTextText,
                            secondary: this.props.classes.listItemTextText,
                        }} />
                </MenuItem>
            </ul>
        );
    }

    private onSuggestionSelected
        (e: React.FormEvent<HTMLElement>, { suggestion }: Autosuggest.SuggestionSelectedEventData<Suggestion>) {
        this.props.saveHistory(suggestion);
        let to;
        switch (this.props.type) {
            case Type.summary:
                const type = suggestion.type;
                to = `/${type.slice(0, type.length - 1)}/${suggestion.encryptedId}/summary${this.props.location.search}`;
                break;
            case Type.delivery:
                to = `/artist/${suggestion.encryptedId}/delivery`;
                break;
            case Type.analysis:
                to = `/artist/${suggestion.encryptedId}/analysis`;
                break;
            default:
                return;
        }
        this.props.history.push(to);
    }

    private onSuggestionsFetchRequested({ value, reason }: { value: string, reason: string }) {
        if (reason === 'input-focused' || reason === 'input-changed' && !value) {
            if (this.props.type === Type.summary) {
                this.props.loadHistories();
            } else {
                this.props.loadHistories(['artists']);
            }
        }
    }

    private onClickHistory(e: React.SyntheticEvent<HTMLLIElement>) {
        const { currentTarget } = e;
        const { childNodes } = currentTarget;
        if (childNodes && childNodes.length > 1) {
            const target = childNodes[1] as HTMLParagraphElement;
            const value = target.innerText;
            this.setState({
                inputedValue: value,
            });
            this.props.search(value, ['artists']);
        }
    }

}

const renderInputComponent = (inputProps: Autosuggest.InputProps<any>) => {
    const { classes, autoFocus, value, ref, ...other } = inputProps;
    return (
        <TextField
            autoFocus={autoFocus}
            className={classes.textField}
            value={value ? value : ''}
            inputRef={ref}
            inputProps={{
                ...other,
            }} />
    );
};

const renderSuggestionsContainer = (options: Autosuggest.RenderSuggestionsContainerParams) => {
    const { containerProps, children } = options;
    return (
        <Paper {...containerProps} square>
            {children}
        </Paper>
    );
};

export default decorate<Props>(SearchBox);
