import React from 'react';
import {
    Grid,
    Paper,
    Typography,
    FormControl,
    TextField,
    Tabs,
    Tab,
    Theme,
    withStyles,
    WithStyles
} from '@material-ui/core';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import List from '../partials/List'

type ClassNames = 'root'
    | 'search'
    | 'inputWrap'

const decorate = withStyles<ClassNames>((theme: Theme) => {
    const u1 = theme.spacing.unit;
    const u2 = 2 * u1;

    return {
        root: {
            backgroundColor: theme.palette.grey[100],
            boxSizing: 'border-box',
            minHeight: '100%',
            padding: u2,
        },
        search: {
            padding: u2,
        },
        inputWrap: {
            marginTop: u1,
        }
    };
});

interface Props {
    keyword: string
    target: 'artists' | 'albums' | 'tracks'
    result: dashboard.SearchSet
    init: () => void
    search: (keyword: string, target: 'artists' | 'albums' | 'tracks') => void
    searchMore: (target: 'artists' | 'albums' | 'tracks') => void
    setTarget: (target: 'artists' | 'albums' | 'tracks') => void
}

interface State {
    tab: number
    keyword: string
    lastKeyword: {
        [ key in Props['target'] ]: string
    }
}

type MergedProps = Props & WithStyles<ClassNames> & RouteComponentProps<{}>;

const tabIndex: { [ key in Props['target']]: number } = {
    artists: 0,
    albums: 1,
    tracks: 2,
}
const targetMap: { [ key: number ]: Props['target'] } = {
    0: 'artists',
    1: 'albums',
    2: 'tracks',
}
const tabLabels = Object.keys(tabIndex)

class Search extends React.Component<MergedProps, State> {
    constructor(props: MergedProps) {
        super(props)
        props.init()
        const keyword = props.keyword || '';
        this.state = {
            tab: tabIndex[props.target] || tabIndex.artists,
            keyword: keyword,
            lastKeyword: {
                artists: props.target === 'artists' ? keyword : '',
                albums: props.target === 'albums' ? keyword : '',
                tracks: props.target === 'tracks' ? keyword : '',
            }
        }
    }

    render() {
        const { classes: s } = this.props;

        return (
            <div className={s.root}>
                <Grid container spacing={8}>
                    {this.buildSearch()}
                    {this.buildResults()}
                </Grid>
            </div>
        );
    }

    buildSearch() {
        const { classes: s } = this.props;
        return (
            <Grid item xs={12}>
                <Paper className={s.search}>
                    <Typography variant="title" color="primary">
                        Search
                    </Typography>
                    <FormControl fullWidth className={s.inputWrap}>
                        <TextField
                            autoFocus={true}
                            value={this.state.keyword}
                            onChange={this.handleInputChange}
                        />
                    </FormControl>
                </Paper>
            </Grid>
        )
    }

    buildResults() {
        const { tab } = this.state;
        const { result } = this.props
        return (
            <Grid item xs={12}>
                <Paper>
                    <Tabs value={tab} fullWidth={true} onChange={this.handleTabChange}>
                        <Tab label={tabLabels[0]} />
                        <Tab label={tabLabels[1]} />
                        <Tab label={tabLabels[2]} />
                    </Tabs>
                    <List
                        type='searchArtist'
                        data={result.artists}
                        hidden={tab !== tabIndex.artists}
                        onNext={this.handleNext}
                    />
                    <List
                        type='searchAlbum'
                        data={result.albums}
                        hidden={tab !== tabIndex.albums}
                        onNext={this.handleNext}
                    />
                    <List
                        type='searchTrack'
                        data={result.tracks}
                        hidden={tab !== tabIndex.tracks}
                        onNext={this.handleNext}
                    />
                </Paper>
            </Grid>
        )
    }

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ keyword: e.target.value });
        this.search();
    }

    handleTabChange = (event: any, tab: number) => {
        this.setState({ tab });
        this.search();
    }

    search = _.debounce(() => {
        const { tab, keyword, lastKeyword } = this.state
        const target = targetMap[tab]

        if (keyword === lastKeyword[target]) {
            this.props.setTarget(target)
            return
        }

        this.setState({
            lastKeyword: {
                ...lastKeyword,
                [target]: keyword,
            }
        })

        this.props.search(keyword, target)
    }, 60)

    handleNext = (type: string) => {
        switch(type) {
            case 'searchArtist':
                this.props.searchMore('artists')
                break;
            case 'searchAlbum':
                this.props.searchMore('albums')
                break;
            case 'searchTrack':
                this.props.searchMore('tracks')
                break;
        }
    }
}

export default decorate<Props>(Search);
