import moment from 'moment';
import React from 'react';
import { Translator } from '../../utils/localization';
import Graph, { Labels, Type } from './Graph';

interface Props {
    plays: dashboard.Plays;
    users: dashboard.Users;
    favCount: Partial<dashboard.FavCount>;
    unit: string;
    start: moment.Moment;
    end: moment.Moment;
    width: number;
    translator: Translator;
}

export const Graphs: React.SFC<Props> = (props) => {
    const data = {
        plays: [{
            label: Labels.PlaysOver30s,
            values: props.plays.over30s,
        }, {
            label: Labels.PlaysUnder30s,
            values: props.plays.under30s,
        }],
        users: [{
            label: Labels.Users,
            values: props.users.values,
        }],
        favCount: Object.keys(props.favCount).map((key) => {
            return ({
                label: key.charAt(0).toUpperCase() + key.slice(1),
                values: props.favCount[key],
            });
        }),
    };
    return (
        <>
            <Graph type={Type.plays} data={data.plays} {...props} />
            <Graph type={Type.users} data={data.users} {...props} />
            <Graph type={Type.favCount} data={data.favCount} {...props} />
        </>
    );
};