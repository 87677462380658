import 'isomorphic-fetch';
import { Dispatch } from 'redux';
import { store } from '../stores';
import {
    KEY_TOKEN,
    load as loadFromLocalStorage,
    save as saveToLocalStorage
} from '../stores/localStorage';
import { RootState } from '../stores/rootState';
import { LoginState } from './../stores/loginState';
import { logout } from './login';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export interface RefreshToken {
    type: typeof REFRESH_TOKEN;
}

export type AuthAction = RefreshToken;

export const refreshToken = () => {
    return async (dispatch: Dispatch<LoginState>) => {
        const states = store.getState() as RootState;
        const response = await fetch('/api/refresh', {
            headers: {
                'X-Access-Token': loadFromLocalStorage(KEY_TOKEN) || '',
            },
        }).catch((err) => {
            console.error(err);
            dispatch(logout());
            return;
        });
        if (response) {
            if (!response.ok) {
                console.error(states.window.translator.translate('login__errorMessage1', `${response.status}:${response.statusText}`));
                dispatch(logout());
            } else {
                const json = await response.json();
                const token = json.token;
                saveToLocalStorage(null, token);
            }
        }
    };
};
