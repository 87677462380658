import { Grid } from '@material-ui/core';
import React from 'react';
import { Translator } from '../../utils/localization';
import Figure, { Type } from './Figure';

interface Props {
    playsSum: dashboard.PlaysSum;
    usersSum: dashboard.UsersSum;
    translator: Translator;
}

export const Figures: React.SFC<Props> = (props) => {
    return (
        <Grid container spacing={16}>
            <Figure type={Type.plays} sum={props.playsSum.value} sumOver30s={props.playsSum.valueOver30s} {...props} />
            <Figure type={Type.users} sum={props.usersSum.value} {...props} />
            <Figure type={Type.playsShare} ratio={props.playsSum.ratio} {...props} />
            <Figure type={Type.usersShare} ratio={props.usersSum.ratio} {...props} />
        </Grid>
    );
};