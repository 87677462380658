import { FAILED, NotificationsAction, SUCCEEDED } from '../actions/notifications';
import { NotificationsState } from '../stores/notificationsState';

export default (state: NotificationsState, action: NotificationsAction): NotificationsState => {
    switch (action.type) {
        case SUCCEEDED:
            return { ...state, result: action.result, unread: action.unread };
        case FAILED:
            return { ...state, errorMessage: action.errorMessage };
        default:
            return state || {};
    }
};