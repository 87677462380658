import {
    Theme,
    WithStyles,
    withStyles,
} from '@material-ui/core';

export type StyleProps = WithStyles<keyof ReturnType<typeof styles>>

export const decorate = withStyles(styles);

function styles(theme: Theme) {
    const u1 = theme.spacing.unit;
    const u2 = u1 * 2;
    return {
        root: {
            backgroundColor: theme.palette.grey[100],
            padding: u2,
            minHeight: '100%',
        },
        summary: {
            padding: u2,
            overflowX: 'auto',
        },
        buttons: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            position: 'relative',
        },
        showChart: {
            position: 'absolute',
            right: 0,
            top: 0,
        },
        contentsContainer: {
            display: 'flex',
            marginTop: u2,
        },
        imgContainer: {
            backgroundColor: theme.palette.grey[100],
            height: 258,
            width: 258,
        },
        img: {
            display: 'block',
            height: 'inherit',
            width: 'inherit',
        },
        summaryBody: {
            flex: 1,
            paddingLeft: u2,
        },
        link: {
            color: 'inherit',
            '&:hover': {
                textDecoration: 'underline'
            },
        },
        urlCopyIconButton: {
            width: 32,
            height: 32,
            marginRight: u1,
        },
        urlCopyIcon: {
            width: 16,
            height: 16
        },
        embed: {
            display: 'flex',
        },
        embedButton: {
            width: 32,
        },
        embedCodeWrap: {
            flex: 1,
            paddingLeft: u1,
        },
        embedCode: {
            background: theme.palette.grey[50],
            border: `1px solid ${theme.palette.grey[200]}`,
            color: theme.palette.grey[700],
            display: 'block',
            lineHeight: 1.4,
            borderRadius: 4,
            padding: u1,
        },
        errorText: {
            color: theme.palette.grey[700],
            fontSize: '1rem',
            marginTop: theme.spacing.unit * 8,
            whiteSpace: 'pre-line',
            lineHeight: 1.6,
            textAlign: 'center',
            display: 'block',
            width: '100%'
        },
    } as const
}
