import 'isomorphic-fetch';
import { Dispatch } from 'redux';
import { store } from '../stores';
import { remove as removeFromLocalStorage, save as saveToLocalStorage } from '../stores/localStorage';
import { RootState } from '../stores/rootState';
import { LoginState } from './../stores/loginState';
import * as delivery from './delivery';
import * as summary from './summary';

export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const LOAD_LOGIN_STATE = 'LOAD_LOGIN_STATE';

export interface LoginSucceeded {
    type: typeof LOGIN_SUCCEEDED;
    company: string;
    token: string;
}

export interface LoginFailed {
    type: typeof LOGIN_FAILED;
    errorMessage: string;
}

export interface Logout {
    type: typeof LOGOUT;
}

export interface LoadLoginState {
    type: typeof LOAD_LOGIN_STATE;
    companyFromLocalStorage: string;
    tokenFromLocalStorage: string;
    isLoggedInFromLocalStorage: boolean;
}

export type LoginAction = LoginSucceeded | LoginFailed | Logout | LoadLoginState;

export const login = (username: string, password: string) => {
    return async (dispatch: Dispatch<LoginState>) => {
        const states = store.getState() as RootState;
        const response = await fetch('/login', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        }).catch((err) => {
            removeFromLocalStorage();
            dispatch(loginFailed(err.message));
            return;
        });
        if (response) {
            if (!response.ok) {
                removeFromLocalStorage();
                if (response.status === 401) {
                    dispatch(loginFailed(states.window.translator.translate('login__errorMessage2')));
                    return;
                } else {
                    dispatch(loginFailed(states.window.translator.translate('app__errorMessage1')));
                    return;
                }
            }
            const json: dashboard.Distributor = await response.json();

            const company = json.company;
            const token = json.token;
            saveToLocalStorage(company, token);
            dispatch(loginSucceeded(company, token));
        }
    };
};

const loginSucceeded = (company: string, token: string) => {
    return {
        type: LOGIN_SUCCEEDED,
        company,
        token,
    };
};

const loginFailed = (errorMessage: string) => {
    return {
        type: LOGIN_FAILED,
        errorMessage,
    };
};

export const logout = () => {
    removeFromLocalStorage();
    return (dispatch: Dispatch<{}>) => {
        dispatch({
            type: LOGOUT,
        });
        dispatch({
            type: summary.CLEAR,
        });
        dispatch({
            type: delivery.CLEAR,
        });
    };
};

export const loadLoginState =
    (companyFromLocalStorage: string | null, tokenFromLocalStorage: string | null, isLoggedInFromLocalStorage: boolean) => {
        return {
            type: LOAD_LOGIN_STATE,
            companyFromLocalStorage,
            tokenFromLocalStorage,
            isLoggedInFromLocalStorage,
        };
    };