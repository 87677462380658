import { grey } from '@material-ui/core/colors';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as loginActions from './actions/login';
import * as windowActions from './actions/window';
import Routes from './components/Routes';
import { store } from './stores';
import {
    isLoggedIn as isLoggedInFromLocalStorage,
    KEY_COMPANY,
    KEY_TOKEN,
    load as loadFromLocalStorage
} from './stores/localStorage';

// NOTE: Initialize store
store.dispatch(loginActions.loadLoginState(
    loadFromLocalStorage(KEY_COMPANY),
    loadFromLocalStorage(KEY_TOKEN),
    isLoggedInFromLocalStorage())
);
store.dispatch(windowActions.localize());
window.addEventListener('resize', _.throttle(() => {
    store.dispatch(windowActions.resize(window.innerWidth, window.innerHeight));
}, 1000));

const theme = createMuiTheme({
    palette: {
        primary: grey,
        secondary: {
            main: '#fc7459',
        },
        error: {
            main: '#fc7459',
        },
    },
});

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <Provider store={store}>
            <Routes />
        </Provider>
    </MuiThemeProvider>,
    document.getElementById('app') as HTMLElement
);