import {
    Checkbox,
    Divider,
    Fade,
    FormControl,
    FormControlLabel,
    FormGroup,
    LinearProgress,
    List,
    MenuItem,
    Paper,
    Theme,
    Typography,
    withStyles,
    WithStyles
} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import InfiniteScroll = require('react-infinite-scroller');
import { Link, RouteComponentProps } from 'react-router-dom';
import { EMPTY_IMG } from '../../../consts';
import { Translator } from '../../../utils/localization';
import SearchBox from '../../SearchBox';
import { Type as SearchType } from '../../SearchBox';
import Toolbar from '../../Toolbar';

const LIMIT = 20;

type ClassNames = 'root'
    | 'toolbarContainer'
    | 'listContainer'
    | 'imgContainer'
    | 'img'
    | 'name'
    | 'linerNotes'
    | 'menuItemRoot'
    | 'errorTextContainer'
    | 'errorText';

const decorate = withStyles<ClassNames>((theme: Theme) => {
    const IMAGE_SIZE = 100;
    return {
        root: {
            backgroundColor: theme.palette.grey[100],
            padding: 16,
        },
        toolbarContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
        },
        listContainer: {
            marginTop: 64,
        },
        imgContainer: {
            backgroundImage: `url(${EMPTY_IMG})`,
        },
        img: {
            width: IMAGE_SIZE,
            height: IMAGE_SIZE,
            backgroundImage: `url(${EMPTY_IMG})`,
        },
        name: {
            width: `calc((100% - ${IMAGE_SIZE}px) / 3)`,
            padding: '0 16px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        linerNotes: {
            width: `calc((100% - ${IMAGE_SIZE}px) / 3 * 2)`,
            padding: '0 16px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        menuItemRoot: {
            height: IMAGE_SIZE,
        },
        errorTextContainer: {
            display: 'flex',
            justifyContent: 'center',
        },
        errorText: {
            marginTop: 32,
        },
    };
});

interface Props {
    results: dashboard.Delivery[];
    errorMessage: string;
    translator: Translator;
    searchResult: dashboard.SearchSet;
    searchErrorMessage: string | undefined;
    load: (offset: number, limit: number, noLiner: boolean, noImage: boolean) => void;
    search: (query: string, targets?: ['artists' | 'albums' | 'tracks'], limit?: number) => void;
    loadHistories: (targets?: ['artists' | 'albums' | 'tracks']) => void;
    saveHistory: (history: dashboard.SearchArtist | dashboard.SearchAlbum | dashboard.SearchTrack) => void;
    clear: () => void;
}

interface State {
    noLiner: boolean;
    noImage: boolean;
    offset: number;
    allData: dashboard.Delivery[];
    hasMore: boolean;
}

type MergedProps = Props & WithStyles<ClassNames> & RouteComponentProps<{}>;

class Artists extends React.Component<MergedProps, State> {

    public static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const { allData } = prevState;
        const newData = nextProps.results || [];
        return {
            allData: _.uniq(allData.concat(newData)),
            hasMore: newData.length < LIMIT ? false : true,
        };
    }

    private mounted: boolean = false;

    constructor(props: MergedProps) {
        super(props);
        this.state = { noLiner: false, noImage: false, offset: 0, allData: [], hasMore: true };
        this.loadMore = _.throttle(this.loadMore.bind(this), 3000);
    }

    public componentDidMount() {
        this.props.load(this.state.offset, LIMIT, this.state.noLiner, this.state.noImage);
        this.mounted = true;
    }

    public componentWillUnmount() {
        this.mounted = false;
        this.props.clear();
    }

    public render() {
        const { classes, errorMessage, translator } = this.props;
        const { allData, hasMore } = this.state;

        return errorMessage ? (
            <div className={classes.errorTextContainer}>
                <Typography variant="title" color="error" className={classes.errorText}>{errorMessage}</Typography>
            </div>
        ) :
            (
                <div className={classes.root}>
                    <Toolbar>
                        <div className={classes.toolbarContainer}>
                            <FormControl component="fieldset">
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={this.state.noImage}
                                                onChange={this.onCheckBoxClicked('noImage').bind(this)}
                                                value="noImage"
                                            />
                                        }
                                        label={translator.translate('deliveries__noImage')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={this.state.noLiner}
                                                onChange={this.onCheckBoxClicked('noLiner').bind(this)}
                                                value="noLiner"
                                            />
                                        }
                                        label={translator.translate('deliveries__noBio')}
                                    />
                                </FormGroup>
                            </FormControl>
                            <div>
                                <SearchBox
                                    search={this.props.search}
                                    loadHistories={this.props.loadHistories}
                                    saveHistory={this.props.saveHistory}
                                    type={SearchType.delivery}
                                    result={this.props.searchResult}
                                    errorMessage={this.props.searchErrorMessage}
                                    history={this.props.history}
                                    location={this.props.location} />
                            </div>
                        </div>
                    </Toolbar>
                    <Paper className={classes.listContainer}>
                        {allData.length > 0 ? (
                            <List disablePadding>
                                <InfiniteScroll
                                    loadMore={this.loadMore}
                                    hasMore={hasMore}
                                    threshold={100}
                                    loader={<LinearProgress />}>
                                    {
                                        allData.map((d, i) => {
                                            return (
                                                <div key={i}>
                                                    <Link to={`/artist/${d.encryptedId}/delivery`}>
                                                        <MenuItem classes={{ root: classes.menuItemRoot }}>
                                                            <Fade key={d.id} in={true} timeout={1000}>
                                                                <img src={d.imageUrl ? d.imageUrl : EMPTY_IMG} className={classes.img} />
                                                            </Fade>
                                                            <Typography className={classes.name}>{d.name}</Typography>
                                                            <Typography className={classes.linerNotes}>{d.linerNotes}</Typography>
                                                        </MenuItem>
                                                    </Link>
                                                    <Divider />
                                                </div>
                                            );
                                        })
                                    }
                                </InfiniteScroll>
                            </List>
                        ) : (
                                <LinearProgress />
                            )
                        }
                    </Paper>
                </div>
            );
    }

    private onCheckBoxClicked = (name: any) => (e: any, checked: boolean) => {
        const { noLiner, noImage } = this.state;
        this.props.load(0, LIMIT, name === 'noLiner' ? checked : noLiner, name === 'noImage' ? checked : noImage);
        this.setState({ [name]: checked, allData: [], hasMore: true } as any);
    }

    private loadMore() {
        if (this.mounted) {
            const { noLiner, noImage, offset } = this.state;
            const nextOffset = offset + LIMIT;
            this.props.load(nextOffset, LIMIT, noLiner, noImage);
            this.setState({ offset: nextOffset });
        }
    }
}

export default decorate<Props>(Artists);
