import { Button, Divider, MenuItem, Theme, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import moment from 'moment';
import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { DATE_FORMAT } from '../../consts/index';
import { Translator } from '../../utils/localization';

const LIMIT = 50;

const WIDTH = {
    RIGHT_AREA: 34.5,
    IMG: 40,
    IMG_PLAYLIST: 48,
};
const MARGIN = {
    CENTER_AREA: 8,
};

type ClassNames = 'title'
    | 'list'
    | 'img'
    | 'imgPlaylist'
    | 'centerAreaContainer'
    | 'primaryText'
    | 'secondaryText'
    | 'rightAreaContainer'
    | 'rank'
    | 'rankOver100'
    | 'count'
    | 'button'
    | 'loading'
    | 'typographyTitle';

const decorate = withStyles<ClassNames>((theme: Theme) => ({
    title: {
        marginLeft: 16,
        padding: '8px 0',
    },
    list: {
        paddingTop: 16,
        paddingBottom: 16,
    },
    img: {
        width: WIDTH.IMG,
    },
    imgPlaylist: {
        width: WIDTH.IMG_PLAYLIST,
    },
    centerAreaContainer: {
        width: `calc(100% - (${WIDTH.IMG}px + ${WIDTH.RIGHT_AREA}px + ${MARGIN.CENTER_AREA}px * 2))`,
        margin: `0 ${MARGIN.CENTER_AREA}px`,
    },
    primaryText: {
        fontSize: '0.9rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    secondaryText: {
        fontSize: '0.75rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    rightAreaContainer: {
        width: WIDTH.RIGHT_AREA,
    },
    rank: {
        fontStyle: 'italic',
        fontSize: '2em',
        fontWeight: 'bold',
        color: theme.palette.grey[400],
    },
    rankOver100: {
        fontSize: '1.7em',
    },
    count: {
        fontSize: '0.7em',
        color: theme.palette.grey[500],
    },
    button: {
        width: '100%',
    },
    loading: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    // For overrides
    typographyTitle: {
        margin: '2px 0',
    },
}));

export enum Type {
    artists = 'artists',
    albums = 'albums',
    tracks = 'tracks',
    playlists = 'playlists',
}

interface Props {
    type: Type;
    start: moment.Moment;
    end: moment.Moment;
    data: dashboard.Ranking;
    dataSet: dashboard.DataSet;
    hasNextRankings: { [key: string]: boolean };
    translator: Translator;
    loadMoreRanking: (
        start: string,
        end: string,
        offset: number,
        limit: number,
        type: 'artists' | 'albums' | 'tracks' | 'playlists',
        target?: { uriType: string, id: string } | null)
        => void;
}

interface State {
    offset: number;
    loading: boolean;
    showMoreButton: boolean;
}

type MergedProps = Props & WithStyles<ClassNames> & RouteComponentProps<{ id: string }>;

class Rank extends React.Component<MergedProps, State> {

    public static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        if (prevState.loading) {
            const hasNextRankings = nextProps.hasNextRankings;
            let showMoreButton = true;
            if (hasNextRankings && hasNextRankings[nextProps.type] === false) {
                showMoreButton = false;
            }
            return { loading: false, showMoreButton };
        } else {
            return null;
        }
    }

    constructor(props: MergedProps) {
        super(props);
        this.onLoadMore = this.onLoadMore.bind(this);
        this.state = { offset: LIMIT, loading: false, showMoreButton: true };
    }

    public shouldComponentUpdate(nextProps: Props) {
        const data: dashboard.Ranking = nextProps.data;
        return data[nextProps.type] ? true : false;
    }

    public render() {
        const { data: allData, dataSet, type, classes, translator } = this.props;
        const { loading, showMoreButton } = this.state;
        const data = allData[type];
        if (!allData || !dataSet || !data) {
            return null;
        }

        const buildContent = (i: number, d: { id: string, count: number }) => (
            <MenuItem key={i} className={classes.list}>
                {
                    type !== Type.playlists ? (
                        <img src={dataSet[type][d.id] && dataSet[type][d.id].imageUrl} className={classes.img} />
                    ) : (
                            dataSet[type][d.id].isPublic ? (
                                <img src={dataSet[type][d.id].imageUrl} className={classes.imgPlaylist} />
                            ) : null
                        )
                }
                {buildCenterArea(d.id, type, dataSet, classes, translator)}
                <div className={classes.rightAreaContainer}>
                    <Typography variant="display1" className={`${classes.rank} ${i >= 99 ? classes.rankOver100 : ''}`}>{i + 1}</Typography>
                    <Typography className={classes.count}>{Number(d.count).toLocaleString()}</Typography>
                </div>
            </MenuItem>
        );

        return (
            <>
                <Typography variant="title" color="primary" className={classes.title}>
                    {type.charAt(0).toUpperCase() + type.slice(1)} Ranking
                </Typography>
                <List disablePadding>
                    {
                        data.map((d, i) => {
                            if (type !== Type.playlists) {
                                return (
                                    <Link to={
                                        // tslint:disable-next-line:max-line-length
                                        `/${type.slice(0, type.length - 1)}/${dataSet[type][d.id].encryptedId}/summary${this.props.location.search}`}
                                        key={i}>
                                        {buildContent(i, d)}
                                        <Divider />
                                    </Link>
                                );
                            } else {
                                const dataSetPlaylist = dataSet[type][d.id];
                                if (!dataSetPlaylist) { return null; }
                                return (
                                    <a href={`https://s.awa.fm/playlist/${dataSetPlaylist.id}/?t=${dataSetPlaylist.updatedAt}`} key={i} >
                                        {buildContent(i, d)}
                                        <Divider />
                                    </a>
                                );
                            }
                        })
                    }
                </List>
                {
                    loading ? (
                        <div className={classes.loading}>
                            <CircularProgress />
                        </div>
                    ) :
                        showMoreButton ? (
                            <Button variant="raised" className={classes.button} onClick={this.onLoadMore}>
                                Load more
                            </Button>) : null
                }
            </>
        );

    }

    public onLoadMore() {
        const uriType = this.props.match.url.match(/artist|album|track/);
        this.props.loadMoreRanking(
            this.props.start.format(DATE_FORMAT),
            this.props.end.format(DATE_FORMAT),
            this.state.offset,
            LIMIT,
            this.props.type,
            uriType ? {
                uriType: uriType[0],
                id: this.props.match.params.id,
            } : null
        );
        this.setState({
            offset: this.state.offset + LIMIT,
            loading: true,
        });
    }
}

const buildCenterArea =
    (id: string, type: Type, dataSet: dashboard.DataSet, classes: Record<ClassNames, string>, translator: Translator) => {
        const buildText = (primary: string, secondary?: string) => (
            <>
                <Typography variant="title"
                    className={classes.primaryText}
                    classes={{ title: classes.typographyTitle }}>
                    {primary}
                </Typography>
                {secondary ? (
                    <Typography variant="subheading"
                        color="primary"
                        className={classes.secondaryText}>
                        {secondary}
                    </Typography>
                ) : null}
            </>
        );

        switch (type) {
            case Type.artists:
                const dataSetArtist = dataSet[type][id];
                return (
                    <div className={classes.centerAreaContainer}>
                        {buildText(dataSetArtist.name)}
                    </div>
                );
            case Type.albums:
                const dataSetAlbum = dataSet[type][id];
                return (
                    <div className={classes.centerAreaContainer}>
                        {buildText(dataSetAlbum.name, dataSetAlbum.artist.name)}
                    </div>
                );
            case Type.tracks:
                const dataSetTrack = dataSet[type][id];
                return (
                    <div className={classes.centerAreaContainer}>
                        {buildText(dataSetTrack.name, `${dataSetTrack.artist.name} / ${dataSetTrack.album.name}`)}
                    </div>
                );
            case Type.playlists:
                const dataSetPlaylist = dataSet[type][id];
                const widthStyle = dataSetPlaylist.isPublic ?
                    `calc(100% - (${WIDTH.IMG_PLAYLIST}px + ${WIDTH.RIGHT_AREA}px + ${MARGIN.CENTER_AREA * 2}px))`
                    :
                    `calc(100% - (${WIDTH.RIGHT_AREA}px + ${MARGIN.CENTER_AREA * 2}px))`;
                return (
                    <div style={{ width: widthStyle, margin: `0 ${MARGIN.CENTER_AREA}px` }}>
                        {dataSetPlaylist.isPublic ?
                            buildText(dataSetPlaylist.name, dataSet.users[dataSetPlaylist.userId].name)
                            :
                            <Typography variant="subheading" color="primary" className={classes.secondaryText}>
                                {translator.translate('summary__rank__unpublishedPlaylist')}
                            </Typography>
                        }
                    </div>
                );
            default:
                break;
        }
    };

export default decorate<Props>(Rank);