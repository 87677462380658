import React from 'react';
import {
    Grid,
    Paper,
    Button,
    Typography,
    FormControl,
    TextField,
    Tabs,
    Tab,
    Tooltip,
    IconButton,
} from '@material-ui/core';
import ContentCopy from '@material-ui/icons/ContentCopy';
import ShowChart from '@material-ui/icons/ShowChart';
import _ from 'lodash';
import { Link, RouteComponentProps } from 'react-router-dom';
import { decorate, StyleProps } from './style';
import List from '../partials/List';
import { copyToClipboard } from '../../../../utils/clipboard';
import Breadcrumbs from '../partials/Breadcrumbs';
import Img from '../partials/Img';

interface Props {
    keyword: string
    target: 'albums' | 'tracks'
    summary: dashboard.DeliveryArtist | null
    searchList: {
        albums: dashboard.SearchAlbum[]
        tracks: dashboard.SearchTrack[]
    }
    list: {
        albums: dashboard.SearchAlbum[]
        tracks: dashboard.SearchTrack[]
    }
    errorMessage: string
    init: (id: string) => void
    search: (keyword: string, target: 'albums' | 'tracks') => void
    loadMore: (target: 'albums' | 'tracks') => void
    setTarget: (target: 'albums' | 'tracks') => void
}

interface State {
    tab: number
    keyword: string
    lastKeyword: {
        [ key in Target ]: string
    }
}

type MergedProps = Props & StyleProps & RouteComponentProps<{ id: string }>;

type Target = 'albums' | 'tracks'

const tabIndex: { [ key in Target]: number } = {
    albums: 0,
    tracks: 1,
}
const targetMap: { [ key: number ]: Target } = {
    0: 'albums',
    1: 'tracks',
}
const tabLabels = Object.keys(tabIndex);
const LOADING = 'loading...';

class Artist extends React.Component<MergedProps, State> {
    constructor(props: MergedProps) {
        super(props);

        const { id } = props.match.params;
        this.props.init(id);
        this.state = Artist.getDerivedStateFromProps(props)
    }

    public static getDerivedStateFromProps(nextProps: MergedProps) {
        const keyword = nextProps.keyword || '';

        return {
            tab: tabIndex[nextProps.target] || tabIndex.albums,
            keyword: keyword,
            lastKeyword: {
                albums: nextProps.target === 'albums' ? keyword : '',
                tracks: nextProps.target === 'tracks' ? keyword : '',
            },
        };
    }

    render() {
        const { classes: s, summary, errorMessage } = this.props;

        if (errorMessage) {
            return (
                <div className={s.root}>
                    <Grid container spacing={8}>
                        <Breadcrumbs
                            items={[
                                { label: 'Search', link: '/delivery' },
                                { label: 'unknown artist' },
                            ]}
                        />
                        <p className={s.errorText}>
                            {errorMessage}
                        </p>
                    </Grid>
                </div>
            );
        }

        return (
            <div className={s.root}>
                <Grid container spacing={8}>
                    <Breadcrumbs
                        items={[
                            { label: 'Search', link: '/delivery' },
                            { label: summary?.name || LOADING }
                        ]}
                    />
                    {this.buildSummary(summary)}
                    {this.buildSearch()}
                    {this.buildResults()}
                </Grid>
            </div>
        );
    }

    private buildSummary(summary:  dashboard.DeliveryArtist | null) {
        const { classes: s } = this.props;
        const { id, imageUrl } = summary || {};

        return (
            <Grid item xs={12}>
                <Paper className={s.summary}>
                    <div className={s.buttons}>
                        {
                            id ? (
                                <Link to={`/delivery/artist/${id}/edit`}>
                                    <Button variant='raised'>
                                        Edit
                                    </Button>
                                </Link>
                            ) : (
                                <span>
                                    <Button variant='raised' disabled>
                                        Edit
                                    </Button>
                                </span>
                            )
                        }
                        {
                            id ? (
                                <Tooltip title="Show Chart">
                                    <Link to={`/artist/${id}/summary`} className={s.showChart}>
                                        <Button variant="fab">
                                            <ShowChart />
                                        </Button>
                                    </Link>
                                </Tooltip>
                            ) : null
                        }
                    </div>
                    <div className={s.contentsContainer}>
                        <div className={s.imgContainer}>
                            {
                                imageUrl ? (
                                    <Img src={imageUrl} className={s.img} />
                                ) : null
                            }
                        </div>
                        {this.buildSummaryBody(summary)}
                    </div>
                </Paper>
            </Grid>
        )
    }

    private buildSummaryBody(summary:  dashboard.DeliveryArtist | null) {
        const { classes: s, match } = this.props;
        const { id } = match.params;
        const now = summary ? '' : `${Date.now() / 1000 | 0}`
        const name = summary ? summary.name : LOADING;
        const published = summary ? (summary.isDeleted ? 'Stopped' : 'Published') : LOADING;
        const linerNotes = summary ? summary.linerNotes : '';
        const shareUrl = summary ? summary.shareUrl : `https://s.awa.fm/artist/${id}?t=${now}`;
        const favorited = summary ? `${summary.favorited}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : LOADING;
        const embedCode = summary ? summary.embedCode : `<iframe src="https://embed.awa.fm/artist/${id}?t=${now}" width="100%" height="354" frameborder="0" allowtransparency="true"></iframe>`;

        return (
            <div className={s.summaryBody}>
                <Typography variant='subheading' color='primary'>
                    NAME
                </Typography>
                <Typography gutterBottom>
                    {name}
                </Typography>
                <Typography variant='subheading' color='primary'>
                    STATUS
                </Typography>
                <Typography gutterBottom>
                    {published}
                </Typography>
                <Typography variant='subheading' color='primary'>
                    FAVORITED
                </Typography>
                <Typography gutterBottom>
                    {favorited}
                </Typography>
                <Typography variant='subheading' color='primary'>
                    BIOGRAPHY
                </Typography>
                <Typography gutterBottom className={s.pre}>
                    {linerNotes}
                </Typography>
                <Typography variant='subheading' color='primary'>
                    URL
                </Typography>
                <Typography gutterBottom>
                    <Tooltip title="Copy Share Url">
                        <IconButton
                            aria-label="copy"
                            className={s.urlCopyIconButton}
                            onClick={() => this.copy(shareUrl)}
                        >
                            <ContentCopy className={s.urlCopyIcon} />
                        </IconButton>
                    </Tooltip>
                    <Link
                        className={s.link}
                        to={shareUrl}
                        title={shareUrl}
                        target='_blank'
                    >
                        {shareUrl}
                    </Link>
                </Typography>
                <Typography variant='subheading' color='primary'>
                    EMBED CODE
                </Typography>
                <div className={s.embed}>
                    <div className={s.embedButton}>
                        <Tooltip title="Copy Embed Code">
                            <IconButton
                                aria-label="copy"
                                className={s.urlCopyIconButton}
                                onClick={() => this.copy(embedCode)}
                            >
                                <ContentCopy className={s.urlCopyIcon} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={s.embedCodeWrap}>
                        <code className={s.embedCode}>
                            {embedCode}
                        </code>
                    </div>
                </div>
            </div>
        )
    }

    private buildSearch() {
        const { classes: s, summary } = this.props;
        return (
            <Grid item xs={12}>
                <Paper className={s.search}>
                    <Typography variant="title" color="primary">
                        Search in Artist
                    </Typography>
                    <FormControl fullWidth className={s.inputWrap}>
                        <TextField onChange={this.handleInputChange} disabled={!summary} />
                    </FormControl>
                </Paper>
            </Grid>
        )
    }

    private buildResults() {
        const { tab, keyword } = this.state;
        const { list, searchList } = this.props;
        const isSearch = !!keyword;

        return (
            <Grid item xs={12}>
                <Paper>
                    <Tabs value={tab} fullWidth={true} onChange={this.handleTabChange}>
                        <Tab label={tabLabels[0]} />
                        <Tab label={tabLabels[1]} />
                    </Tabs>
                    <List
                        type='artistAlbum'
                        data={list.albums}
                        hidden={tab !== tabIndex.albums || isSearch}
                        onNext={this.handleNext}
                    />
                    <List
                        type='artistTrack'
                        data={list.tracks}
                        hidden={tab !== tabIndex.tracks || isSearch}
                        onNext={this.handleNext}
                    />
                    <List
                        type='artistAlbum'
                        data={searchList.albums}
                        hidden={tab !== tabIndex.albums || !isSearch}
                        onNext={this.handleNext}
                    />
                    <List
                        type='artistTrack'
                        data={searchList.tracks}
                        hidden={tab !== tabIndex.tracks || !isSearch}
                        onNext={this.handleNext}
                    />
                </Paper>
            </Grid>
        )
    }

    private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ keyword: e.target.value });
        this.search();
    }

    private handleTabChange = (event: any, tab: number) => {
        const { keyword } = this.state;
        this.setState({ tab });

        const target = targetMap[tab];
        this.props.setTarget(target);

        if (keyword) {
            this.search();
        }
    }

    private search = _.debounce(() => {
        const { tab, keyword, lastKeyword } = this.state;
        const target = targetMap[tab];

        if (keyword === lastKeyword[target]) {
            return
        }

        this.setState({
            lastKeyword: {
                ...lastKeyword,
                [target]: keyword,
            }
        })

        this.props.search(keyword, target);
    }, 60)

    private handleNext = (type: string) => {
        switch(type) {
            case 'artistAlbum':
                this.props.loadMore('albums');
                break;
            case 'artistTrack':
                this.props.loadMore('tracks');
                break;
        }
    }

    private async copy(text: string) {
        await copyToClipboard(text);
    }
}

export default decorate<Props>(Artist);
