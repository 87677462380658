import {
    GridList, GridListTile, GridListTileBar, Grow, Paper, Theme, Typography, WithStyles, withStyles
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Type as RelatedType } from '../../actions/analysis';
import { EMPTY_IMG } from '../../consts';
import { Translator } from '../../utils/localization';

const CELL_HEIGHT = 200;
const GRID_LIST_TYLE_STYLE = { width: 200, height: 200, padding: 0 };

type ClassNames = 'root'
    | 'container'
    | 'title'
    | 'gridList'
    | 'gridListTile'
    | 'img'
    | 'iconButton';

const decorate = withStyles<ClassNames>((theme: Theme) => ({
    root: {
        padding: 16,
        backgroundColor: theme.palette.grey[100],
        height: '100%',
        boxSizing: 'border-box',
        overflow: 'hidden',
    },
    container: {
        marginBottom: 16,
        padding: 16,
    },
    title: {
        marginBottom: 8,
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },
    gridListTile: {
        backgroundImage: `url(${EMPTY_IMG})`,
        backgroundSize: 'contain',
        margin: '0 2px',
    },
    img: {
        width: 200,
    },
    iconButton: {
        color: theme.palette.common.white,
    },
}));

interface Props {
    resultsViaPlaylist: dashboard.RelatedArtist[];
    resultsViaPlayback: dashboard.RelatedArtist[];
    errorMessage: string;
    translator: Translator;
    width: number;
    loadRelatedArtists: (id: string, typd: RelatedType) => void;
    clearRelatedArtists: () => void;
    getArtist: (id: string) => void;
    clearArtist: () => void;
}

type MergedProps = Props & WithStyles<ClassNames> & RouteComponentProps<{ id: string }>;

class Artist extends React.Component<MergedProps, {}> {

    constructor(props: MergedProps) {
        super(props);
    }

    public componentDidMount() {
        const id = this.props.match.params.id;
        this.props.loadRelatedArtists(id, RelatedType.playlist);
        this.props.loadRelatedArtists(id, RelatedType.playback);
        this.props.getArtist(id);
    }

    public componentWillUnmount() {
        this.props.clearRelatedArtists();
        this.props.clearArtist();
    }

    public render() {
        const { resultsViaPlaylist, resultsViaPlayback, errorMessage, translator, classes } = this.props;

        return (
            <div className={classes.root}>
                <Paper className={classes.container}>
                    <Typography variant="title" color="primary" className={classes.title}>
                        {translator.translate('analysis__relatedArtists__viaPlaylist__title')}
                    </Typography>
                    {
                        resultsViaPlaylist && resultsViaPlaylist.length > 0 ? (
                            <GridList cellHeight={CELL_HEIGHT} className={classes.gridList}>
                                {resultsViaPlaylist.map((result) => (
                                    buildItem(result, classes)
                                ))}
                            </GridList>
                        ) : (
                                <Typography variant="title" color="error">{errorMessage}</Typography>
                            )
                    }
                </Paper>
                {
                    resultsViaPlayback && resultsViaPlayback.length > 0 ? (
                        <Paper className={classes.container}>
                            <Typography variant="title" color="primary" className={classes.title}>
                                {translator.translate('analysis__relatedArtists__viaPlayback__title')}
                            </Typography>
                            <GridList cellHeight={CELL_HEIGHT} className={classes.gridList}>
                                {resultsViaPlayback.map((result) => (
                                    buildItem(result, classes)
                                ))}
                            </GridList>
                        </Paper>
                    ) : (
                            null
                        )
                }
            </div>
        );
    }
}

const buildItem = (result: dashboard.RelatedArtist, classes: Record<ClassNames, string>) => (
    <GridListTile
        key={result.id}
        className={classes.gridListTile}
        style={GRID_LIST_TYLE_STYLE}>
        <Grow in={true} timeout={500}>
            <img src={result.imgUrl} className={classes.img} />
        </Grow>
        <GridListTileBar
            title={result.name}
            actionIcon={
                <a href={`https://s.awa.fm/artist/${result.encryptedId}/?t=${result.updatedAt}`}>
                    <IconButton className={classes.iconButton}>
                        <OpenInNewIcon />
                    </IconButton>
                </a>} />
    </GridListTile>
);

export default decorate<Props>(Artist);
