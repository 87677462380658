import { connect, Dispatch } from 'react-redux';
import { withRouter } from 'react-router';
import * as actions from '../../actions/analysis';
import * as commonActions from '../../actions/common';
import Artist from '../../components/analysis/Artist';
import { RootState } from '../../stores/rootState';

function mapStateToProps({ analysis, window }: RootState) {
    return {
        resultsViaPlaylist: analysis.relatedResultsViaPlaylist,
        resultsViaPlayback: analysis.relatedResultsViaPlayback,
        errorMessage: analysis.errorMessage,
        translator: window.translator,
        width: window.width,
    };
}

function mapDispatchToProps(dispatch: Dispatch<actions.AnalysisAction | commonActions.CommonAction>) {
    return {
        loadRelatedArtists: (id: string, type: actions.Type) => { dispatch(actions.loadRelatedArtists(id, type)); },
        clearRelatedArtists: () => { dispatch(actions.clearArtists()); },
        getArtist: (id: string) => { dispatch(commonActions.getCommonInfo('artist', id)); },
        clearArtist: () => { dispatch(commonActions.clearCommonInfo()); },
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Artist));