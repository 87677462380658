import 'isomorphic-fetch';
import * as queryString from 'query-string';
import { Dispatch } from 'redux';
import { RootState } from '../stores/rootState';
import { Translator } from '../utils/localization';
import { DeliveryViewerState } from './../stores/deliveryViewerState';
import { KEY_TOKEN, load as loadFromLocalStorage } from './../stores/localStorage';
import * as commonAction from './common';

const PREFIX = 'DELIVERY_VIEWER_';

/************************************
 *
 * Search
 */
export const search = (keyword: string, target: 'artists' | 'albums' | 'tracks') => {
    return async (dispatch: Dispatch<DeliveryViewerState>) => {
        if (!keyword) {
            dispatch(searchClear());
            return;
        }
        const { ok, status, json } = await callSearchAPI(keyword, {
            limit: 30,
            targets: [ target ],
        });

        if (ok) {
            dispatch(searchSucceeded({ keyword, target, result: json, paging: false}));
        } else {
            console.error({ status, json });
        }
    };
};

export const searchMore = (target: 'artists' | 'albums' | 'tracks') => {
    return (async (dispatch: Dispatch<DeliveryViewerState>, getState: () => RootState) => {
        const { keyword, result } = getState().deliveryViewer.search;
        if (!keyword) {
            dispatch(searchClear());
            return;
        }
        const offset = result[target]?.length || 0;
        const { ok, status, json } = await callSearchAPI(keyword, {
            offset,
            limit: 30,
            targets: [ target ],
        });

        if (ok) {
            dispatch(searchSucceeded({ keyword, target, result: json, paging: true}));
        } else {
            console.error({ status, json });
        }
    }) as any;
};

export const setTarget = (target: 'artists' | 'albums' | 'tracks') => {
    return async (dispatch: Dispatch<DeliveryViewerState>) => {
        dispatch(searchTargetChanged({ target }));
    };
};

/************************************
 *
 * Artist
 */
export const loadArtist = (id: string) => {
    return (async (dispatch: Dispatch<DeliveryViewerState>, getState: () => RootState) => {
        const summary = getState().deliveryViewer.artist.summary;
        const prevId = summary && summary.id;
        const shouldKeepCache = prevId === id;

        if (!shouldKeepCache) {
            dispatch(artistClear());
        }

        dispatch(error(''));

        const response = await fetch(`/api/delivery/viewer/artist/${id}`, auth());
        const json = await response.json();

        if (response.ok) {
            const result: ArtistLoaded['result'] = json;
            dispatch(artistLoaded(result));
            dispatch(commonAction.clearCommonInfo());
            dispatch(commonAction.artistCommonInfo({
                encryptedId: result.id,
                name: result.name,
                updatedAt: result.updatedAt,
            }));

            if (!shouldKeepCache) {
                loadArtistContents('albums')(dispatch, getState);
                loadArtistContents('tracks')(dispatch, getState);
            }
        } else {
            dispatch(commonAction.clearCommonInfo());
            dispatch(error(json.code));
        }
    }) as any;
};

export const searchInArtist = (keyword: string, target: 'albums' | 'tracks') => {
    return (async (dispatch: Dispatch<DeliveryViewerState>, getState: () => RootState) => {
        if (!keyword) {
            dispatch(searchClear());
            return;
        }
        const summary = getState().deliveryViewer.artist.summary;
        const artistId = summary && summary.id;

        if (!artistId) {
            return;
        }

        const { ok, status, json } = await callSearchAPI(keyword, {
            limit: 30,
            targets: [ target ],
            artistId,
        });

        if (ok) {
            dispatch(artistSearchSucceeded({ keyword, target, result: json, paging: false}));
        } else {
            console.error({ status, json });
        }
    }) as any;
};

export const loadArtistContents = (target: 'albums' | 'tracks', offset?: number) => {
    return (async (dispatch: Dispatch<DeliveryViewerState>, getState: () => RootState) => {
        const summary = getState().deliveryViewer.artist.summary;
        const artistId = summary && summary.id;

        if (!artistId) {
            return;
        }

        const queries = queryString.stringify(
            {
                limit: 30,
                offset: offset || 0,
            }
        );
        const response = await fetch(`/api/delivery/viewer/artist/${artistId}/${target}?${queries}`, auth());
        const json = await response.json();

        if (response.ok) {
            if (target === 'albums') {
                dispatch(artistAlbumsLoaded(json));
            } else {
                dispatch(artistTracksLoaded(json));
            }
        } else {
            console.error({ status, json });
        }
    }) as any;
};

export const setArtistSearchTarget = (target: 'albums' | 'tracks') => {
    return async (dispatch: Dispatch<DeliveryViewerState>) => {
        dispatch(artistSearchTargetChanged({ target }));
    };
};

export const loadMoreArtistContents = (target: 'albums' | 'tracks') => {
    return (async (dispatch: Dispatch<DeliveryViewerState>, getState: () => RootState) => {
        // tslint:disable-next-line
        const { list, search, summary } = getState().deliveryViewer.artist;
        const { keyword, result } = search;

        if (!keyword) {
            const offset = list[target]?.length || 0;
            loadArtistContents(target, offset)(dispatch, getState);
        } else if (summary) {
            const offset = result[target]?.length || 0;
            const { ok, status, json } = await callSearchAPI(keyword, {
                offset,
                limit: 30,
                targets: [ target ],
                artistId: summary.id,
            });

            if (ok) {
                dispatch(artistSearchSucceeded({ keyword, target, result: json, paging: true}));
            } else {
                console.error({ status, json });
            }

        }
    }) as any;
};

/************************************
 *
 * Album
 */
export const loadAlbum = (id: string) => {
    return (async (dispatch: Dispatch<DeliveryViewerState>, getState: () => RootState) => {
        const summary = getState().deliveryViewer.album.summary;
        const prevId = summary && summary.id;
        const shouldKeepCache = prevId === id;

        if (!shouldKeepCache) {
            dispatch(albumClear());
        }

        dispatch(error(''));

        const response = await fetch(`/api/delivery/viewer/album/${id}`, auth());
        const json = await response.json();

        if (response.ok) {
            const result: AlbumLoaded['result'] = json;
            dispatch(albumLoaded(json));
            dispatch(commonAction.clearCommonInfo());
            dispatch(commonAction.albumCommonInfo({
                encryptedId: result.summary.id,
                name: result.summary.name,
                updatedAt: result.summary.updatedAt,
            }));
        } else {
            dispatch(error(json.code));
        }
    }) as any;
};

/************************************
 *
 * Track
 */
export const loadTrack = (id: string) => {
    return (async (dispatch: Dispatch<DeliveryViewerState>, getState: () => RootState) => {
        const summary = getState().deliveryViewer.track.summary;
        const prevId = summary && summary.id;
        const shouldKeepCache = prevId === id;

        if (!shouldKeepCache) {
            dispatch(trackClear());
        }

        dispatch(error(''));

        const response = await fetch(`/api/delivery/viewer/track/${id}`, auth());
        const json = await response.json();

        if (response.ok) {
            const result: TrackLoaded['result'] = json;
            dispatch(trackLoaded(json));
            dispatch(commonAction.clearCommonInfo());
            dispatch(commonAction.trackCommonInfo({
                encryptedId: result.summary.id,
                name: result.summary.name,
                updatedAt: result.summary.updatedAt,
            }));
        } else {
            dispatch(error(json.code));
        }
    }) as any;
};

/************************************
 *
 * Internal
 */
async function callSearchAPI(
    query: string,
    options?: {
        offset?: number
        limit?: number
        targets?: ['artists' | 'albums' | 'tracks']
        artistId?: string
    }
) {
    const queries = queryString.stringify(
        {
            offset: options?.offset ? options?.offset : 0,
            limit: options?.limit ? options?.limit : 10,
            targets: options?.targets ? options?.targets.join(',') : '',
            artistId: options?.artistId ? options?.artistId : '',
        }
    );

    const response = await fetch(`/api/search/${query}?${queries}`, auth());

    return {
        ok: response.ok,
        status: response.status,
        json: await response.json(),
    };
}

function auth() {
    return {
        headers: {
            'X-Access-Token': loadFromLocalStorage(KEY_TOKEN) || '',
        },
    };
}

/**
 * DeliveryViewerAction
 */
export type DeliveryViewerAction =
    SearchSucceeded |
    SearchClear |
    SearchTargetChanged |
    ArtistLoaded |
    ArtistClear |
    ArtistAlbumsLoaded |
    ArtistTracksLoaded |
    ArtistSearchSucceeded |
    ArtistSearchTargetChanged |
    AlbumLoaded |
    AlbumClear |
    TrackLoaded |
    TrackClear |
    Error;

/**
 * SEARCH_SUCCEEDED
 */
export const SEARCH_SUCCEEDED = `${PREFIX}SEARCH_SUCCEEDED` as const;
interface SearchSucceeded {
    type: typeof SEARCH_SUCCEEDED;
    result: {
        keyword: string
        target: 'artists' | 'albums' | 'tracks'
        result: dashboard.SearchSet
        paging: boolean
    };
}
const searchSucceeded = (result: SearchSucceeded['result']): SearchSucceeded => ({
    type: SEARCH_SUCCEEDED,
    result,
});

/**
 * SEARCH_CLEAR
 */
export const SEARCH_CLEAR = `${PREFIX}SEARCH_CLEAR` as const;
interface SearchClear { type: typeof SEARCH_CLEAR; }
const searchClear = (): SearchClear => ({ type: SEARCH_CLEAR });

/**
 * SEARCH_TARGET_CHANGED
 */
export const SEARCH_TARGET_CHANGED = `${PREFIX}SEARCH_TARGET_CHANGED` as const;
interface SearchTargetChanged {
    type: typeof SEARCH_TARGET_CHANGED;
    result: {
        target: 'artists' | 'albums' | 'tracks'
    };
}
const searchTargetChanged = (result: SearchTargetChanged['result']): SearchTargetChanged => ({
    type: SEARCH_TARGET_CHANGED,
    result: result,
});

/**
 * ARTIST_LOADED
 */
export const ARTIST_LOADED = `${PREFIX}ARTIST_LOADED` as const;
interface ArtistLoaded {
    type: typeof ARTIST_LOADED;
    result: dashboard.DeliveryArtist;
}
const artistLoaded = (result: ArtistLoaded['result']): ArtistLoaded => ({
    type: ARTIST_LOADED,
    result,
});

/**
 * ARTIST_CLEAR
 */
export const ARTIST_CLEAR = `${PREFIX}ARTIST_CLEAR` as const;
interface ArtistClear { type: typeof ARTIST_CLEAR; }
const artistClear = (): ArtistClear => ({ type: ARTIST_CLEAR });

/**
 * ARTIST_ALBUMS_LOADED
 */
export const ARTIST_ALBUMS_LOADED = `${PREFIX}ARTIST_ALBUMS_LOADED` as const;
interface ArtistAlbumsLoaded {
    type: typeof ARTIST_ALBUMS_LOADED;
    result: dashboard.SearchAlbum[];
}
const artistAlbumsLoaded = (result: ArtistAlbumsLoaded['result']): ArtistAlbumsLoaded => ({
    type: ARTIST_ALBUMS_LOADED,
    result,
});

/**
 * ARTIST_TRACKS_LOADED
 */
export const ARTIST_TRACKS_LOADED = `${PREFIX}ARTIST_TRACKS_LOADED` as const;
interface ArtistTracksLoaded {
    type: typeof ARTIST_TRACKS_LOADED;
    result: dashboard.SearchTrack[];
}
const artistTracksLoaded = (result: ArtistTracksLoaded['result']): ArtistTracksLoaded => ({
    type: ARTIST_TRACKS_LOADED,
    result,
});

/**
 * ARTIST_SEARCH_SUCCEEDED
 */
export const ARTIST_SEARCH_SUCCEEDED = `${PREFIX}ARTIST_SEARCH_SUCCEEDED` as const;
interface ArtistSearchSucceeded {
    type: typeof ARTIST_SEARCH_SUCCEEDED;
    result: {
        keyword: string
        target: 'albums' | 'tracks'
        result: Pick<dashboard.SearchSet, 'albums' | 'tracks'>
        paging: boolean
    };
}
const artistSearchSucceeded = (result: ArtistSearchSucceeded['result']): ArtistSearchSucceeded => ({
    type: ARTIST_SEARCH_SUCCEEDED,
    result,
});

/**
 * ARTIST_SEARCH_TARGET_CHANGED
 */
export const ARTIST_SEARCH_TARGET_CHANGED = `${PREFIX}ARTIST_SEARCH_TARGET_CHANGED` as const;
interface ArtistSearchTargetChanged {
    type: typeof ARTIST_SEARCH_TARGET_CHANGED;
    result: {
        target: 'albums' | 'tracks'
    };
}
const artistSearchTargetChanged = (result: ArtistSearchTargetChanged['result']): ArtistSearchTargetChanged => ({
    type: ARTIST_SEARCH_TARGET_CHANGED,
    result: result,
});

/**
 * ALBUM_LOADED
 */
export const ALBUM_LOADED = `${PREFIX}ALBUM_LOADED` as const;
interface AlbumLoaded {
    type: typeof ALBUM_LOADED;
    result: {
        summary: dashboard.DeliveryAlbum
        list: dashboard.SearchTrack[]
    };
}
const albumLoaded = (result: AlbumLoaded['result']): AlbumLoaded => ({
    type: ALBUM_LOADED,
    result,
});

/**
 * ALBUM_CLEAR
 */
export const ALBUM_CLEAR = `${PREFIX}ALBUM_CLEAR` as const;
interface AlbumClear { type: typeof ALBUM_CLEAR; }
const albumClear = (): AlbumClear => ({ type: ALBUM_CLEAR });

/**
 * TRACK_LOADED
 */
export const TRACK_LOADED = `${PREFIX}TRACK_LOADED` as const;
interface TrackLoaded {
    type: typeof TRACK_LOADED;
    result: {
        summary: dashboard.DeliveryTrack
    };
}
const trackLoaded = (result: TrackLoaded['result']): TrackLoaded => ({
    type: TRACK_LOADED,
    result,
});

/**
 * TRACK_CLEAR
 */
export const TRACK_CLEAR = `${PREFIX}TRACK_CLEAR` as const;
interface TrackClear { type: typeof TRACK_CLEAR; }
const trackClear = (): TrackClear => ({ type: TRACK_CLEAR });

/**
 * ERROR
 */
export const ERROR = `${PREFIX}ERROR` as const;
interface Error { type: typeof ERROR; result: string; }
const error = (code: string): Error => {
    let result = ''

    if (code) {
        const translator = new Translator(navigator.language)

        if (code === 'not_allowed') {
            result = translator.translate('delivery__viewer__error__not__allowed')
        } else if (code === 'not_found') {
            result = translator.translate('delivery__viewer__error__not__found')
        } else {
            result = 'Unexpected Error'
        }
    }

    return { type: ERROR, result }
};
