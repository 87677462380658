import {
    COMMON_CLEAR, COMMON_FAILED, COMMON_GET_ALBUM_SUCCEEDED, COMMON_GET_ARTIST_SUCCEEDED, COMMON_GET_TRACK_SUCCEEDED, CommonAction
} from '../actions/common';
import { CommonState } from './../stores/commonState';

export default (state: CommonState, action: CommonAction): CommonState => {
    switch (action.type) {
        case COMMON_GET_ARTIST_SUCCEEDED:
            return { ...state, artist: action.payload };
        case COMMON_GET_ALBUM_SUCCEEDED:
            return { ...state, album: action.payload };
        case COMMON_GET_TRACK_SUCCEEDED:
            return { ...state, track: action.payload };
        case COMMON_CLEAR:
            return { ...state, artist: null, album: null, track: null };
        case COMMON_FAILED:
            return { ...state, errorMessage: action.errorMessage };
        default:
            const _: never = action;
            return state || {};
    }
};