/**
 * クリップボードに文字列をコピーする
 * @param text コピーしたい文字列
 */
export async function copyToClipboard(text: string): Promise<void> {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text);
  } else {
    const input = document.createElement('input');
    input.setAttribute('id', 'copyinput');
    document.body.appendChild(input);
    input.style.position = 'fixed';
    input.style.top = '0';
    input.style.opacity = '0';
    input.value = text;
    input.select();
    const res = document.execCommand('copy');
    document.body.removeChild(input);

    if (!res) {
      throw new Error('failed to copy');
    }
  }
}
