import { connect, Dispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as commonActions from '../../actions/common';
import * as searchActions from '../../actions/search';
import { cancel, clear, download, load, loadRanking, SummaryAction } from '../../actions/summary';
import Summary from '../../components/summary/Summary';
import { RootState } from '../../stores/rootState';

const mapStateToProps = ({ summary, window, search }: RootState) => {
    return {
        summaryResult: summary.result,
        errorMessage: summary.errorMessage,
        cancelled: summary.cancelled,
        width: window.width,
        height: window.height,
        hasNextRankings: summary.hasNextRankings,
        translator: window.translator,
        searchResult: search.result,
        searchErrorMessage: search.errorMessage,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<SummaryAction | searchActions.SearchAction | commonActions.CommonAction>) => {
    return {
        load: (type: string, id: string, start: string, end: string) => { dispatch(load(type, id, start, end)); },
        clear: () => { dispatch(clear()); },
        download: (type: string, id: string, start: string, end: string) => { dispatch(download(type, id, start, end)); },
        cancel: () => { dispatch(cancel()); },
        loadMoreRanking: (
            start: string,
            end: string,
            offset: number,
            limit: number,
            type: 'artists' | 'albums' | 'tracks' | 'playlists',
            target?: { uriType: string, id: string }) => {
            dispatch(loadRanking(start, end, offset, limit, type, target));
        },
        search: (query: string, targets?: ['artists' | 'albums' | 'tracks']) => { dispatch(searchActions.search(query, targets)); },
        loadHistories: () => { dispatch(searchActions.loadHistories()); },
        saveHistory: (history: dashboard.SearchArtist | dashboard.SearchAlbum | dashboard.SearchTrack) => {
            dispatch(searchActions.saveHistory(history));
        },
        getCommonInfo: (type: dashboard.CommonInfoType, id: string) => { dispatch(commonActions.getCommonInfo(type, id)); },
        clearCommonInfo: () => { dispatch(commonActions.clearCommonInfo()); },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Summary));